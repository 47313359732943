import {JsonSchema, RuleEffect} from "@jsonforms/core";

export const ConstraintType = {
    consistency: 'Целостность',
};



export const ConstraintDialogUiSchema = {
    type: 'VerticalLayout',
    elements: [
        {
            type: 'Control',
            scope: '#/properties/id'
        },
        {
            type: 'Control',
            scope: '#/properties/name'
        },
        {
            type: 'Control',
            scope: '#/properties/type',
            options: {
                format: 'radio'
            }
        },
        {
            type: 'Control',
            scope: '#/properties/activationPredicate',
        },
        {
            type: 'VerticalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/predicate'
                },
                {
                    type: 'Control',
                    scope: '#/properties/message'
                },
            ],
            rule: {
                effect: RuleEffect.SHOW,
                condition: {
                    scope: '#/properties/type',
                    schema: {
                        enum: [ConstraintType.consistency]
                    }
                }
            }
        }
    ]
}


export const constraintDialogSchema = (constraintData: boolean, readonly: boolean): JsonSchema => {
    return {
        type: 'object',
        properties: {
            id: {
                title: 'ID',
                type: 'string',
                ...constraintData && {'readOnly': readonly},
            },
            type: {
                title: 'Тип ограничения',
                type: 'string',
                enum: Object.values(ConstraintType),
                ...constraintData && {'readOnly': readonly},
            },
            name: {
                title: 'Название',
                type: 'string',
                ...constraintData && {'readOnly': readonly},
            },
            activationPredicate: {
                title: 'Активировать, когда',
                type: 'object',
                properties: {
                    value: {
                        title: 'Активировать, когда (значение)',
                        type: 'string',
                    },
                    formula: {
                        title: 'Активировать, когда (формула)',
                        type: 'string',
                    },
                },
                ...constraintData && {'readOnly': readonly},
            },
            predicate: {
                title: 'Формула проверки',
                type: 'object',
                properties: {
                    value: {
                        title: 'Формула проверки (значение)',
                        type: 'string',
                    },
                    formula: {
                        title: 'Формула проверки (формула)',
                        type: 'string',
                    },
                },
                ...constraintData && {'readOnly': readonly},
            },
            message: {
                title: 'Сообщение',
                type: 'string',
                ...constraintData && {'readOnly': readonly},
            }

        },
        required: ['id', 'name', 'type', 'activationPredicate']
    };
}
