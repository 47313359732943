
import React from 'react';
import {
  ControlProps,
  isNumberControl,
  RankedTester,
  rankWith
} from '@jsonforms/core';

import { withJsonFormsControlProps } from '@jsonforms/react';
import {MaterialInputControl} from "./InputControl";
import {MuiInputNumber} from "@jsonforms/material-renderers";

export const MaterialNumberControl = (props: ControlProps) => (
  <MaterialInputControl {...props} input={MuiInputNumber} />
);

export const numberControlTester: RankedTester = rankWith(
  3,
  isNumberControl
);

export default withJsonFormsControlProps(MaterialNumberControl);
