import {DropDownButton} from '@progress/kendo-react-buttons';
import {AppPrompt} from '../../components/app-prompt/app-prompt';
import React, {PropsWithChildren, useState} from 'react';
import {useAppDispatch} from '../../hook/store';
import {ApiModelType, AppPromptData, ModelTypeAction} from '../../interface';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {ROUTE_PATH} from "../../constants/routes";
import {deleteModelType} from "../../store/slice/model-type-slice";
import {useNavigate} from "react-router-dom";




interface NodeTypeActionsProps extends PropsWithChildren{
  modelType: ApiModelType
}

const NodeTypeActions = ({modelType, children}:NodeTypeActionsProps) => {

  const dispatch = useAppDispatch();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const navigate = useNavigate();

  const actionData: AppPromptData = {
    title: 'Подтвердите действие',
    message: [`Подтвердите удаление типа узла "${modelType.id}"`],
    confirmButton: 'Удалить',
    buttonError: true
  };

  const handleAction = (e: DropDownButtonItemClickEvent) => {
    switch (e.item.text) {
      case ModelTypeAction.delete:
        setShowConfirm(true);
        break;
    }
  };

  const itemRender = (itemData: { item: any; itemIndex: number }) => {
    return <div className="action-item">
      <span data-action={itemData.item.text}>{itemData.item.text}</span>
    </div>;
  };

  const onDeleteItem = () => {
    setShowConfirm(false);
    if (modelType.id) {
      dispatch(deleteModelType(modelType.id)).unwrap()
        .then(() => {
          navigate(`/${ROUTE_PATH.nodeTypes}`);
        });
    }
  };

  return (
    <>
      <DropDownButton
        size={'small'}
        themeColor={'primary'}
        fillMode={'solid'}
        rounded={'small'}
        icon={'more-vertical'}
        itemRender={itemRender}
        onItemClick={handleAction}
      >
        {children}
      </DropDownButton>
      {showConfirm && (
        <AppPrompt data={actionData} onClose={() => setShowConfirm(false)} onConfirm={onDeleteItem}/>
      )}

    </>
  );
};

export {NodeTypeActions};
