export interface ApiModelType{
    rawData?: string;
    hash?: string;
    id: number;
    namespace: string;
    typeNumber: number;
    created: string;
}

export interface ApiModelTypeResult {
    result: ApiModelType[];
    total: number;
}


export enum ModelTypeAction {
  delete = 'Удалить',
}
