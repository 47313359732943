import React from 'react';
import {SpecItemDocInvoice} from '../../../../../interface';
import './invoice.scss';
import {Button} from '@mui/material';
import {AppTableView} from '../../../../../components/app-table-view/app-table-view';
import {ReactComponent as DownloadIcon} from '../../../../../assets/img/icon/download.svg';
import {StickyDownloadButton} from '../../../../../ui/sticky-download-button/sticky-download-button';


interface InvoiceProps {
    invoice: SpecItemDocInvoice
    specId: string;
}

const Invoice = ({invoice, specId}: InvoiceProps) => {

    return <>
        <StickyDownloadButton  style={{margin: '0 -24px 0 0px'}} hideNotStickyWrapper={true}>
            <Button
                variant="outlined"
                startIcon={<DownloadIcon/>}
                href={`/api/spec/${specId}/command/export/excel`}
                download>
                Скачать...
            </Button>
        </StickyDownloadButton>
        <div className="specification-invoice">
            <div className={'specification-invoice__table'}>
                <AppTableView data={invoice} reportType={'invoice'}/>
            </div>
        </div>
    </>;
};

export {Invoice};
