import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    TextField
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';


interface NewTypeDialogProps {
    onClose: () => void;
    onSubmit: (formData: { name: string }) => void;
    title?: string,
    namePlaceholder?: string
    inProgress?: boolean
}


const NewTypeDialog = ({onClose, onSubmit, title, namePlaceholder, inProgress}: NewTypeDialogProps) => {
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'all',
    });

    const onBeforeSubmit = (formData: any) => {
        onSubmit(formData);
    };

    return (
        <Dialog
            className="new-item-dialog"
            open={true}
            maxWidth={'lg'}
            onClose={onClose}
        >
            <DialogTitle className="dialog-title">{title || 'Новый элемент'}</DialogTitle>
            <form onSubmit={handleSubmit(onBeforeSubmit)}>
                <DialogContent>
                    <FormGroup className={errors?.name ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl error={!!errors?.name} size={'small'}>
                            <TextField
                                label={namePlaceholder || ''}
                                variant="outlined"
                                error={!!errors?.name}
                                helperText={errors?.name ? errors?.name.message as string : ''}
                                {...register('name', {
                                    required: 'Обязательное поле',
                                    maxLength: {value: 100, message: 'Название должно содержать максимум 100 символов'},
                                    pattern: {
                                        value: /^[a-zA-Z0-9_]*$/,
                                        message: 'Название может содержать только латинские букы, цифры и знак подчеркивания'
                                    }
                                })}
                            />
                        </FormControl>
                    </FormGroup>
                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        disabled={inProgress}
                        variant={'outlined'}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'submit'}
                        variant={'contained'}
                        disabled={!isValid || inProgress}
                    >
                        Добавить
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>
    );
};

export {NewTypeDialog};
