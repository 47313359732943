import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {AppPromptData} from '../../interface';
import './app-prompt.scss';
import {LoadingButton} from "@mui/lab";

interface AppPromptProps {
    data: AppPromptData
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
}


const AppPrompt = ({
                       data: {title, message, confirmButton, declineButton, buttonError},
                       onClose,
                       onConfirm,
                       isLoading
                   }: AppPromptProps) => {


    return <>
        <Dialog
            className="action-prompt-dialog"
            open={true}
            maxWidth={'sm'}
            onClose={onClose}
        >
            <DialogTitle className="dialog-title">{title}</DialogTitle>
            <DialogContent>
                <ul>
                    {message.map((item, i) => (
                        <li key={i} dangerouslySetInnerHTML={{__html: item}}>
                        </li>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={'outlined'}
                    onClick={onClose}
                >
                    {declineButton || 'Отмена'}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    color={buttonError ? 'error' : 'primary'}
                    onClick={onConfirm}
                    loading={isLoading}
                >
                    {confirmButton}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>;
};

export {AppPrompt};
