import {
    SearchParams,
    SpecCreateTemplateRequest,
    SpecTemplate, SpecTemplateGroupResultInterface,
    SpecTemplateResultInterface
} from '../../interface';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';

export interface TemplateState {
    templates: SpecTemplate[] | null,
    templatesTotal: number,
    templateGroups: string[]
}


export const getTemplates = createAsyncThunk(
    'template/getTemplates',
    async function (params: SearchParams, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/spec2/templates',
            params
        }, dispatch);
        return response.data;
    }
);

export const deleteTemplate = createAsyncThunk(
    'template/deleteTemplate',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'delete',
            url: `/api/spec2/templates/${id}`
        }, dispatch);
        return response.data;
    }
);


export const createTemplate = createAsyncThunk(
    'template/createTemplate',
    async function (data: SpecCreateTemplateRequest, {dispatch}) {
        const response = await baseRequest({
            method: 'post',
            url: `/api/spec2/templates/${data.specId}?templateName=${data.templateName}&groupName=${data.groupName || ''}`,
            data: {
                forms: data.forms
            }
        }, dispatch);
        return response.data;
    }
);

export const renameTemplate = createAsyncThunk(
    'template/renameTemplate',
    async function (data: { id: string, name: string, groupName: string }, {dispatch}) {
        const response = await baseRequest({
            method: 'put',
            url: `/api/spec2/templates/${data.id}?templateName=${data.name}&groupName=${data.groupName}`,
        }, dispatch);
        return response.data;
    }
);

export const createSpecFromTemplate = createAsyncThunk(
    'template/createSpecFromTemplate',
    async function (id: string, {dispatch}) {
        const response = await baseRequest({
            method: 'post',
            url: `/api/spec2/templates/templates/${id}/commands/newSpec`,
        }, dispatch);
        return response.data;
    }
);

export const getTemplateGroups = createAsyncThunk(
    'template/getTemplateGroups',
    async function (_, {dispatch}) {
        const response = await baseRequest({
            method: 'get',
            url: '/api/spec2/templates/groups',
        }, dispatch);
        return response.data;
    }
);


const initialState: TemplateState = {
    templates: null,
    templatesTotal: 0,
    templateGroups: []
};


const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        clearTemplateList(state: TemplateState) {
            state.templates = null;
        },
    },
    extraReducers: {
        [getTemplates.fulfilled as any]: (state: TemplateState, action: PayloadAction<SpecTemplateResultInterface>) => {
            if (action.payload?.result) {
                state.templates = action.payload.result;
                state.templatesTotal = action.payload.total;
            }
        },
        [getTemplateGroups.fulfilled as any]: (state: TemplateState, action: PayloadAction<SpecTemplateGroupResultInterface>) => {
            if (action.payload?.result) {
                state.templateGroups = action.payload.result;
            }
        },
    }
});

export const {clearTemplateList} = templateSlice.actions;
export default templateSlice.reducer;
