import {AppTab} from './app-tabs.interface';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Fragment, ReactElement} from 'react';

interface AppTabsProps {
    tabs: AppTab[];
    selected: AppTab;
    onSelect: (tab: AppTab) => void;
}

const AppTabs = ({tabs, selected, onSelect}: AppTabsProps) => {

    const tabElement = (tab: AppTab): ReactElement => {
        const active = selected.path === tab.path;
        return (
            <>
                {(!tab.hideInactive || active) && (
                    <div
                        className={active ? 'tab active' : 'tab'}
                        onClick={() => onSelect(tab)}
                    >
                        {tab.title}
                    </div>
                )}
            </>
        );
    };

    return (
        <Card style={{background: 'inherit'}}>
            <CardBody className={'item-tabs'}>
                <div className="form-tabs">
                    {
                        tabs.filter(t => t.position === 'left').map((tab, index) => {
                            return <Fragment key={index}>{tabElement(tab)}</Fragment>;
                        })
                    }
                </div>
                <div className="action-tabs">
                    {
                        tabs.filter(t => t.position === 'right').map((tab, index) => {
                            return <Fragment key={index}>{tabElement(tab)}</Fragment>;
                        })
                    }
                </div>
            </CardBody>
        </Card>
    );
};

export {AppTabs};
