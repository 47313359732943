import styles from '../../styles/type-edit.module.scss';
import {AppTab} from '../../../../components/app-tabs/app-tabs.interface';
import {AppBreadcrumbs} from '../../../../components/app-breadcrumbs/app-breadcrumbs';
import {TypeHeader, TypeHeaderData} from '../../components/type-header/type-header';
import {AppTabs} from '../../../../components/app-tabs/app-tabs';
import {TypeContentHeader} from '../../components/type-header/type-content-header';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {AppLoader} from '../../../../components/app-loader/app-loader';
import {AppPrompt} from '../../../../components/app-prompt/app-prompt';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppBreadcrumb} from '../../../../interface/breadcrumbs.interface';
import {adminTraitsBreadcrumbs, adminTraitSchema} from '../node-traits.meta';
import {useAppDispatch} from '../../../../hook/store';
import axios from 'axios';
import {API_ADMIN_NODE_TRAITS, typeItemTabs} from '../../admin.meta';
import {ROUTE_PATH} from '../../../../constants/routes';
import {showError} from '../../../../store/slice/toast-slice';
import {AdminTraitSchema, AdminTraitSchemaData} from '../../../../interface/admin/admin-traits.interface';

export interface AdminTraitsOutletContext {
    sharedData: AdminTraitSchemaData,
    onDataChange: (data: any) => void
    onDelete: () => void
    onUpdate: () => void
}

const tabs = typeItemTabs;

const TraitsItemEdit = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const urlParams = useParams();
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState<AppBreadcrumb[]>(adminTraitsBreadcrumbs);
    const [id, setId] = useState<string>('');
    const [itemData, setItemData] = useState<AdminTraitSchema>(adminTraitSchema);
    const [header, setHeader] = useState<TypeHeaderData>({title: ''});
    const [jsonMode, setJsonMode] = useState<boolean>(false);
    const [confirmSave, setConfirmSave] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<AppTab>(tabs[0]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newData, setNewData] = useState<AdminTraitSchemaData>(adminTraitSchema.data);
    const [sharedData, setSharedData] = useState<AdminTraitSchemaData>(adminTraitSchema.data);


    useEffect(() => {
        setId(urlParams?.id || '');
    }, [urlParams]);

    useEffect(() => {
        if (!!id) {
            getItemData();
        }
    }, [id]);

    useEffect(() => {
        if (itemData?.id) {
            const parts = itemData.id.split('.');
            setHeader({
                title: parts[0],
                ...(parts.length > 1 && {version: `v${parts[1]}`})
            });
        }

    }, [itemData]);

    useEffect(() => {
        updateShared()
        if (!!location?.pathname) {
            const parts = location.pathname.split('/');
            const lastPart = parts[parts.length - 1];
            const tab = tabs.find(t => t.path === lastPart) || tabs[0];
            setJsonMode(tab.path === 'json');
            setSelectedTab(tab);
        } else {
            setSelectedTab(tabs[0]);
        }
    }, [location]);


    const getItemData = () => {
        axios.get(`${API_ADMIN_NODE_TRAITS}/${id}`)
            .then((response) => {
                updateBreadcrumbs(response.data?.name || response.data?.id || '-');
                setItemData(response.data);
                setSharedData({...response.data.data})
            }).finally(() => setIsReady(true));
    };


    const updateBreadcrumbs = (title: string) => {
        setBreadcrumbs([
            ...breadcrumbs,
            {title}
        ]);
    };


    const handleSelectTab = (tab: AppTab | undefined) => {
        updateShared();
        if (tab?.path) {
            navigate(tab.path);
        }
    };

    const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            handleSelectTab(tabs.find((t) => t.path === 'json'));
        } else {
            handleSelectTab(tabs.find((t) => t.path === 'form'));
        }
    };

    const onDataChange = (data: AdminTraitSchemaData) => {
        setNewData(data);
    };

    const updateShared = () => {
        setSharedData({...newData});
    };

    const onDelete = () => {
        setConfirmDelete(true);
    };

    const onUpdate = () => {
        setConfirmSave(true);
    };


    const handleDelete = () => {
        setIsLoading(true);
        axios.delete(`${API_ADMIN_NODE_TRAITS}/${id}`)
            .then(() => {
                navigate(`/${ROUTE_PATH.adminNodeTraits}`);
            }).catch(() => dispatch(showError('Не удалось удалить характеристику')))
            .finally(() => setIsLoading(false));
    };

    const handleUpdate = () => {
        setIsLoading(true);
        axios.put(`${API_ADMIN_NODE_TRAITS}/${id}`, {data: newData})
            .then(() => {
                setConfirmSave(false);
                getItemData();
            })
            .catch(() => dispatch(showError('Не удалось сохранить характеристику')))
            .finally(() => setIsLoading(false));
    };


    return (
        <>
            <div className={styles.adminTypeItem}>
                <div className={styles.adminTypeItem__breadcrumbs}>
                    <AppBreadcrumbs items={breadcrumbs}/>
                </div>
                <div className={styles.adminTypeItem__header}>
                    <TypeHeader header={header}
                                jsonMode={jsonMode}
                                onDelete={onDelete}
                                onSave={onUpdate}/>
                    <AppTabs tabs={tabs}
                             selected={selectedTab}
                             onSelect={handleSelectTab}/>
                </div>
                <div className={styles.adminTypeItem__content}>
                    {isReady && (
                        <>
                            <TypeContentHeader onChange={handleModeChange} checked={jsonMode}/>
                            <div className={styles.adminTypeItem__content_editor}>
                                <Outlet context={{sharedData, onDataChange, onDelete, onUpdate}}/>
                            </div>
                        </>
                    )}
                    {!isReady && (
                        <AppLoader/>
                    )}
                </div>
            </div>
            {confirmDelete && (
                <AppPrompt data={{
                    title: 'Удаление характеристики',
                    message: [`Вы хотите удалить характеристику '${itemData.id}'?`],
                    confirmButton: 'Удалить',
                    buttonError: true
                }}
                           onClose={() => setConfirmDelete(false)}
                           onConfirm={handleDelete}
                           isLoading={isLoading}
                />
            )}

            {confirmSave && (
                <AppPrompt data={{
                    title: 'Сохранение характеристики',
                    message: [`Сохранить характеристику '${itemData.id}'?`],
                    confirmButton: 'Сохранить',
                    buttonError: false
                }}
                           onClose={() => setConfirmSave(false)}
                           onConfirm={handleUpdate}
                           isLoading={isLoading}
                />
            )}
        </>
    );
};

export {TraitsItemEdit};
