import {useRef} from 'react';
import {useOutletContext} from 'react-router-dom';
import {AdminNodeItemOutletContext} from './node-item-edit';
import Editor, {Monaco} from '@monaco-editor/react';
import styles from '../../styles/type-edit.module.scss';
import {editor} from 'monaco-editor';
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;

const NodeItemEditJson = () => {
    const editorRef = useRef<IStandaloneCodeEditor>();
    const {sharedData, onDataChange} = useOutletContext<AdminNodeItemOutletContext>();
    const options = {
        selectOnLineNumbers: false,
        fixedOverflowWidgets: true,
    };


    const handleDataChange = (data: string | undefined) => {
        if (data) {
            const json = JSON.parse(data)
            onDataChange(json);
        }
    };

    function editorWillMount(monaco: Monaco) {
        monaco.editor.defineTheme('test', {
            base: 'vs',
            inherit: true,
            encodedTokensColors: [],
            rules: [
                {
                    token: 'delimiter',
                    foreground: '#9b9489',
                },
                {
                    token: 'keyword.json',
                    foreground: '#00BEC8',
                },
                {
                    token: 'string.value.json',
                    foreground: '#9b9489',
                },
                {
                    token: 'string.key.json',
                    foreground: '#00BEC8',
                }
            ],
            colors: {}
        });
    }


    const editorDidMount = (editor: IStandaloneCodeEditor) => {
        if (!!editor) {
            editorRef.current = editor;

            editor.updateOptions({
                lineNumbers: 'off',
                wordWrap: 'on',
                renderLineHighlight: 'none',
                fixedOverflowWidgets: true,
                // automaticLayout: true,
                // minimap: {
                //     enabled: false
                // }
            });

            editor.onDidFocusEditorWidget((e) => {
                setTimeout(() => {
                    editor.getAction('editor.action.formatDocument')?.run().then(() => {
                        editor.setScrollPosition({scrollTop: 0});
                    });
                }, 200);
            });

            editor.focus();
        }
    };


    return (
        <div className={styles.adminTypeItem__content_json}>
            <Editor
                width="100%"
                height="100%"
                value={JSON.stringify(sharedData)}
                onChange={handleDataChange}
                language="json"
                options={options}
                beforeMount={editorWillMount}
                onMount={editorDidMount}
                theme="test"
            />
        </div>
    );
};

export {NodeItemEditJson};
