import {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
} from '@mui/material';
import Editor, {Monaco, loader} from '@monaco-editor/react';

import {FormulaInputAcceptedValues} from './formula-input-renderer';
import { editor, IDisposable} from 'monaco-editor';
import {JsonSchema} from '@jsonforms/core/src/models';
import {useAppSelector} from '../../../hook/store';
import {NodeState} from '../../../store/slice/node-slice';
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import {CompassContext} from '../../../hook/compas-context';

interface ComponentFormulaDialogProps {
    title: string;
    formulaValue: string;
    handleSaveFormula: (value: FormulaInputAcceptedValues) => void;
    handleClose: () => void;
    handleFormulaChange: (value: string | undefined) => void;
    rootSchema: JsonSchema;
}

interface CompletionOptions {
    [key: string]: CompletionOption[]
}

interface CompletionOption {
    detail: string;
    insertText: string;
    label: string;
    trigger: string;
    properties: CompletionOption[];
}

loader.config({ paths: { vs: '/monaco-editor/min/vs' } });

const FormulaInputModal = (
        {
            title,
            formulaValue,
            handleSaveFormula,
            handleClose,
            handleFormulaChange,
            rootSchema
        }: ComponentFormulaDialogProps) => {
        const [formulaData, setFormulaData] = useState<string | undefined>('');
        const index = title.indexOf('(');
        const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
        const [completionOptions, setCompletionOptions] = useState<CompletionOptions>();
        const {isLink, item} = useContext(CompassContext);

        const handleChange = (value: string | undefined) => {
            setFormulaData(value);
        };

        const handleSaveData = () => {
            handleFormulaChange(formulaData);
            handleSaveFormula(formulaData);
            handleClose();
        };

        useEffect(() => {
            let url = `/api/node/${currentNodeItem?.id}/scripting/luaCompletion`;
            if(isLink && item){
                url = `/api/node/${item.masterNodeId}/scripting/linkedNodeLuaCompletion?linkedNodeId=${item.linkedNodeId}`;
            }
            fetch(url)
                .then((response) => {
                    if(response.ok){
                        return response.json();
                    }else{
                        throw new Error('Something went wrong.');
                    }
                })
                .then((actualData) => {
                    setCompletionOptions(actualData);
                })
                .catch((e) => setCompletionOptions({}));
        }, [fetch, isLink, item]);

        return <>
            {completionOptions && (
                <Dialog
                    className="new-item-dialog"
                    open={true}
                    maxWidth={'lg'}
                    onClose={handleClose}
                    disableEscapeKeyDown
                >
                    <DialogTitle className="dialog-title">{title.substring(0, index)}</DialogTitle>
                    <DialogContent sx={{padding: '0 24px', width: '600px'}}>
                        <p style={{color: 'gray', marginTop: '0'}}>Формула</p>
                        <FormGroup className={'form-field'}>
                            <FormControl sx={{border: '1px solid rgba(0, 0, 0, 0.12)'}} className={'codeEditor'}>
                                <CodeEditor
                                    value={formulaValue}
                                    handleChange={handleChange}
                                    completionOptions={completionOptions}
                                />
                            </FormControl>
                        </FormGroup>

                        <div className="hint">
                            для автодополнения нажмите <span>Ctrl</span>+<span>Space</span> (<span>Option</span>+<span>Escape</span> на Mac)
                        </div>
                    </DialogContent>
                    <DialogActions className="form-buttons">
                        <Button
                            variant={'outlined'}
                            onClick={handleClose}
                        >
                            Отмена
                        </Button>
                        <Button
                            type={'submit'}
                            variant={'contained'}
                            onClick={handleSaveData}
                        >
                            Сохранить
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

        </>;
    }
;

export {FormulaInputModal};

interface IEditorProps {
    value: string;
    handleChange: (e: string | undefined) => void;
    completionOptions: CompletionOptions;
}

const CodeEditor = ({value, handleChange, completionOptions}: IEditorProps) => {

    const options = {
        selectOnLineNumbers: false,
        fixedOverflowWidgets: true
    };

    const editorRef = useRef<IStandaloneCodeEditor>();

    const [completionProvider, setCompletionProvider] = useState<IDisposable>();

    function editorWillMount(monaco: Monaco) {
        const provider = monaco.languages.registerCompletionItemProvider('lua', {
            //@ts-ignore
            provideCompletionItems: (model, position) => {
                // Get the text before the cursor
                const textUntilPosition = model.getValueInRange({
                    startLineNumber: position.lineNumber,
                    startColumn: 1,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column,
                });

                const getHint = (option: CompletionOption) => {
                        if (textUntilPosition.endsWith(`${option.label}${option.trigger}`) && Array.isArray(option.properties)) {
                            return{
                                suggestions: option.properties.map((i) => {
                                    return {
                                        label: i.label,
                                        kind: monaco.languages.CompletionItemKind.Variable,
                                        insertText: i.insertText,
                                        detail: i.detail
                                    };
                                })
                            };
                        }
                        return undefined;
                };
                // Check if the text matches an R function
                const options = Object.values(completionOptions) as unknown as CompletionOption[];
                for (const k in options) {
                    const optionHints = getHint(options[k]);
                    if(!!optionHints) {
                        return optionHints;
                    }
                    if (Array.isArray(options[k].properties)) {
                        for (const p in options[k].properties) {
                            const propsHints = getHint(options[k].properties[p]);
                            if(!!propsHints){
                                return propsHints;
                            }
                        }
                    }
                }
                // No suggestions
                return {
                    suggestions: options.map((o) => {
                        return {
                            label: o.label,
                            kind: monaco.languages.CompletionItemKind.Function,
                            insertText: o.insertText,
                            detail: o.detail
                        };
                    }),
                };
            },
        });
        setCompletionProvider(provider);
    }


    const editorDidMount = (editor: IStandaloneCodeEditor) => {
        editorRef.current = editor;

        editor.updateOptions({
            lineNumbers: 'off',
            wordWrap: 'on',
            renderLineHighlight: 'none',
            fixedOverflowWidgets: true,
            minimap: {
                enabled: false
            }
        });
        editor.focus();
    };

    useEffect(() => {
        return () => {
            if (completionProvider && typeof completionProvider.dispose === 'function') {
                completionProvider.dispose();
            }
        };
    }, [completionProvider]);

    return <Editor
        width="600px"
        height="200px"
        value={value}
        onChange={handleChange}
        language="lua"
        options={options}
        beforeMount={editorWillMount}
        onMount={editorDidMount}
        theme="my-lua-theme"
    />;
};
