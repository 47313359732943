import React from 'react';
import {AppBreadcrumb} from '../../interface/breadcrumbs.interface';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {NavLink} from 'react-router-dom';
import {ReactComponent as IconHome} from '../../assets/img/icon/home.svg';
import {ReactComponent as IconChevronRight} from '../../assets/img/icon/chevron-right.svg';

interface AppBreadcrumbsProps {
    items?: AppBreadcrumb[];
}

const AppBreadcrumbs = ({items}: AppBreadcrumbsProps) => {

    return (
        <Card>
            <CardBody className={'breadcrumbs-card'}>
                <div className="breadcrumbs">
                    <NavLink to={'/'}><IconHome></IconHome></NavLink>
                    {!!items && items.map((item, i, {length}) => {
                        if (length - 1 === i) {
                            return (
                                <React.Fragment key={i}>
                                    <IconChevronRight></IconChevronRight>
                                    <div className="item">
                                        {item?.title || '-'}
                                    </div>
                                </React.Fragment>
                            );
                        } else {
                            return (
                                <React.Fragment key={i}>
                                    <IconChevronRight></IconChevronRight>
                                    <div className="item">
                                        <NavLink
                                            to={item?.path || '/'}>{item?.title || '-'}</NavLink>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    })}
                </div>
            </CardBody>
        </Card>
    );
};

export {AppBreadcrumbs};
