import {AppBreadcrumbs} from '../../components/app-breadcrumbs/app-breadcrumbs';
import {SectionHeader} from '../../components/section-header/section-header';
import {useSearchParams} from 'react-router-dom';
import {NodeTypesGrid} from './node-types-grid';
import {useEffect, useState} from 'react';
import {SortDescriptor} from '@progress/kendo-data-query';
import {SearchParams} from '../../interface';
import {ColumnInterface} from '../../components';
import {useAppDispatch, useAppSelector} from '../../hook/store';
import {getModelTypes, ModelTypeState} from '../../store/slice/model-type-slice';
import {ApiModelType} from '../../interface';
import {setSearchParamsTerm} from '../../helpers';
import {modelTypeColumns} from './node-types.meta';


const NodeTypes = () => {

    const dispatch = useAppDispatch();
    const {models, total} = useAppSelector<ModelTypeState>(state => state.modelType);
    const [searchParams, setSearchParams] = useSearchParams();
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [columns, setColumns] = useState<ColumnInterface[]>(modelTypeColumns);
    const [modelsTotal, setModelsTotal] = useState<number>(total);
    const [data, setData] = useState<ApiModelType[] | null>(models);


    useEffect(() => {
        if (!searchParams.get('limit')) {
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                limit: '50',
            }, {replace: true});
        } else {
            dispatch(getModelTypes(Object.fromEntries(searchParams.entries())))
                .unwrap().then((d) => {
                setData(d.result);
                setModelsTotal(d.total);
            });
        }

        const sortBy = searchParams.get('sortBy');
        const sortDir = searchParams.get('sortDirection') || undefined;
        if (!!sortBy && !!sortDir) {
            // const sortField = sortBy === 'specTypeName' ? 'typeName' : sortBy;
            setSort([
                {
                    field: sortBy,
                    dir: sortDir as any
                }
            ]);
        } else {
            setSort([]);
        }

        const columnsParam = searchParams.get('columns');
        if (!columnsParam) {
            const selectedColumns = columns.filter(column => column.show).map(column => column.field).join(';');
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                'columns': selectedColumns,
            }, {replace: true});
        } else if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            const newColumns = columns.map(column => column.field && columnsParamsArr.includes(column.field) ? {
                ...column,
                show: true,
            } : {
                ...column,
                show: false,
            }).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            setColumns(newColumns);
        }
    }, [searchParams]);

    const onSearch = (searchTerm: string): void => {
        if(!searchTerm){
            setData(null);
            searchParams.delete('searchQuery');
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0'
            }, {replace: true});
        }else{
            handleSearchChange(setSearchParamsTerm(searchTerm, {...searchParams, offset: '0'}));
        }
    };


    const handleSearchChange = (params: Partial<SearchParams>) => {
        setData(null);
        if (Object.keys(params).length === 0) {
            searchParams.delete('sortBy');
            searchParams.delete('sortDirection');
        }
        setSearchParams(
            {
                ...Object.fromEntries(searchParams.entries()),
                ...params
            } as unknown as URLSearchParams, {replace: true});
    };

    const onColumnsChange = (newColumns: ColumnInterface[]) => {
        const columnsParam = searchParams.get('columns');
        let sortedColumns: ColumnInterface[] = [];
        let defaultCols: ColumnInterface[] = [];
        if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            const idCol = newColumns.find(col => col.field === 'id');
            if (idCol && !columnsParamsArr.includes('id')) {
                sortedColumns[0] = idCol;
                defaultCols = newColumns.filter(col => col.field !== 'id' && columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            } else if (idCol) {
                defaultCols = newColumns.filter(col => columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            }
            const others: ColumnInterface[] = newColumns.filter(col => defaultCols.every(c => c.field !== col.field)) || [];

            sortedColumns = [...sortedColumns, ...defaultCols, ...others];
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                'columns': sortedColumns.filter(col => col?.show).map(col => col?.field).join(';'),
            }, {replace: true});
        }
    };

    const resetFilter = (field: string) => {
        if (searchParams.has(field)) {
            setData(null);
        }
        searchParams.delete(field);
        setSearchParams(searchParams);
    };

    return (
        <>
            <div className={'page-grid page-grid-inline-header'}>
                <div className="page-grid__breadcrumbs">
                    <AppBreadcrumbs items={[{title: 'Типы узлов'}]}/>
                </div>
                <div className="page-grid__header">
                    <SectionHeader style={'inline'} onSearch={onSearch}
                    />
                </div>
                <div className="page-grid__grid">
                    {searchParams && (
                        <NodeTypesGrid
                            searchParams={searchParams}
                            sort={sort}
                            columns={columns}
                            data={data}
                            total={total}
                            onChanges={handleSearchChange}
                            onColumnsChange={onColumnsChange}
                            resetFilter={resetFilter}/>
                    )}

                </div>
            </div>
        </>
    );
};

export {NodeTypes};
