import React from 'react';
import {
  ControlProps,
  isIntegerControl,
  RankedTester,
  rankWith
} from '@jsonforms/core';

import { withJsonFormsControlProps } from '@jsonforms/react';
import {MaterialInputControl} from './InputControl';
import {MuiInputInteger} from "@jsonforms/material-renderers";

export const MaterialIntegerControl = (props: ControlProps) => (
  <MaterialInputControl {...props} input={MuiInputInteger} />
);
export const integerControlTester: RankedTester = rankWith(
  3,
  isIntegerControl
);
export default withJsonFormsControlProps(MaterialIntegerControl);
