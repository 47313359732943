import {SpecItemDocModel} from '../../../../../../interface';
import {useEffect, useState} from 'react';
import {AppTableView} from '../../../../../../components/app-table-view/app-table-view';


interface SpecificationItemCalculationTableProps {
    model: SpecItemDocModel;
    type: string;
}


const SpecificationItemCalculationTable = ({model, type}: SpecificationItemCalculationTableProps) => {
    const [data, setData] = useState<any>();
    useEffect(() => {
        setData(model.sections[type]);
    }, [model, type]);

    return (
        <>
            <AppTableView data={data} reportType={type}/>
        </>
    );
};

export {SpecificationItemCalculationTable};
