import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import React, {SyntheticEvent, useState} from 'react';

interface AddDialogProps {
    inProgress: boolean;
    onClose: () => void;
    onAdd: (ids: string) => void;
}

const AddDialog = ({onClose, onAdd, inProgress}: AddDialogProps) => {
    const [text, setText] = useState<string>('');

    const formSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        onAdd(text);
    };

    return (
        <Dialog
            open={true}
            maxWidth={'lg'}
            disableRestoreFocus={true}
            onClose={onClose}
        >
            <DialogTitle>Добавить спецификацию</DialogTitle>
            <div style={{ width: '500px', display: 'flex', justifyContent: 'center', padding: '10px 0 20px !important'}}>
                <form onSubmit={formSubmit} style={{width: '100%'}}>
                    <DialogContent>
                        <TextField
                            fullWidth={true}
                            multiline
                            rows={3}
                            autoFocus={true}
                            label="Номер спецификации"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className="form-buttons" sx={{display: 'flex', justifyContent: 'flex-end', padding: '10px 20px'}}>
                        <Button
                            variant={'outlined'}
                            onClick={onClose}
                            disabled={inProgress}
                        >
                            Отмена
                        </Button>
                        <LoadingButton
                            type={'submit'}
                            variant={'contained'}
                            disabled={!text.length || inProgress}
                            loading={inProgress}
                        >
                            Добавить
                        </LoadingButton>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
};

export {AddDialog};
