import {
  Grid,
  GridCell,
  GridCellProps,
  GridColumn,
  GridSortChangeEvent,
  GridColumnReorderEvent,
  GridNoRecords as NoRecords
} from '@progress/kendo-react-grid';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import React, {ReactElement, SyntheticEvent, useEffect, useState} from 'react';
import {getFilteredNodes} from '../../../store/slice/node-slice';
import {ComponentBreadcrumbs} from '../components/breadcrumbs/components-breadcrumbs';
import {SectionHeader} from '../../../components/section-header/section-header';
import {DATE_TIME, DEFAULT_PAGE_SIZE, SECTION_HEADER} from '../../../constants';
import {Card, CardBody} from '@progress/kendo-react-layout';
import './node-item.scss';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  ApiLinkedNodeItem,
  ApiNode,
  ApiNodeItem,
  SearchParams,
  SpecInterface,
  StatusLabel
} from '../../../interface';
import {AppSuspense} from '../../../components/app-suspense/app-suspense';
import {ColumnDataInterface, ColumnInterface, TypeColumnMenu} from '../../../components';
import {
  ComponentItemNameCell
} from '../component-item/component-item-links/component-item-name-cell/component-item-name-cell';
import {format, parseISO} from 'date-fns';
import {CheckboxFilter, CheckboxFilterOption} from '../components/grid-header-filters/type-filter-cell';
import {getAllNodeTypes, NodeTypeState} from '../../../store/slice/node-type-slice';
import {NODE_FILTER} from './node-item.interface';
import {SortDescriptor} from '@progress/kendo-data-query';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {TextFilter} from '../components/grid-header-filters/text-filter';
import {DateFilter} from '../components/grid-header-filters/date-filter';
import {ComponentTypeActions} from '../components/component-type-actions/component-actions';
import ObjectHelper from '../../../helpers/object.helper';
import {NodeFilter} from '../components/grid-header-filters/node-filter';
import {nodeTypeColumns} from './node-item.meta';
import {AppPagination} from '../../../components/app-pagination/app-pagination';
import {ROUTE_PATH} from '../../../constants/routes';
import {NewComponentDialog} from '../components/new-component-dialog/new-component-dialog';

const NodeItem = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<ApiNodeItem[] | null>(null);
  const [sort, setSort] = useState<SortDescriptor[]>([{field: 'type', dir: 'asc'}]);
  const [total, setTotal] = React.useState<number>(0);
  const [columns, setColumns] = useState<ColumnInterface[]>([]);
  const [currentTypes, setCurrentTypes] = useState<string[]>([]);
  const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
  const [newDialog, setNewDialog] = useState<boolean>(false);

  const handleNameClick = (data: ApiLinkedNodeItem, event?: SyntheticEvent): void => {
    itemClick(data, event);
  };

  const handleRowClick = (data: any, event?: SyntheticEvent): void => {
    itemClick(data.dataItem, event);
  };

  const itemClick = (data: { type?: string, id?: string }, event?: SyntheticEvent): void => {
    if (event && ((event as unknown as MouseEvent).ctrlKey || (event as unknown as MouseEvent).metaKey)) {
      window.open(`/${ROUTE_PATH.newComponents}/${data.type}/${data.id}`, '_blank');
    } else {
      navigate(`/${ROUTE_PATH.newComponents}/${data.type}/${data.id}`);
    }
    event?.stopPropagation();
  };

  const nameCell = ({dataItem}: GridCellProps): ReactElement => {
    return <td>
      <ComponentItemNameCell data={{...dataItem, linkedNode: dataItem}}
                             onClick={handleNameClick}/>
    </td>;
  };

  const dateCell = (props: GridCellProps): ReactElement => {
    const dataItem: SpecInterface = props.dataItem;
    return (
      <td onClick={(e) => handleRowClick(props, e)}>
        {format(parseISO(dataItem.lastModified), DATE_TIME)}
      </td>
    );
  };

  const updateFilter = (field: string, filter: string) => {
    if (!!filter) {
      if (field === NODE_FILTER.types) {
        const params = ObjectHelper.pickParams(Object.fromEntries(searchParams.entries()), ['limit', 'showArchive']);
        setSearchParams(
          {
            ...Object.fromEntries(searchParams.entries()),
            ...params,
            offset: '0',
            [field]: filter
          });
      } else {
        setSearchParams(
          {
            ...Object.fromEntries(searchParams.entries()),
            offset: '0',
            [field]: filter
          });
      }
    }
  };

  const resetFilter = (field: string) => {
    searchParams.delete(field);
    setSearchParams(searchParams);
  };


  const typeSelectCell = (props: GridHeaderCellProps): ReactElement<any> => {
    let options: CheckboxFilterOption[] = [];
    if (Array.isArray(allNodeTypes)) {
      options = [...allNodeTypes]
        .sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
        .map((v) => ({
          name: v.id,
          value: v.id,
          checked: false
        }));
    }

    return <div>
      <CheckboxFilter {...props}
                      options={options}
                      selected={searchParams.get(NODE_FILTER.types)}
                      onFilter={(filter) => updateFilter(NODE_FILTER.types, filter)}
                      onReset={() => resetFilter(NODE_FILTER.types)}
      />
    </div>;
  };

  const textSearchCell = (props: any): ReactElement<any> => {
    return <div>
      <TextFilter
        {...props}
        onFilter={(filter) => updateFilter(NODE_FILTER.searchQuery, filter)}
        selected={searchParams.get(NODE_FILTER.searchQuery)}
        onReset={() => resetFilter(NODE_FILTER.searchQuery)}
      />
    </div>;
  };

  const dateSearchCell = (props: any, field: NODE_FILTER): ReactElement<any> => {
    return <div>
      <DateFilter
        {...props}
        onFilter={(filter) => updateFilter(field, filter)}
        selected={searchParams.get(field)}
        onReset={() => resetFilter(field)}
      />
    </div>;
  };

  const gridActions = (props: GridCellProps): ReactElement => {
    return <td className={props.className} style={{...props.style, textAlign: 'right'}}>
      <ComponentTypeActions nodeItem={props.dataItem}/>
    </td>;
  };

  const onColumnsChange = (newColumns: ColumnInterface[]) => {
    const columnsParam = searchParams.get('columns');
    let sortedColumns: ColumnInterface[] = [];
    let defaultCols: ColumnInterface[] = [];
    if (columnsParam) {
      const columnsParamsArr = columnsParam.split(';');
      const idCol = newColumns.find(col => col.field === 'id');
      if (idCol && !columnsParamsArr.includes('id')) {
        sortedColumns[0] = idCol;
        defaultCols = newColumns.filter(col => col.field !== 'id' && columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
      } else if (idCol) {
        defaultCols = newColumns.filter(col => columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
      }
      const others: ColumnInterface[] = newColumns.filter(col => defaultCols.every(c => c.field !== col.field)) || [];

      sortedColumns = [...sortedColumns, ...defaultCols, ...others];
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        'columns': sortedColumns.filter(col => col?.show).map(col => col?.field).join(';'),
      }, {replace: true});
    }
  };

  const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
    if (!column.searchField) {
      return null;
    }
    switch (column.type) {
      case 'type':
        return <>{typeSelectCell(props)}</>;
      case 'name':
        return <>{textSearchCell(props)}</>;
      case 'modified':
        return <>{dateSearchCell(props, NODE_FILTER.modified)}</>;
      case 'status':
        const options: CheckboxFilterOption[] = Object.entries(StatusLabel)
          .map(([key, value]) => ({
            name: value,
            value: key,
            checked: false
          }));

        return <div>
          <CheckboxFilter {...props}
                          options={options}
                          selected={searchParams.get('status')}
                          onFilter={(filter) => {
                            updateFilter('status', filter);
                          }}
                          onReset={() => resetFilter('status')}
          />
        </div>;
      default:
        return <div>
          <NodeFilter {...props}
                      onFilter={(field, filter) => updateFilter(field, filter)}
                      column={column}
                      onReset={resetFilter}
                      ignoreSearchType={column.searchField === 'id'}
          />
        </div>;
    }
    return null;
  };

  const getCell = (props: GridCellProps, field: string | undefined): ReactElement<HTMLTableCellElement> => {
    if (props.field === 'name') {
      return nameCell(props);
    }
    if (props.field === 'lastModified' || props.field === 'create') {
      return dateCell(props);
    }
    if (props.field === 'status') {
      return <td
        onClick={(e) => handleRowClick(props, e)}>{StatusLabel[props.dataItem.status as keyof typeof StatusLabel]}</td>;
    }
    if (field) {
      let value = '';
      if (props.field?.startsWith('calculatedData')) {
        value = props.dataItem.calculatedData[field];
      } else {
        value = props.dataItem[field];
      }
      return <td title={value ? value.toString() : ''} onClick={(e) => handleRowClick(props, e)}>
        <div className="grid-cell">{value && value.toString()}</div>
      </td>;
    } else {
      return <GridCell {...props}/>;
    }
  };

  const pageChange = (params: SearchParams): void => {
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()),
        offset: params.offset || '0',
        limit: params.limit || '50'
      });
  };

  const handleSearch = (term: string) => {
    if (term.trim().length) {
      setSearchParams(
        {
          ...Object.fromEntries(searchParams.entries()),
          offset: '0',
          searchQuery: term.trim()
        });
    } else {
      searchParams.delete('searchQuery');
      setSearchParams(searchParams);
    }
  };

  const onSort = (data: SortDescriptor[]): void => {
    if (!!data[0] && !!data[0].dir) {
      let field = data[0].field.replace('calculatedData.', 'd.');
      if (field === 'type') {
        field = 'nodeTypeName';
      } else if (field === 'lastModified') {
        field = 'lastModifiedDate';
      }
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        offset: '0',
        sortBy: field,
        sortDirection: data[0].dir
      });
    } else {
      searchParams.delete('sortBy');
      searchParams.delete('sortDirection');
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        offset: '0'
      });
    }
  };

  const handleReorderColumns = (event: GridColumnReorderEvent) => {
    const newOrder = [...event.columns];
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      'columns': newOrder.sort((a, b) => a.orderIndex! - b.orderIndex!).map(col => col.field).join(';'),
    }, {replace: true});
  };

  useEffect(() => {
    if (Array.isArray(allNodeTypes) && !!allNodeTypes.length) {
      const columnSet: ColumnInterface[] = !columns.length ? nodeTypeColumns : nodeTypeColumns.map((col) => (
        {
          ...col,
          show: !!columns.find((c) => c.title === col.title && c.type === col.type && c.show)
        }
      ));
      let defaultCols: string[] = [];
      let additionalCols: ColumnInterface[] = [];
      const columnsData: ColumnDataInterface[] = [];
      const types = !!currentTypes.length ? allNodeTypes.filter((v) => currentTypes.includes(v.id)) : allNodeTypes;
      for (const type of types) {
        if (Array.isArray(type.uiShowListColumns) && type.uiShowListColumns.length) {
          defaultCols = type.uiShowListColumns;
          for (const k in defaultCols) {
            columnsData.push({
              key: defaultCols[k],
              value: type.typeSchema?.properties[defaultCols[k]],
              columnType: type.id,
              default: true
            });
          }
        }
        if (ObjectHelper.isObject(type.typeSchema?.properties)) {
          let props = Object.entries(type.typeSchema.properties);
          if (!!defaultCols.length) {
            props = props.filter(p => !defaultCols.includes(p[0]));
          }
          if (props.length) {
            for (const k in props) {
              columnsData.push({
                key: props[k][0],
                value: type.typeSchema?.properties[props[k][0]],
                columnType: type.id
              });
            }
          }
        }
      }
      const reduced = columnsData.reduce((acc: { [key: string]: ColumnInterface }, current) => {
        const fieldType = current.value.type === 'object' ?
          current.value.properties?.value?.type
          : current.value.type;

        const key = `${current.key}:${fieldType}`;

        if (acc[key] && acc[key].type === fieldType) {
          acc[key] = {...acc[key], columnType: [...acc[key].columnType as string[], current.columnType]};
        } else {
          acc[key] = {
            title: current.value.title,
            type: fieldType,
            field: `calculatedData.${current.key}`,
            searchField: current.key,
            columnType: [current.columnType],
            show: !!columns.find((c) => c.title === current.value.title && c.type === fieldType && c.show),
            sortable: true,
            width: '200px',
          };
        }
        return acc;
      }, {});
      additionalCols = Object.entries(reduced).map(([k, v]) => v);
      let fullColumns = [...columnSet, ...additionalCols];

      const columnsParam = searchParams.get('columns');
      if (columnsParam) {
        const columnsParamsArr = columnsParam.split(';');
        fullColumns = fullColumns.sort((a, b) => {
          const indexA = columnsParamsArr.indexOf(a.field!);
          const indexB = columnsParamsArr.indexOf(b.field!);

          return indexA - indexB;
        });
      }

      setColumns(fullColumns);
    }
  }, [allNodeTypes, currentTypes]);


  useEffect(() => {
    if (!searchParams.get('limit')) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        offset: '0',
        limit: DEFAULT_PAGE_SIZE.toString(),
        showDraft: 'true'
      }, {replace: true});
    } else {
      const typesParam = searchParams.get(NODE_FILTER.types);
      !!typesParam ? setCurrentTypes(typesParam.split(';')) : setCurrentTypes([]);
      dispatch(getFilteredNodes(Object.fromEntries(searchParams.entries())))
        .unwrap().then((d: ApiNode) => {
        setData(d.result);
        setTotal(d.total);
      });
    }

    const sortBy = searchParams.get('sortBy');
    const sortDir = searchParams.get('sortDirection');
    if (!!sortBy && !!sortDir) {
      let field = sortBy.replace('d.', 'calculatedData.');
      if (field === 'nodeTypeName') {
        field = 'type';
      } else if (field === 'lastModifiedDate') {
        field = 'lastModified';
      }
      setSort([
        {
          field: field,
          dir: sortDir as any
        }
      ]);
    } else {
      setSort([]);
    }

  }, [searchParams]);

  useEffect(() => {
    const columnsParam = searchParams.get('columns');
    if (columnsParam && columns.length > 0) {
      const columnsParamsArr = columnsParam.split(';');
      const newColumns = columns.filter(column => !column.columnType || (column.columnType && currentTypes.some(col => column.columnType?.includes(col))))
        .map(column => column.field && columnsParamsArr.includes(column.field) ? {
          ...column,
          show: true,
        } : {
          ...column,
          show: false,
        });
      setColumns(newColumns);
    }
  }, [searchParams, columns.length]);

  useEffect(() => {
    const columnsParam = searchParams.get('columns');
    if (!columnsParam) {
      const typesParam = searchParams.get(NODE_FILTER.types);
      const selectedColumns = columns.filter(column => (column.columnType && column.columnType?.some(type => typesParam === type) && column.show) || (!column.columnType && column.show)).map(column => column.field).join(';');
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        'columns': selectedColumns,
      }, {replace: true});
    }
  }, [columns]);


  useEffect(() => {
    if (!allNodeTypes) {
      // необходимы типы компонентов для фильтра
      dispatch(getAllNodeTypes());
    }
  }, [allNodeTypes]);

  return (
    <div className={'node-type'}>
      <div className="node-type__breadcrumbs">
        <ComponentBreadcrumbs></ComponentBreadcrumbs>
      </div>
      <div className="node-type__header">
        <SectionHeader onSearch={handleSearch}
                       onCreate={() => setNewDialog(true)}
                       type={SECTION_HEADER.nodeType}
                       style={'inline'}
        />
      </div>
      <div className="node-type__grid">
        <AppSuspense condition={!!allNodeTypes}>
          <Card>
            <CardBody>
              <div className={'universal-grid'}>
                <Grid
                  className={'node-grid'}
                  style={{height: 'calc(100vh - 274px)', width: '100%'}}
                  sortable={true}
                  onSortChange={(e: GridSortChangeEvent) => {
                    onSort(e.sort);
                  }}
                  sort={sort}
                  data={data ?? []}
                  reorderable={true}
                  resizable={true}
                  onRowClick={handleRowClick}
                  onColumnReorder={handleReorderColumns}
                >
                  {columns.map((col, idx) => col.show && (
                    <GridColumn
                      key={idx}
                      orderIndex={idx}
                      field={col.field}
                      title={col.title?.toString()}
                      sortable={col.sortable}
                      cell={(props) => getCell(props, col.searchField)}
                      width={col.width || 'auto'}
                      headerCell={(props) => getHeaderCell(props, col)}
                      minResizableWidth={100}
                    />
                  ))}
                  <GridColumn field="" title="" width={100}
                              orderIndex={columns.length + 1}
                              headerClassName={'actions-menu'}
                              reorderable={false}
                              resizable={false}
                              locked={true}
                              cell={gridActions}
                              headerCell={() => (
                                <TypeColumnMenu
                                  columns={columns}
                                  searchParams={searchParams}
                                  onFilter={(filter: string) => updateFilter('columns', filter)}
                                  onColumnsChange={onColumnsChange}
                                  type={'component'}
                                />
                              )}/>


                  <NoRecords>
                    {!!data && !data?.length && ('Список пуст.')}
                    {!data && ('Загрузка данных...')}
                  </NoRecords>

                </Grid>
              </div>
              <div className="node-pager-wrapper">
                <div className="page-info">

                </div>
                <div className="node-pager">
                  <AppPagination params={{
                    offset: searchParams.get('offset') || undefined,
                    limit: searchParams.get('limit') || undefined,
                  }} total={total}
                                 onPageChange={pageChange}
                                 showButtons={true}
                                 pageSizes={[10, 50, 100, 200]}
                  />

                </div>
                <div className="download">

                </div>
              </div>
            </CardBody>
          </Card>
        </AppSuspense>
      </div>
      {newDialog && (
        <NewComponentDialog handleClose={() => setNewDialog(false)}/>
      )}
    </div>
  );
};

export {NodeItem};
