import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {
    SpecCreateTemplateRequest,
    SpecificationItemActionData,
    SpecItem, SpecTemplate
} from '../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {getSpecification, SpecificationState} from '../../../../store/slice/spec-slice';
import {showSuccess} from '../../../../store/slice/toast-slice';
import {createTemplate, renameTemplate} from '../../../../store/slice/template-slice';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {translation} from '../../../../helpers';
import {JsonForms} from '@jsonforms/react';
import {TemplateDialogSchema, TemplateDialogUiSchema} from './template-dialog.meta';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';

import AsyncSelectControl, {
    asyncSelectTester
} from '../../../../ui/json-form-renderers/async-select-renderer/async-select-control';
import TextControl, {textControlTester} from "../../../../ui/json-form-renderers/input-control-renderers/TextControl";
import IntegerControl, {
    integerControlTester
} from "../../../../ui/json-form-renderers/input-control-renderers/IntegerControl";


interface TemplateDialogProps {
    specification?: SpecificationItemActionData;
    template?: SpecTemplate;
    onClose: (reload: boolean) => void;
    itemPage?: boolean
}

interface DialogData {
    templateName: string;
    groupName: string;
}

const renderers = [...materialRenderers,
    {tester: formulaInputTester, renderer: FormulaInputRenderer},
    {tester: asyncSelectTester, renderer: AsyncSelectControl},
    {tester: textControlTester, renderer: TextControl},
    {tester: integerControlTester, renderer: IntegerControl},
];


const TemplateDialog = ({specification, template, itemPage, onClose}: TemplateDialogProps) => {
    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {currentSpecState} = useAppSelector<SpecificationState>(store => store.spec);
    const [data, setData] = useState<DialogData>({} as DialogData);
    const [formData, setFormData] = useState<any>({});
    const [item, setItem] = useState<SpecTemplate | SpecItem>();
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const onSubmit = async (e: any) => {
        e.preventDefault();

        const {data} = formData;
        if (!item?.id) {
            return;
        }
        setInProgress(true);

        if (isEdit) {
            updateTemplate(item as SpecTemplate, data);
        } else {
            if (!itemPage) {
                await dispatch(getSpecification(item.id)).unwrap()
                    .then((data: SpecItem) => {
                        addTemplate(item as SpecItem, data);
                    })
                    .catch(() => {
                        setInProgress(false);
                    });
            } else {
                addTemplate(item as SpecItem, data);
            }
        }


    };

    const updateTemplate = async (item: SpecTemplate, data: any) => {
        if (item?.name === data?.templateName && item?.groupName === data?.groupName) {
            setInProgress(false);
            onClose(false);
            return;
        }
        await dispatch(renameTemplate({
            id: item.id,
            name: data.templateName,
            groupName: data.groupName || ' '
        })).unwrap()
            .then((data: any) => {
                if (!data.error) {
                    dispatch(showSuccess('Данные шаблона изменены'));
                    onClose(true);
                }
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    const getForms = (item: SpecItem,): any => {
        const formsData: { [key: string]: any } = {};
        if (currentSpecState?.tabs && Array.isArray(currentSpecState.tabs)) {
            for (const tab of currentSpecState.tabs) {
                if (tab.forms && Array.isArray(tab.forms)) {
                    for (const form of tab.forms) {
                        formsData[form.key] = {data: form.data};
                    }
                }
            }
        }

        const forms = {...item.doc.forms};

        Object.keys(forms).forEach((key: any) => {
            forms[key] = {...forms[key], ...formsData[key]};
        });

        return forms;
    };


    const addTemplate = async (item: SpecItem, formData: any) => {

        const data: SpecCreateTemplateRequest = {
            ...formData,
            specId: item?.id,
            forms: getForms(item)
        };

        await dispatch(createTemplate(data)).unwrap()
            .then((data: any) => {
                if (!data.error) {
                    dispatch(showSuccess('Шаблон успешно сохранен'));
                    onClose(true);
                }
            })
            .catch((e: any) => {
                setInProgress(false);
                onClose(true);
            });
    };

    const handleChange = (data: any): void => {
        setFormData(data);
    };

    useEffect(() => {
        const item = !!template ? template : specification?.item;
        setIsEdit(!!template);
        setItem(item);
        setData({
            templateName: !!template ? item?.name || '' : `Шаблон ${item?.name}` || '',
            groupName: !!template ? template.groupName : ''
        });
    }, []);


    return (
        <Dialog
            className="rename-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={() => onClose(false)}
        >
            <DialogTitle className="dialog-title">
                {isEdit ? `Свойства шаблона "${item?.name || ''}"` : 'Сохранить как шаблон'}
            </DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    <JsonForms
                        data={data}
                        schema={TemplateDialogSchema}
                        uischema={TemplateDialogUiSchema}
                        renderers={renderers}
                        cells={materialCells}
                        onChange={handleChange}
                        i18n={{locale: 'ru', translate: translation}}
                    />

                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        variant={'outlined'}
                        onClick={() => onClose(false)}
                        disabled={inProgress}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'submit'}
                        variant={'contained'}
                        disabled={inProgress || !!formData.errors?.length}
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>
    );
};

export {TemplateDialog};
