import {ReactComponent as IconHome} from '../../../../assets/img/icon/home.svg';
import {ReactComponent as IconChevronRight} from '../../../../assets/img/icon/chevron-right.svg';
import {NavLink} from 'react-router-dom';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {ROUTE_PATH} from '../../../../constants/routes';

interface ComponentBreadcrumbsProps {
    type?: {
        id: string,
        name?: string
    };
    node?: string;
}

const ComponentBreadcrumbs = ({type, node}: ComponentBreadcrumbsProps) => {

    return <>
        <Card>
            <CardBody className={'breadcrumbs-card'}>
                <div className="breadcrumbs">
                    <NavLink to={'/'}><IconHome></IconHome></NavLink>
                    <IconChevronRight></IconChevronRight>
                    <div className="item">
                        <NavLink
                            to={`/${ROUTE_PATH.newComponents}`}>{'Компоненты'}</NavLink>
                    </div>
                    {!!type && !node && (
                        <>
                            <IconChevronRight></IconChevronRight>
                            <div className="item">
                                {type.name}
                            </div>
                        </>

                    )}
                    {!!type && !!node && (
                        <>
                            <IconChevronRight></IconChevronRight>
                            <div className="item">
                                <NavLink
                                    to={`/${ROUTE_PATH.newComponents}/type?types=${type.id}`}>{type.name}</NavLink>
                            </div>
                            <IconChevronRight></IconChevronRight>
                            <div className="item">
                                {node}
                            </div>
                        </>

                    )}
                </div>
            </CardBody>
        </Card>
    </>;
};

export {ComponentBreadcrumbs};
