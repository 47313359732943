import {ComponentType} from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import {ColumnInterface} from "../../../../../components";

export const componentItemConstraintColumns = (
    typeCell: ComponentType<GridCellProps>,
    valueCell: ComponentType<GridCellProps>,
    predicateCell: ComponentType<GridCellProps>,
): ColumnInterface[] => ([
    {
        title: 'ID',
        field: 'id',
        show: true,
        sortable: true,
        width: '150px'
    }, {
        title: 'Тип',
        field: 'type',
        show: true,
        sortable: true,
        cell: typeCell,
        width: '200px'
    },
    {
        title: 'Название',
        field: 'name',
        show: true,
        sortable: true,
        width: '250px'
    },
    {
        title: 'Активировать, когда',
        field: 'activationPredicate',
        show: false,
        sortable: true,
        cell: valueCell,
        width: '250px'
    },
    {
        title: 'Формула проверки',
        field: 'consistency.predicate',
        show: false,
        sortable: true,
        cell: predicateCell,
        width: '250px'
    },
    {
        title: 'Сообщение',
        field: 'consistency.message',
        show: false,
        sortable: true,
        width: '250px'
    }
]);
