import {ApiModelType, ApiModelTypeResult} from '../../interface';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {SearchParams} from '../../interface';

export interface ModelTypeState {
  models: ApiModelType[];
  currentModel: ApiModelType | null;
  total: number;
}

export const getModelTypes = createAsyncThunk(
  'modelType/getModelTypes',
  async function (params: SearchParams, {dispatch}) {
    const response = await baseRequest(
      {
        method: 'get',
        url: '/api/modelType',
        params,
      }, dispatch);
    return response.data;
  }
);


export const getModelType = createAsyncThunk(
  'modelType/getModelTypes',
  async function (id: number, {dispatch}) {
    const response = await baseRequest(
      {
        method: 'get',
        url: `/api/modelType/${id}`,
      }, dispatch);
    return response.data;
  }
);


export const deleteModelType = createAsyncThunk(
  'spec/deleteModelType',
  async function (id: number, {dispatch}) {
    const response = await baseRequest({
      method: 'delete',
      url: `/api/modelType/${id}`
    }, dispatch);
    return response.data;
  }
);


const initialState: ModelTypeState = {
  models: [],
  total: 0,
  currentModel: null
};

const modelTypeSlice = createSlice({
  name: 'modelType',
  initialState,
  reducers: {
    clearCurrentModel(state: ModelTypeState) {
      state.currentModel = null;
    }
  },
  extraReducers: {
    [getModelTypes.fulfilled as any]: (state: ModelTypeState, action: PayloadAction<ApiModelTypeResult>) => {
      if (action.payload?.result) {
        state.models = action.payload.result;
        state.total = action.payload.total;
      }
    },
    [getModelType.fulfilled as any]: (state: ModelTypeState, action: PayloadAction<ApiModelType>) => {
      state.currentModel = action.payload;
    },
  }
});

export const {clearCurrentModel} = modelTypeSlice.actions;
export default modelTypeSlice.reducer;
