import {CSSProperties, PropsWithChildren, useEffect, useRef, useState} from "react";

const styles: CSSProperties = {
    position: 'fixed',
    top: 0,
    zIndex: 99,
    background: 'rgba(255,255,255,.9)',
    padding: '8px 24px 8px 8px',
    margin: '0 -8px',
    boxShadow: '0px 5px 8px -3px rgba(0, 0, 0, 0.1)'
}

const Sticky = (props: PropsWithChildren<{}>) => {
    const [offset, setOffset] = useState<number | undefined>(undefined);
    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [style, setStyle] = useState<CSSProperties>({});
    const elementRef = useRef<HTMLDivElement>(null);


    const handleScroll = () => {
        if (elementRef.current) {

            const boundingRect = elementRef.current.getBoundingClientRect();
            setOffset(boundingRect.y);
            setWidth(boundingRect.width);
            setHeight(boundingRect.height);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])

    return (
        <>
            {!!offset && offset <= 8 && (
                <div style={{...styles, width: width}}>
                    {props.children}
                </div>
            )}
            <div ref={elementRef} style={{width: '100%'}}>
                {props.children}
            </div>
            <div style={{visibility: 'hidden', height}}/>
        </>
    );
};

export {Sticky};
