import styles from '../../styles/type-edit.module.scss';

import {useOutletContext} from 'react-router-dom';

import {AdminNodeItemOutletContext} from './node-item-edit';
import {AppMultiselect} from '../../components/multiselect/app-multiselect';
import {OptionListItem} from '../../../../interface/common.interface';
import {isEmpty, notEmpty} from '../../../../utils/array.util';
import {
    AdminNode,
    AdminNodeSchemaData, AdminSchemaTypeSchemaType,
} from '../../../../interface/admin/admin-node.interface';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import React, {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {AdminState, getAllNodes, getAllTraits} from '../../../../store/slice/admin-slice';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {AdminTrait} from '../../../../interface/admin/admin-traits.interface';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    createTheme,
    FormControl,
    FormGroup,
    FormLabel,
    TextField, ThemeProvider
} from '@mui/material';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Attribute, FormAttribute} from '../../components/attribute/attribute';
import {LoadingButton} from '@mui/lab';
import {AddCircleOutline} from '@mui/icons-material';
import {typeAttributesTheme} from "../../admin.meta";
import {NewAttributeDialog} from "../../components/attribute/new/new-attribute-dialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Sticky} from "../../../../components/sticky/sticky";

const validationSchema = Yup.object().shape({
    typeName: Yup.string()
        .required('Обязательное поле'),
    allowChildren: Yup.array(),
    requireTraits: Yup.array(),
    properties: Yup.array().of(
        Yup.object().shape({
            type: Yup.string()
                .required('Обязательное поле'),
            name: Yup.string()
                .required('Обязательное поле')
                .matches(/^[a-zA-Z0-9_]*$/, {
                    message: 'Название может содержать только латинские букы, цифры и знак подчеркивания'
                }),
            title: Yup.string()
                .required('Обязательное поле'),
            required: Yup.boolean(),
            restrictions: Yup.boolean(),
            minimum: Yup.number()
                .transform((value) => Number.isNaN(value) ? null : value)
                .nullable()
                .min(0, '>= 0'),
            maximum: Yup.number()
                .transform((value) => Number.isNaN(value) ? null : value)
                .nullable()
                .positive('>= 0')
                .moreThan(Yup.ref('minimum'), '> min'),
        })
    )
});

const formOptions = {resolver: yupResolver(validationSchema)};

const theme = createTheme(typeAttributesTheme);

const NodeItemEditForm = () => {
    const dispatch = useAppDispatch();
    const {nodeList, traitList} = useAppSelector<AdminState>(store => store.admin);
    const {sharedData, onDataChange, onDelete, onUpdate} = useOutletContext<AdminNodeItemOutletContext>();
    const [isReady, setIsReady] = useState<boolean>(false);
    const [formFields, setFormFields] = useState<Partial<FormAttribute>[]>([]);
    const [showAddAttribute, setShowAddAttribute] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(true);

    const {
        handleSubmit,
        control,
        register,
        getValues,
        trigger,
        formState: {errors, isValid, isValidating, isLoading},
    } = useForm<any>({...formOptions, mode: 'all'});

    const {fields, prepend, remove, replace} = useFieldArray({name: 'properties', control});

    useEffect(() => {
        setTimeout(() => trigger(), 1000);

    }, [trigger]);

    useEffect(() => {
        handleFormChange();
    }, [errors, isValid, isValidating]);

    useEffect(() => {
        if (!nodeList) {
            dispatch(getAllNodes());
        }
        if (!traitList) {
            dispatch(getAllTraits());
        }
        if (notEmpty(nodeList) && notEmpty(traitList) && !!sharedData) {
            setIsReady(true);
        }
    }, [nodeList, traitList]);

    useEffect(() => {
        const props = sharedData?.typeSchema?.properties;
        const required = sharedData?.typeSchema?.required || [];
        const fields: Partial<FormAttribute>[] = [];
        if (!!props) {
            for (const [key, value] of Object.entries(props)) {
                fields.push({
                    name: key,
                    ...value,
                    required: required.includes(key),
                    restrictions: value.minimum !== undefined || value.maximum !== undefined,
                });
            }
            setFormFields(fields);
        }

    }, [sharedData]);

    useEffect(() => {

        if (notEmpty(formFields)) {
            // use react-hook-form append method
            replace(formFields);
            handleFormChange();
        }
    }, [formFields]);

    const attributeList = useMemo(() => {
        if (isEmpty(fields)) {
            return [];
        }
        return fields.map((f: any) => f?.name?.toLowerCase());
    }, [fields]);


    const handleFormChange = () => {
        if (!isValid || isValidating) {
            return;
        }
        const formData = getValues();
        const required = formData.properties.filter((prop: FormAttribute) => prop.required).map((prop: FormAttribute) => prop.name);
        const itemProps = sharedData?.typeSchema?.properties || [];
        const properties = formData.properties.reduce((acc: AdminSchemaTypeSchemaType, curr: FormAttribute) => {
            let property: Partial<FormAttribute> = {};
            if (itemProps[curr.name]) {
                property = {
                    ...itemProps[curr.name],
                    title: curr.title,
                    type: curr.type,
                    ...(curr.minimum && {minimum: Number(curr.minimum)}),
                    ...(curr.maximum && {maximum: Number(curr.maximum)})
                };
            } else {
                property = {
                    title: curr.title,
                    type: curr.type,
                    ...(curr.minimum && {minimum: Number(curr.minimum)}),
                    ...(curr.maximum && {maximum: Number(curr.maximum)})

                };
            }
            if (curr.maximum === undefined || isNaN(Number(curr.maximum)) || !curr.restrictions) {
                delete property.maximum;
            }
            if (curr.minimum === undefined || isNaN(Number(curr.minimum)) || !curr.restrictions) {
                delete property.minimum;
            }
            acc[curr.name] = property;
            return acc;
        }, {});

        const data: AdminNodeSchemaData = {
            typeName: formData.typeName,
            allowChildren: formData.allowChildren.map((item: OptionListItem) => item.value),
            requireTraits: formData.requireTraits.map((item: OptionListItem) => item.value),
            typeSchema: {
                ...sharedData.typeSchema,
                required,
                properties
            }
        };
        onDataChange(data);
    };

    const toOptionList = (list: AdminNode[] | AdminTrait[]): OptionListItem[] => {
        return list.map(item => ({
            value: item.id,
            title: item.name
        }));
    };

    const nodeOptions = useMemo((): OptionListItem[] => {
        if (!nodeList) {
            return [];
        }
        return toOptionList(nodeList);
    }, [nodeList]);

    const nodeOptionsSelected = useMemo((): OptionListItem[] => {
        const items = sharedData?.allowChildren;
        if (!items || !nodeList || isEmpty(items)) {
            return [];
        }
        return toOptionList(nodeList.filter((node) => items?.includes(node.id)));
    }, [nodeList, sharedData]);


    const traitsOptions = useMemo((): OptionListItem[] => {
        if (!traitList) {
            return [];
        }
        return toOptionList(traitList);
    }, [traitList]);

    const traitsOptionsSelected = useMemo((): OptionListItem[] => {
        const items = sharedData?.requireTraits;
        if (!items || !traitList || isEmpty(items)) {
            return [];
        }
        return toOptionList(traitList.filter((node) => items.includes(node.id)));
    }, [traitList, sharedData]);


    const handleAddAttribute = (e: React.MouseEvent) => {
        e.stopPropagation();
        setShowAddAttribute(true);
    };

    const addAttribute = (data: { name: string }) => {
        setShowAddAttribute(false);
        setExpanded(true);
        prepend({
            name: data.name,
            title: '',
            type: '',
            required: false,
            restrictions: false,
            minimum: null,
            maximum: null
        });
        trigger();
    };


    return (
        <ThemeProvider theme={theme}>
            <AppSuspense condition={isReady}>
                <div className={styles.adminTypeItem__content_form}>
                    <div className={styles.adminTypeItem__content_form_fields}>
                        <form onSubmit={handleSubmit((data) => console.log(data))}>
                            <FormGroup sx={{gap: '24px'}}>
                                <FormControl error={!!errors?.typeName}>
                                    <FormLabel>
                                        Название типа компонента
                                    </FormLabel>
                                    <TextField
                                        title={sharedData?.typeName || ''}
                                        variant="standard"
                                        error={!!errors?.typeName}
                                        defaultValue={sharedData?.typeName || ''}
                                        helperText={errors?.typeName ? errors?.typeName.message as string : ''}
                                        {...register('typeName')}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Допустимые типы дочерних компонентов
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name="allowChildren"
                                        defaultValue={nodeOptionsSelected}
                                        render={({field: {onChange, onBlur, value, ref}}) => (
                                            <AppMultiselect
                                                options={nodeOptions}
                                                selected={nodeOptionsSelected}
                                                onChange={(e, v) => onChange(v)}
                                                limitTags={3}
                                            />
                                        )}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>
                                        Наследуемые характеристики
                                    </FormLabel>
                                    <Controller
                                        control={control}
                                        name="requireTraits"
                                        defaultValue={traitsOptionsSelected}
                                        render={({field: {onChange, onBlur, value, ref}}) => (
                                            <AppMultiselect
                                                options={traitsOptions}
                                                selected={traitsOptionsSelected}
                                                onChange={(e, v) => onChange(v)}
                                                limitTags={2}
                                            />
                                        )}/>

                                </FormControl>
                            </FormGroup>
                            <FormGroup sx={{gap: '8px'}}>
                                <Accordion expanded={expanded} onChange={(e, state) => setExpanded(state)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="attributes-content"
                                        id="attributes-header"
                                    >
                                        <Sticky>
                                            <div className={styles.adminTypeItem__content_form_attribute_header}>
                                                <FormLabel sx={{marginBottom: 0}}>Атрибуты</FormLabel>
                                                <Button onClick={handleAddAttribute}
                                                        sx={{
                                                            color: '#00BEC8',
                                                            fontSize: '14px',
                                                            lineHeight: '16px',
                                                            marginRight: '12px'
                                                        }}
                                                        endIcon={<AddCircleOutline sx={{fontSize: 14}}/>}
                                                >
                                                    Добавить атрибут
                                                </Button>
                                            </div>
                                        </Sticky>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {fields.map((field, index) => (
                                                <Attribute
                                                    key={field.id}
                                                    register={register}
                                                    errors={() => errors}
                                                    control={control}
                                                    field={field}
                                                    index={index}
                                                    onDelete={() => {
                                                        remove(index);
                                                        handleFormChange();
                                                    }}
                                                />
                                            )
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </FormGroup>
                        </form>
                    </div>
                    <div className={styles.adminTypeItem__content_form_actions}>
                        <LoadingButton
                            sx={{width: '100%'}}
                            variant={'contained'}
                            disableElevation={true}
                            disabled={!isValid || isValidating}
                            onClick={onUpdate}
                        >
                            Сохранить
                        </LoadingButton>
                        <LoadingButton
                            sx={{width: '100%'}}
                            variant={'outlined'}
                            disableElevation={true}
                            onClick={onDelete}
                        >
                            Удалить
                        </LoadingButton>
                    </div>
                </div>
                {showAddAttribute && (
                    <NewAttributeDialog
                        onClose={() => setShowAddAttribute(false)}
                        onSubmit={addAttribute}
                        existing={attributeList}
                    />
                )}

            </AppSuspense>
        </ThemeProvider>
    );
};

export {NodeItemEditForm};
