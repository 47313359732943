import {
    Grid,
    IconButton,
    Hidden,
    Toolbar,
    Tooltip,
    Typography, Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import {UnfoldLess, UnfoldMore} from "@mui/icons-material";

export interface ArrayLayoutToolbarProps {
    label: string;
    errors: string;
    path: string;

    addItem(path: string, data: any): () => void;

    collapseAll: () => void;
    expandAll: () => void;

    createDefault(): any;

    enabled: boolean;
}

export const ArrayLayoutToolbar = React.memo(
    ({
         label,
         errors,
         addItem,
         path,
         createDefault,
         collapseAll,
         expandAll,
         enabled
     }: ArrayLayoutToolbarProps) => {
        return (
            <Toolbar disableGutters={true}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant={'h6'}>{label}</Typography>
                    </Grid>
                    <Hidden smUp={Array.isArray(errors) && errors.length === 0}>
                        <Grid item>

                        </Grid>
                    </Hidden>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Tooltip
                                    id="tooltip-collapse"
                                    title={'Свернуть все'}
                                    placement="bottom"
                                >
                                    <IconButton
                                        aria-label={`Свернуть все`}
                                        onClick={collapseAll}
                                        size="large">
                                        <UnfoldLess/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    id="tooltip-expand"
                                    title={`Развернуть все`}
                                    placement="bottom"
                                >
                                    <IconButton
                                        aria-label={`Развернуть все`}
                                        onClick={expandAll}
                                        size="large">
                                        <UnfoldMore/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {enabled && (
                                <Grid item>
                                    <Tooltip
                                        id="tooltip-add"
                                        title={`Добавить к ${label}`}
                                        placement="bottom"
                                    >
                                        <IconButton
                                            aria-label={`Добавить к ${label}`}
                                            onClick={addItem(path, createDefault())}
                                            size="large">
                                            <AddIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        );
    }
);
