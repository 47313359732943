import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ApiUser,
  ApiUserCreate,
  ApiUserList,
  ApiUserPasswordUpdate,
  ApiUserUpdate,
  SearchParams
} from '../../interface';
import baseRequest from '../../utils/base-request';
import {getRoles} from './role-slice';

export interface UserState {
  userList: ApiUser[] | null;
  selectedUser: ApiUser | null;
  currentUser: ApiUser | null;
  total: number;
  searchParams: SearchParams;
  isForbiddenUser: boolean;
}

export const getUsers = createAsyncThunk(
  'user/getUsers',
  async function (params: SearchParams, {dispatch}) {
    dispatch(setUserSearchParams(params));
    const response = await baseRequest({
      method: 'get',
      url: '/api/user',
      params
    }, dispatch);
    return response.data;
  }
);

export const getUser = createAsyncThunk(
  'user/getUser',
  async function (id: string, {dispatch}) {
    const response = await baseRequest({
      method: 'get',
      url: `/api/user/${id}`,
    }, dispatch);
    return response.data;
  }
);

interface ICurrentUser {
  isLogin: boolean;
}

export const getCurrentUser = createAsyncThunk(
  'user/getCurrentUser',
  async function (param: ICurrentUser, {dispatch}) {
    try {
      dispatch(getRoles());
      const response = await baseRequest({
        method: 'get',
        url: '/api/user/current',
      }, dispatch);
      return response.data;
    } catch (e: any) {
      throw new Error(e);
    }
  }
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async function (data: ApiUserCreate, {dispatch}) {
    const response = await baseRequest({
      method: 'post',
      url: '/api/user',
      data
    }, dispatch);
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async function (data: ApiUserUpdate, {dispatch}) {
    const response = await baseRequest({
      method: 'put',
      url: '/api/user',
      data
    }, dispatch);
    return response.data;
  }
);

export const updateUserPassword = createAsyncThunk(
  'user/updateUserPassword',
  async function (data: ApiUserPasswordUpdate, {dispatch}) {
    const response = await baseRequest({
      method: 'put',
      url: '/api/user/password',
      data
    }, dispatch);
    return response.data;
  }
);


export const deleteUser = createAsyncThunk<void, number, { state: { user: UserState } }>(
  'user/deleteUser',
  async function (id: number, {dispatch, getState}) {
    const response = await baseRequest({
      method: 'delete',
      url: `/api/user/${id}`,
    }, dispatch);

    const {searchParams} = getState().user;
    dispatch(getUsers(searchParams));

    return response.data;
  }
);

const initialState: UserState = {
  userList: null,
  selectedUser: null,
  currentUser: null,
  searchParams: {} as SearchParams,
  total: 0,
  isForbiddenUser: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSearchParams(state: UserState, action: PayloadAction<SearchParams>) {
      state.searchParams = action.payload;
    },
    clearSelectedUser(state: UserState) {
      state.selectedUser = null;
    },
    resetUserState() {
      return initialState;
    },
  },
  extraReducers: {
    [getUsers.fulfilled as any]: (state: UserState, action: PayloadAction<ApiUserList>) => {
      state.userList = action.payload.result;
      state.total = action.payload.total;
    },
    [getUser.fulfilled as any]: (state: UserState, action: PayloadAction<ApiUser>) => {
      state.selectedUser = action.payload;
    },
    [addUser.fulfilled as any]: (state: UserState, action: PayloadAction<ApiUser>) => {
      state.selectedUser = null;
    },
    [getCurrentUser.fulfilled as any]: (state: UserState, action: PayloadAction<ApiUser>) => {
      state.currentUser = action.payload;
    },
    [getCurrentUser.rejected as any]: (state: UserState, action: any) => {
      if (!action.payload && action.meta.arg && action.meta.arg.isLogin) {
        state.isForbiddenUser = true;
      }
      state.currentUser = {} as ApiUser;
    }
  }
});

export const {clearSelectedUser, setUserSearchParams, resetUserState} = userSlice.actions;
export default userSlice.reducer;

