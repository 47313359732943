import {ColumnInterface} from '../../components';


export const modelTypeColumns: ColumnInterface[] = [
    {
        title: 'ID',
        field: 'id',
        type: 'integer',
        searchField: 'id',
        show: true,
        sortable: true,
        width: '100px'
    },
    {
        title: 'Название',
        field: 'namespace',
        type: 'name',
        searchField: 'namespace',
        show: true,
        sortable: true,
        width: '500px'
    },
    {
        title: 'Тип',
        field: 'typeNumber',
        type: 'integer',
        searchField: 'typeNumber',
        show: true,
        sortable: true,
        width: '150px'
    },
    {
        title: 'Дата',
        field: 'created',
        type: 'created',
        searchField: 'created',
        show: true,
        sortable: true,
    },
];
