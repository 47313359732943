export enum SpecificationStatus {
    calculated = 'calculated',
    empty = 'empty',
    error = 'error',
    inProgress = 'inProgress'
}

export enum SpecificationStatusTitle{
    calculated = 'Спецификация рассчитана',
    empty = 'Спецификация не рассчитана',
    error = 'Ошибка в расчете',
    inProgress = 'Пересчет спецификации...'
}
