
export const TemplateDialogUiSchema = {
    type: 'VerticalLayout',

    elements: [
        {
            type: 'Control',
            label: 'Название',
            scope: '#/properties/templateName',
        },
        {
            type: 'Control',
            label: 'Группа',
            scope: '#/properties/groupName',
        }
    ]
};

export const TemplateDialogSchema = {
    type: 'object',
    properties: {
        templateName: {
            type: 'string',
            title: 'Название',
        },
        groupName: {
            type: 'string',
            title: 'Группа',
            custom: true,
            options: {
                freeSolo: true,
                path: '/api/spec2/templates/groups',
            }
        },
    },
    required: ['templateName']
};
