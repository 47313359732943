import {LoadingButton} from '@mui/lab';
import {Button, Grow} from '@mui/material';
import React, {Fragment} from 'react';


interface CalculationButtonsProps {
    handleRecalculate: () => void;
    cancelRecalculation: () => void;
    navigateToMaster: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled: boolean;
    inProgress: boolean;
    progressPercentage: number;
    isCancelling: boolean;
    isSpecStage: boolean;
}

const CalculationButtons = ({
                                handleRecalculate,
                                cancelRecalculation,
                                navigateToMaster,
                                disabled,
                                progressPercentage,
                                inProgress,
                                isCancelling,
                                isSpecStage
                            }: CalculationButtonsProps) => {
    return (
        <div className="info__status__recalculate">
            {isSpecStage ? (
                <Button
                    variant={'contained'}
                    disableElevation={true}
                    onClick={navigateToMaster}
                >
                    Перейти ко всем этапам
                </Button>
            ) : (
                <Fragment>
                    <LoadingButton className=""
                                   variant={'contained'}
                                   disableElevation={true}
                                   onClick={handleRecalculate}
                        // loading={isLoading}
                                   disabled={disabled}>
                                            <span className={inProgress ? 'progress active' : 'progress'}>
                                                <span className="percent">{progressPercentage}%</span>
                                                <span className="label">Пересчитать</span>
                                            </span>
                    </LoadingButton>
                    {inProgress && (
                        <Grow in={true}>
                            <LoadingButton
                                loading={isCancelling}
                                disabled={isCancelling}
                                loadingPosition="end"
                                endIcon={<></>}
                                onClick={cancelRecalculation}>
                                <span>{isCancelling ? 'Отмена' : 'Отменить'}</span>
                            </LoadingButton>
                        </Grow>
                    )}
                </Fragment>
            )}

        </div>
    );
};

export {CalculationButtons};
