import {Generate, JsonSchema, Layout, Translator, UISchemaElement} from '@jsonforms/core';
import {ErrorObject} from "ajv";

export const JsonFormsGenerateUISchema = (jsonSchema: JsonSchema, uiSchema?: UISchemaElement) => {
  const generatedSchema: any = Generate.uiSchema(jsonSchema);
  if (!uiSchema) {
    return generatedSchema;
  }
  const elements: UISchemaElement[] = (uiSchema as Layout).elements;
  if (!elements || !elements.length) {
    return generatedSchema;
  }

  generatedSchema.elements = generatedSchema.elements
    .map((item: any) => {
      const el = (uiSchema as Layout).elements.find((e: any) => e.scope === item.scope);
      if (el) {
        return Object.assign({}, item, el);
      }
      return item;
    });

  return generatedSchema;
};

export const createTranslator = () => (key: any, defaultMessage: any): string => {
  // console.log(`Locale: RU, Key: ${key}, Default Message: ${defaultMessage}`);

  if (!defaultMessage) {
    return defaultMessage;
  }
  switch (defaultMessage.toLowerCase().trim()) {
    case 'is a required property':
      return 'Обязательное поле';
    case 'must be equal to one of the allowed values':
      return 'Поле должно содержать одно из разрешенных значений';
    case 'should be equal to one of the allowed values':
      return 'Поле должно содержать одно из разрешенных значений';
    case 'must be string':
      return 'Поле должно содержать строку';
    case 'must be integer':
      return 'Поле должно содержать число';
  }
  return defaultMessage;
};

export const translation = createTranslator();

export const translateError = (error: ErrorObject, translate: Translator, uischema?: UISchemaElement): string => {
  switch (error?.message) {
    case 'is a required property':
      return 'Обязательное поле';
    case 'must be equal to one of the allowed values':
      return 'Поле должно содержать одно из разрешенных значений';
    case 'should be equal to one of the allowed values':
      return 'Поле должно содержать одно из разрешенных значений';
    case 'must be string':
      return 'Поле должно содержать строку';
    case 'must be integer':
      return 'Поле должно содержать число';
  }
  if(error.keyword === 'required'){
    return 'Обязательное поле';
  }
  return '';
};
