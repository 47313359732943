import {
    SpecItemDocInvoice, SpecItemDocInvoiceColumn, SpecItemDocInvoiceFooter,
    SpecItemDocInvoiceHeader, SpecItemDocInvoiceLine, SpecItemDocModelFooter,
    SpecItemDocModelHeader,
    SpecItemDocModelSection, SpecItemDocModelSectionColumn, SpecItemDocModelSectionLine
} from '../../interface';
import {CSSProperties, ReactElement, useEffect, useRef, useState} from 'react';
import './app-table-view.scss';
import {ChevronRight} from '@mui/icons-material';
import Cookies from 'js-cookie';
import {Property} from "csstype";

interface AppTableViewProps {
    data: SpecItemDocModelSection | SpecItemDocInvoice
    reportType: string;
}

interface AppTableExpanded {
    header: boolean;
    footer: boolean
}

const TABLE_COOKIE = 'table-settings';

const AppTableView = ({data, reportType}: AppTableViewProps) => {
    const [headerData, setHeaderData] = useState<SpecItemDocInvoiceHeader | SpecItemDocModelHeader>();
    const [footerData, setFooterData] = useState<SpecItemDocInvoiceFooter | SpecItemDocModelFooter>();
    const [expanded, setExpanded] = useState<AppTableExpanded>({header: true, footer: true});

    const [headerRef, setHeaderRef] = useState<HTMLTableElement | null>(null);
    const [footerRef, setFooterRef] = useState<HTMLTableElement | null>(null);
    const [mainTableHeaderHidden, setMainTableHeaderHidden] = useState<boolean>(false);
    const [stickyHeaderRef, setStickyHeaderRef] = useState<HTMLDivElement | null>(null);
    const [stickyHeaderWidth, setStickyHeaderWidth] = useState<string>('100%');
    const [stickyHeaderCellsWidth, setStickyHeaderCellsWidth] = useState<number[]>([]);
    const mainTableRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setHeaderData(data?.header || undefined);
        setFooterData(data?.footer || undefined);

    }, [data]);

    useEffect(() => {
        if (headerRef && footerRef) {
            const settings = Cookies.get(TABLE_COOKIE);

            if (!!settings) {
                setExpanded(JSON.parse(settings));
            } else {
                setExpanded({...expanded});
            }
            getHeaderHeight();
            getFooterHeight();
        }
    }, [headerRef, footerRef, footerData, headerData]);

    const getCellStyle = (column: SpecItemDocInvoiceColumn | SpecItemDocModelSectionColumn, customHeaderIndex?: number): CSSProperties => {
        let align: Property.TextAlign = 'left';
        if (column.format === 'money' || column.format === 'number') {
            align = 'right';
        }
        if (customHeaderIndex !== undefined && stickyHeaderCellsWidth[customHeaderIndex]) {
            return {
                textAlign: align,
                width: stickyHeaderCellsWidth[customHeaderIndex] + 'px'
            };
        }
        return {
            textAlign: align
        };
    };

    const renderValue = (line: SpecItemDocInvoiceLine | SpecItemDocModelSectionLine,
                         column: SpecItemDocInvoiceColumn | SpecItemDocModelSectionColumn): ReactElement => {

        if (!line.data[column.columnId]) {
            return <></>;
        }
        const value = line.data[column.columnId].toString();
        if (column.format === 'money') {
            const parts = value.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            return <><span style={{whiteSpace: 'nowrap'}}>{parts.join(',')}</span></>;
        }
        if (column.format === 'number') {
            return <><span style={{whiteSpace: 'nowrap'}}>{value.replace('.', ',')}</span></>;
        }
        return <><span dangerouslySetInnerHTML={{__html: value.replaceAll('-', '&#8209;')}}></span></>;

    };

    const handleExpand = (type: 'header' | 'footer'): void => {
        if (type === 'header') {
            setExpanded((prevState) => {
                const next = {
                    ...prevState,
                    header: !prevState.header
                };
                Cookies.set(TABLE_COOKIE, JSON.stringify(next));
                return next;
            });
        } else if (type === 'footer') {
            setExpanded((prevState) => {
                const next = {
                    ...prevState,
                    footer: !prevState.footer
                };
                Cookies.set(TABLE_COOKIE, JSON.stringify(next));
                return next;
            });
        }

    };

    const getIconStyle = (type: 'header' | 'footer'): CSSProperties => {
        return expanded[type] ? {transform: 'rotate(90deg)'} : {transform: 'rotate(0)'};
    };

    const getHeaderHeight = (): number => {
        return headerRef?.offsetHeight ? headerRef?.offsetHeight + 1 : 0;
    };
    const getFooterHeight = (): number => {
        return footerRef?.offsetHeight ? footerRef?.offsetHeight + 1 : 0;
    };

    const header = (): ReactElement => {
        if (!Array.isArray(headerData?.lines) || !headerData?.lines) {
            return <></>;
        }

        return (
            <div className={`collapsable ${!expanded.header ? 'collapsed' : ''}`}>
                <div className={'title'} onClick={() => handleExpand('header')}>
                    <ChevronRight style={getIconStyle('header')}/> <span>Доп. информация</span>
                </div>
                <div className={'content'} style={{height: expanded.header ? getHeaderHeight() : 0}}>
                    <div className="table-wrapper">
                        <table className="table-header" ref={(current) => setHeaderRef(current)}>
                            <tbody>
                            {headerData.lines.map((line, i) => (
                                <tr key={`tr_${i}`}>
                                    {
                                        headerData?.columns.map((column, ii) => (
                                            <td key={`td_${i}_${ii}`}>{line.data[column.columnId]}</td>
                                        ))
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
            ;
    };

    const footer = (): ReactElement => {
        if (!Array.isArray(footerData?.lines) || !footerData?.lines) {
            return <></>;
        }
        return (

            <div className={`collapsable ${!expanded.footer ? 'collapsed' : ''}`}>
                <div className={'title'} onClick={() => handleExpand('footer')}>
                    <ChevronRight style={getIconStyle('footer')}/> <span>Доп. информация</span>
                </div>
                <div className={'content'} style={{height: expanded.footer ? getFooterHeight() : 0}}>
                    <div className="table-wrapper">
                        <table className="table-footer" ref={(current) => setFooterRef(current)}>
                            <tbody>
                            {footerData.lines.map((line, i) => (
                                <tr key={`tr_${i}`}>
                                    {
                                        footerData?.columns.map((column, ii) => (
                                            <td key={`td_${i}_${ii}`}>{line.data[column.columnId]}</td>
                                        ))
                                    }
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        );
    };

    const handleScroll = (e: any) => {
        try {
            if (!!stickyHeaderRef?.parentElement) {
                stickyHeaderRef.parentElement.scrollLeft = e.target.scrollLeft;
            }
        } catch (e) {
            //do nothing
        }
    };

    const handleWindowScroll = (e: any) => {
        if (mainTableRef?.current) {
            setMainTableHeaderHidden(mainTableRef.current.getBoundingClientRect()?.top < 60);
        }
    };

    const handleWindowResize = () => {
        if (!mainTableRef?.current) {
            return;
        }

        setStickyHeaderWidth(mainTableRef.current.clientWidth + 'px');
        const nodes = mainTableRef.current.getElementsByTagName('thead')[0].childNodes[0].childNodes;
        if (nodes) {
            const cellsWidth = [];
            for (const key in nodes) {
                if (nodes[key].nodeType) {
                    cellsWidth.push((nodes[key] as HTMLElement).getBoundingClientRect().width);
                }
            }
            setStickyHeaderCellsWidth(cellsWidth);
        }

    };

    useEffect(() => {
        if (!mainTableRef?.current) {
            return;
        }

        mainTableRef.current.addEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleWindowScroll);
        handleWindowResize();
        return () => {
            if (mainTableRef?.current) {
                mainTableRef.current.removeEventListener('scroll', handleScroll);

            }
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, [reportType]);

    useEffect(() => {
        setTimeout(handleWindowResize, 500);
    }, [reportType])

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const body = (): ReactElement => {

        if (!Array.isArray(data?.lines) || !data?.lines) {
            return <></>;
        }

        return (
            <div style={{overflow: 'auto', margin: '20px 0'}} ref={mainTableRef}>
                <div className="stickyTableHeader" style={{
                    width: stickyHeaderWidth,
                    visibility: mainTableHeaderHidden ? 'visible' : 'hidden',
                    height: mainTableHeaderHidden ? 'auto' : 0
                }}>
                    <div
                        ref={(current) => setStickyHeaderRef(current)}
                        className={'stickyTableHeader_content'}>
                        {data.columns.map((column, index) => {
                            return <div key={`th1_${index}`}
                                        style={getCellStyle(column, index)}

                            >{column.title}</div>;
                        })}
                    </div>
                </div>
                <table className={['main', data?.columns.length > 6 ? 'fixed' : ''].join(' ')}>
                    <thead style={{visibility: mainTableHeaderHidden ? 'hidden' : 'visible',}}>
                    <tr>
                        {data.columns.map((column, index) => {
                            return <th key={`th_${index}`} style={getCellStyle(column)}>{column.title}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {data.lines.map((line, i) => (
                        <tr key={`tr_${i}`}>
                            {
                                data.columns.map((column, ii) => (
                                    <td key={`td_${i}_${ii}`}
                                        style={getCellStyle(column)}>{renderValue(line, column)}</td>
                                ))
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };


    return (
        <div className={'app-table'}>
            {header()}
            {body()}
            {footer()}
        </div>
    );
};

export {AppTableView};
