import {SortDescriptor} from '@progress/kendo-data-query';
import {ColumnInterface, TypeColumnMenu} from '../../components';
import {Role, SearchParams, SpecTemplate, SpecTemplateAction} from '../../interface';
import {ApiModelType} from '../../interface';
import React, {ReactElement, useEffect, useState} from 'react';
import {setSortParam} from '../../helpers';
import {
  Grid,
  GridCell,
  GridCellProps,
  GridColumn,
  GridColumnReorderEvent,
  GridSortChangeEvent
} from '@progress/kendo-react-grid';
import {format, parseISO} from 'date-fns';
import {DATE_TIME} from '../../constants';
import ObjectHelper from '../../helpers/object.helper';
import {NODE_FILTER} from '../component/node-item/node-item.interface';
import {TextFilter} from '../component/components/grid-header-filters/text-filter';
import {DateFilter} from '../component/components/grid-header-filters/date-filter';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {NodeFilter} from '../component/components/grid-header-filters/node-filter';
import {AppSuspense} from '../../components/app-suspense/app-suspense';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {LoaderOverlay} from '../../components/loader-overlay/loader-overlay';
import {GridNoRecords as NoRecords} from '@progress/kendo-react-grid/dist/npm/GridNoRecords';
import {AppPagination} from '../../components/app-pagination/app-pagination';
import {DropDownButtonItem} from '@progress/kendo-react-buttons';
import {useAppSelector} from '../../hook/store';
import {UserState} from '../../store/slice/user-slice';
import {RoleState} from '../../store/slice/role-slice';
import {NodeTypeActions} from './node-type-actions';
import {ROUTE_PATH} from '../../constants/routes';
import {useNavigate} from 'react-router-dom';


interface NodeTypesGridProps {
  searchParams: URLSearchParams;
  sort: SortDescriptor[];
  columns: ColumnInterface[];
  data: ApiModelType[] | null;
  total: number;
  onChanges: (params: any) => void;
  onColumnsChange: (columns: ColumnInterface[]) => void;
  resetFilter: (field: string) => void;
}

const exceptionalFields: any[] = [];

const NodeTypesGrid = ({
                         searchParams,
                         sort,
                         columns,
                         data,
                         total,
                         onChanges,
                         onColumnsChange,
                         resetFilter
                       }: NodeTypesGridProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {currentUser} = useAppSelector<UserState>(store => store.user);
  const {roleList} = useAppSelector<RoleState>(store => store.role);
  const [role, setRole] = useState<Role>();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && Array.isArray(roleList)) {
      const role = roleList.find((r) => r.id === currentUser.role);
      const userRole = role ? role.name : Role.ROLE_SALESMAN;
      setRole(userRole);
    }

  }, [currentUser, roleList]);

  useEffect(() => {
    setIsLoading(false);
  }, [data]);


  const onSort = (data: SortDescriptor[]): void => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    onChanges(setSortParam(data, searchParams as unknown as SearchParams));
  };

  const dateCell = (props: GridCellProps): ReactElement => {
    const dataItem: SpecTemplate = props.dataItem;
    return (
      <td onClick={(e) => handleRowClick(props, e)}>
        <div className="grid-cell">
          <p>{format(parseISO(dataItem.created), DATE_TIME)}</p>
        </div>
      </td>
    );
  };

  const handleReorderColumns = (event: GridColumnReorderEvent) => {
    const newOrder = [...event.columns];
    onChanges({
      ...Object.fromEntries(searchParams.entries()),
      'columns': newOrder.sort((a, b) => a.orderIndex! - b.orderIndex!).map(col => col.field).join(';'),
    });
  };


  const pageChange = (params: SearchParams): void => {
    onChanges(
      {
        ...Object.fromEntries(searchParams.entries()),
        offset: params.offset || '0',
        limit: params.limit || '50'
      });
  };


  const updateFilter = (field: string, filter: string) => {
    if (!!filter) {
      if (field === 'typeId') {
        const params = ObjectHelper.pickParams(Object.fromEntries(searchParams.entries()), ['limit']) as URLSearchParams;
        onChanges(
          {
            ...Object.fromEntries(searchParams.entries()),
            ...params,
            offset: '0',
            [field]: filter
          });
      } else if (field === NODE_FILTER.expertMode) {
        onChanges({
          ...Object.fromEntries(searchParams.entries()),
          offset: '0',
          [field]: filter.toLowerCase() === 'нет' ? false : true,
        });
      } else {
        onChanges({
          ...Object.fromEntries(searchParams.entries()),
          offset: '0',
          [field]: filter,
        });
      }
    }
  };


  const getCell = (props: GridCellProps): ReactElement<HTMLTableCellElement> => {
    if (props.field === 'created') {
      return dateCell(props);
    }

    return <GridCell {...props}/>;
  };

  const textSearchCell = (props: any): ReactElement<any> => {
    return <div>
      <TextFilter
        {...props}
        onFilter={(filter) => updateFilter('name', filter)}
        selected={searchParams.get('name')}
        onReset={() => resetFilter('name')}
      />
    </div>;
  };

  const dateSearchCell = (props: any, field: NODE_FILTER): ReactElement<any> => {
    return <div>
      <DateFilter
        {...props}
        onFilter={(filter) => updateFilter(field, filter)}
        selected={searchParams.get(field)}
        onReset={() => resetFilter(field)}
      />
    </div>;
  };

  const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
    switch (column.type) {
      case 'name':
        return <>{textSearchCell(props)}</>;
      case 'created':
        return <>{dateSearchCell(props, NODE_FILTER.created)}</>;
      default:
        return <div>
          <NodeFilter {...props}
                      onFilter={(field, filter) => updateFilter(field, filter)}
                      column={column}
                      onReset={resetFilter}
                      ignoreSearchType={exceptionalFields.includes(column.searchField || '')}
          />
        </div>;
    }
    return null;
  };

  const isAdmin = (): boolean => {
    return role === Role.ROLE_ADMIN;
  };

  const gridActions = (props: GridCellProps): ReactElement => {
    return <td style={{textAlign: 'right'}}>
      {isAdmin() && (
        <NodeTypeActions modelType={props.dataItem}>
          <DropDownButtonItem text={SpecTemplateAction.delete}/>
        </NodeTypeActions>
      )}
    </td>;
  };

  const handleRowClick = (data: any, event?: React.MouseEvent<HTMLTableDataCellElement>): void => {
    let nativeEvent;
    if (event) {
      nativeEvent = event.nativeEvent;
    } else {
      nativeEvent = data.nativeEvent;
    }
    if (nativeEvent?.ctrlKey || nativeEvent?.metaKey) {
      window.open(`/${ROUTE_PATH.nodeTypes}/${data.dataItem.id}`, '_blank');
    } else {
      navigate(`/${ROUTE_PATH.nodeTypes}/${data.dataItem.id}`);
    }
    if (!!nativeEvent) {
      nativeEvent.stopPropagation();
    }
  };

  return (
    <AppSuspense condition={!!data}>
      <Card className="h100">
        <CardBody>
          {(isLoading) && (
            <LoaderOverlay/>
          )}
          <div className={'universal-grid'}>
            <Grid
              className={'templates-grid'}
              style={{width: '100%'}}
              sortable={true}
              sort={sort}
              data={data ?? []}
              onSortChange={(e: GridSortChangeEvent) => {
                onSort(e.sort);
              }}
              reorderable={true}
              resizable={true}
              rowHeight={32}
              onRowClick={handleRowClick}
              onColumnReorder={handleReorderColumns}

            >
              {columns.map((col, idx) => col.show && (
                <GridColumn
                  key={idx}
                  field={col.field}
                  title={col.title}
                  orderIndex={idx}
                  sortable={col.sortable}
                  cell={(props) => getCell(props)}
                  width={col.width || 'auto'}
                  minResizableWidth={100}
                  headerCell={(props) => getHeaderCell(props, col)}
                />
              ))}
              <GridColumn
                field=""
                title=""
                width={100}
                cell={gridActions}
                headerClassName={'actions-menu'}
                orderIndex={columns.length + 1}
                locked={true}
                reorderable={false}
                resizable={false}
                headerCell={() => (
                  <TypeColumnMenu
                    columns={columns}
                    searchParams={searchParams}
                    onFilter={(filter: string) => updateFilter('columns', filter)}
                    onColumnsChange={onColumnsChange}
                  />
                )}
              />
              <NoRecords>
                {!!data && !data?.length && ('Список пуст.')}
                {!data && ('Загрузка данных...')}
              </NoRecords>
            </Grid>
          </div>
          <div className="spec-pager-wrapper">
            <div className="page-info">

            </div>
            <div className="spec-pager">
              <AppPagination params={{
                offset: searchParams.get('offset') || undefined,
                limit: searchParams.get('limit') || undefined,
              }} total={total}
                             onPageChange={pageChange}
                             showButtons={true}
                             pageSizes={[10, 50, 100, 200]}
              />

            </div>
            <div className="download">

            </div>
          </div>
        </CardBody>
      </Card>
    </AppSuspense>

  );
};

export {NodeTypesGrid};
