import {AppBreadcrumbs} from '../../../components/app-breadcrumbs/app-breadcrumbs';
import {SectionHeader} from '../../../components/section-header/section-header';
import {DEFAULT_PAGE_SIZE, SECTION_HEADER} from '../../../constants';
import {CommonGrid} from '../../../components/common-grid/common-grid';
import {NewTypeDialog} from '../components/new-type-dialog/new-type-dialog';
import React, {ReactElement, useEffect, useState} from 'react';
import {useAppDispatch} from '../../../hook/store';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {adminTraitsBreadcrumbs, adminTraitSchema, traitsListColumns} from './node-traits.meta';
import axios from 'axios';
import {API_ADMIN_NODE, API_ADMIN_NODE_TRAITS} from '../admin.meta';
import {ROUTE_PATH} from '../../../constants/routes';
import {ColumnInterface} from '../../../components';
import {NODE_FILTER} from '../../component/node-item/node-item.interface';
import {AdminTrait} from '../../../interface/admin/admin-traits.interface';
import {SortDescriptor} from '@progress/kendo-data-query';
import {GridRowClickEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import ObjectHelper from '../../../helpers/object.helper';
import {GridCellProps} from '@progress/kendo-react-grid';
import {showSuccess} from '../../../store/slice/toast-slice';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {notEmpty} from '../../../utils/array.util';
import {TextFilter} from '../../component/components/grid-header-filters/text-filter';
import {TraitsItemActions} from './actions/traits-item-actions';
import {AppPrompt} from "../../../components/app-prompt/app-prompt";
import {AdminNode} from "../../../interface/admin/admin-node.interface";

const NodeTraitsList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<AdminTrait[] | null>(null);
    const [total, setTotal] = React.useState<number>(0);
    const [showNewDialog, setShowNewDialog] = useState<boolean>(false);
    const [columns, setColumns] = useState<ColumnInterface[]>([]);
    const [currentTypes, setCurrentTypes] = useState<string[]>([]);
    const [sort, setSort] = useState<SortDescriptor[]>([{field: 'type', dir: 'asc'}]);
    const [showExistingType, setShowExistingType] = useState<boolean>(false);
    const [typeToSave, setTypeToSave] = useState<{ name: string }>({name: ''});
    const [inProgress, setInProgress] = useState<boolean>(false);

    useEffect(() => {
        checkLimit();
        updateColumns();
        checkSort();
    }, [searchParams]);

    useEffect(() => {
        const columnsParam = searchParams.get('columns');
        if (columnsParam && columns.length > 0) {
            const columnsParamsArr = columnsParam.split(';');
            const newColumns = columns.filter(column => !column.columnType || (column.columnType && currentTypes.some(col => column.columnType?.includes(col))))
                .map(column => column.field && columnsParamsArr.includes(column.field) ? {
                    ...column,
                    headerCell: (props: GridHeaderCellProps) => getHeaderCell(props, column),
                    show: true,
                } : {
                    ...column,
                    headerCell: (props: GridHeaderCellProps) => getHeaderCell(props, column),
                    show: false,
                });
            setColumns(newColumns);
        }
    }, [searchParams, columns.length]);


    useEffect(() => {
        if (notEmpty(columns)) {
            const columnsParam = searchParams.get('columns');
            if (!columnsParam) {
                const typesParam = searchParams.get(NODE_FILTER.types);
                const selectedColumns = columns.filter(
                    column => (column.columnType && column.columnType?.some(type => typesParam === type) && column.show)
                        || (!column.columnType && column.show)
                ).map(column => column.field).join(';');
                setSearchParams({
                    ...Object.fromEntries(searchParams.entries()),
                    'columns': selectedColumns,
                }, {replace: true});
            }
        }
    }, [columns]);

    const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
        if (!column.searchField) {
            return null;
        }
        return <>{textSearchCell(props)}</>;
    };

    const textSearchCell = (props: any): ReactElement<any> => {
        return <div>
            <TextFilter
                {...props}
                onFilter={(filter) => updateFilter(NODE_FILTER.searchQuery, filter)}
                selected={searchParams.get(NODE_FILTER.searchQuery)}
                onReset={() => resetFilter(NODE_FILTER.searchQuery)}
            />
        </div>;
    };

    const updateFilter = (field: string, filter: string) => {
        if (!!filter) {
            setSearchParams(
                {
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    [field]: filter
                });
        }
    };

    const resetFilter = (field: string) => {
        searchParams.delete(field);
        setSearchParams(searchParams);
    };


    const updateColumns = () => {
        const columnSet = getColumnsSet();
        const columnsParam = searchParams.get('columns');
        let fullColumns = [...columnSet];
        if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            fullColumns = fullColumns.sort((a, b) => {
                const indexA = columnsParamsArr.indexOf(a.field!);
                const indexB = columnsParamsArr.indexOf(b.field!);

                return indexA - indexB;
            });
        }
        setColumns(fullColumns);
    };


    const getColumnsSet = (): ColumnInterface[] => {
        return !columns.length ? traitsListColumns : traitsListColumns.map((col) => (
            {
                ...col,
                show: !!columns.find((c) => c.title === col.title && c.type === col.type && c.show),
            }
        ));
    };


    const checkLimit = () => {
        if (!searchParams.get('limit')) {
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                limit: DEFAULT_PAGE_SIZE.toString(),
                showDraft: 'true'
            }, {replace: true});
        } else {
            const typesParam = searchParams.get(NODE_FILTER.types);
            !!typesParam ? setCurrentTypes(typesParam.split(';')) : setCurrentTypes([]);
            getPageData();
        }
    };

    const checkSort = () => {
        const sortBy = searchParams.get('sortBy');
        const sortDir = searchParams.get('sortDirection');
        if (!!sortBy && !!sortDir) {
            setSort([
                {
                    field: sortBy,
                    dir: sortDir as any
                }
            ]);
        } else {
            setSort([]);
        }
    };

    const getPageData = () => {
        axios.get(API_ADMIN_NODE_TRAITS, {params: searchParams})
            .then((response) => {
                setData(response?.data?.result || []);
                setTotal(response?.data?.total || 0);
            });

    };


    const handleSearch = (term: string) => {
        if (term.trim().length) {
            setSearchParams(
                {
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    searchQuery: term.trim()
                });
        } else {
            searchParams.delete('searchQuery');
            setSearchParams(searchParams);
        }
    };


    const handleCreate = (data: { name: string, version?: number }) => {
        const code = data.name.toUpperCase();
        axios.post(API_ADMIN_NODE_TRAITS, {
            code,
            data: adminTraitSchema.data
        }).then((res) => {
            navigate(`/${ROUTE_PATH.adminNodeTraits}/${res.data.id}`);
        }).finally(() => {
            setShowNewDialog(false);
        });
    };

    const handleRowClick = (data: GridRowClickEvent): void => {
        if (data?.nativeEvent?.ctrlKey || data?.nativeEvent?.metaKey) {
            window.open(`/${ROUTE_PATH.adminNodeTraits}/${data.dataItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.adminNodeTraits}/${data.dataItem.id}`);
        }
        data?.nativeEvent?.stopPropagation();
    };

    const handleSort = (data: SortDescriptor[]): void => {
        if (!!data[0] && !!data[0].dir) {
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0',
                sortBy: data[0].field,
                sortDirection: data[0].dir
            });
        } else {
            searchParams.delete('sortBy');
            searchParams.delete('sortDirection');
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                offset: '0'
            });
        }
    };

    const handleUpdateFilter = (field: string, filter: string) => {
        if (!!filter) {
            if (field === NODE_FILTER.types) {
                const params = ObjectHelper.pickParams(Object.fromEntries(searchParams.entries()), ['limit']);
                setSearchParams(
                    {
                        ...Object.fromEntries(searchParams.entries()),
                        ...params,
                        offset: '0',
                        [field]: filter
                    });
            } else {
                setSearchParams(
                    {
                        ...Object.fromEntries(searchParams.entries()),
                        offset: '0',
                        [field]: filter
                    });
            }
        }
    };

    const handleReload = () => {
        getPageData();
        dispatch(showSuccess('Характеристика успешно переименована'));
    };

    const gridActions = (props: GridCellProps): ReactElement => {
        return <td className={props.className} style={{...props.style, textAlign: 'right'}}>
            <TraitsItemActions traitsItem={props.dataItem}
                             onReload={handleReload}/>
        </td>;
    };


    const checkExistingType = (data: { name: string }) => {
        setInProgress(true)
        const code = data.name.toUpperCase();
        const searchQuery = `${code}.`;
        axios.get(API_ADMIN_NODE_TRAITS, {
            params: {
                searchQuery
            }
        }).then((res) => {
            const items = res?.data?.result || []
            if (items.some((item: AdminTrait) => item.id.startsWith(searchQuery))) {
                setTypeToSave(data);
                setShowExistingType(true)
            } else {
                handleCreate(data);
            }
        });
    };

    const handleConfirmExistingType = () => {
        setShowExistingType(false);
        handleCreate(typeToSave);
    };

    const handleCloseExistingType = () => {
        setShowExistingType(false);
        setInProgress(false)
    };


    return (
        <div className={'node-type'}>
            <div className="node-type__breadcrumbs">
                <AppBreadcrumbs items={adminTraitsBreadcrumbs}/>
            </div>
            <div className="node-type__header">
                <SectionHeader onSearch={handleSearch}
                               onCreate={() => setShowNewDialog(true)}
                               type={SECTION_HEADER.nodeType}
                               style={'inline'}
                />
            </div>
            <div className="node-type__grid">
                <CommonGrid
                    columns={columns}
                    data={data}
                    total={total}
                    onRowClick={handleRowClick}
                    sort={sort}
                    onSort={handleSort}
                    onUpdateFilter={handleUpdateFilter}
                    gridActions={gridActions}
                    exportLink={'/api/admin/nodeTraits/export/excel'}
                />
            </div>
            {showNewDialog && (
                <NewTypeDialog
                    onClose={() => setShowNewDialog(false)}
                    onSubmit={checkExistingType}
                    title={'Новая характеристика'}
                    namePlaceholder={'Название типа характеристики'}
                />
            )}
            {showExistingType && (
                <AppPrompt data={{
                    title: 'Тип характеристики',
                    message: [`Тип характеристики '${typeToSave.name.toUpperCase()}' уже существует`, 'Сохранить как новую версию типа характеристики?'],
                    confirmButton: 'Сохранить',
                    buttonError: false
                }}
                           onClose={handleCloseExistingType}
                           onConfirm={handleConfirmExistingType}
                />
            )}
        </div>
    );
};

export {NodeTraitsList};
