import {Card, CardBody} from '@progress/kendo-react-layout';
import {Button, Dialog, FormControl, TextField} from '@mui/material';
import {JsonForms} from '@jsonforms/react';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {getNodeItem, NodeState, recalculateNodes, updateCurrentNodeItem, updateNodeItem} from '../../../../store/slice/node-slice';
import {useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {ApiNodeItem, ApiNodeItemOptions, ApiUpdateNodeRequest, NodeItemStatus, TestingParamsProps,} from '../../../../interface';
import {JsonSchema, UISchemaElement} from '@jsonforms/core';
import AsyncSelectControl, {
    asyncSelectTester
} from '../../../../ui/json-form-renderers/async-select-renderer/async-select-control';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';
import {clearAll, showSuccess} from '../../../../store/slice/toast-slice';
import {useNavigate} from 'react-router-dom';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {ComponentItemLinks} from '../component-item-links/component-item-links';
import {ComponentItemConfirm} from '../component-item-confirm/component-item-confirm';
import {SplitLoaderButton} from '../../../../ui/button/split-loader-button/split-loader-button';
import {NodeTypeState} from '../../../../store/slice/node-type-slice';
import {ROUTE_PATH} from '../../../../constants/routes';
import {ItemLinkSetType} from '../component-item-links/component-item-links.interface';
import AddIcon from '@mui/icons-material/Add';
import {
    ComponentItemOptionsDialog
} from '../component-item-options/component-item-options-dialog/component-item-options-dialog';
import { ComponentItemTestingDialog, TestParamsType } from '../component-item-testing/component-item-testing-dialog';
import { ComponentItemTestingResults } from '../component-item-testing/component-item-testing-results';
import CircularProgress from '@mui/material/CircularProgress';
import { getDictionary } from '../../../../store/slice/dictionary-slice';
import {translation} from "../../../../helpers";
import {ComponentItemConstraint} from "../component-item-constraint/component-item-constraint";
import TextControl, {textControlTester} from "../../../../ui/json-form-renderers/input-control-renderers/TextControl";
import IntegerControl, {
    integerControlTester
} from "../../../../ui/json-form-renderers/input-control-renderers/IntegerControl";

interface ActualParamsProps {
  [key:string]: any;
}

const ComponentItemsForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [formData, setFormData] = useState<any>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [dryRunResult, setDryRunResult] = useState<any>(false);
    const [dryRunMessage, setDryRunMessage] = useState<boolean>(false);
    const [saveRedirect, setSaveRedirect] = useState<boolean>(false);
    const [jsonSchema, setJsonSchema] = useState<JsonSchema>();
    const [uiSchema, setUiSchema] = useState<UISchemaElement | undefined>(undefined);
    const [linkedAvailable, setLinkedAvailable] = useState(false);
    const [testingDialog, setTestingDialog] = useState<boolean>(false);
    const [testInProgress, setTestInProgress] = useState<boolean>(false);
    const [testParams, setTestParams] = useState<TestingParamsProps[]>([{id: Date.now(), parameter: '', type: '', value: '', errors: []}]);
    const [showTestResults, setShowTestResults] = useState<boolean>(false);
    const [hasTestingError, setHasTestingError] = useState<boolean>(false);
    const [testingErrors, setTestingErrors] = useState<string[]>([]);

    const [option, setOption] = useState<ApiNodeItemOptions | null>(null);
    const [itemOptionsDialog, setItemOptionsDialog] = useState(false);

    const renderers = [...materialRenderers,
        {tester: asyncSelectTester, renderer: AsyncSelectControl},
        {tester: formulaInputTester, renderer: FormulaInputRenderer},
        {tester: textControlTester, renderer: TextControl},
        {tester: integerControlTester, renderer: IntegerControl},
    ];

    const {
        register,
        getValues,
        setValue,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
    });

    const handleChangeTestParams = (nextState: any) => {
      setTestParams(nextState);
    };


    const updateOptions = (data: ApiNodeItem) => {
        dispatch(updateCurrentNodeItem(data));
        setItemOptionsDialog(false);
        // setShowOptionConfirm(false);
        // setUpdateData(null);
    };

    const handleOptionEdit = (id: string) => {
        if(currentNodeItem){
            setOption(currentNodeItem.options[id]);
            setItemOptionsDialog(true);
        }
    };

    const handleOptionsDialogClose = () => {
        setOption(null);
        setItemOptionsDialog(false);
    };

    const handleAddOption = () => {
        setOption(null);
        setItemOptionsDialog(true);
    };

    const handleFormChange = (data: any) => {
        setFormData(data);
    };

    const isReadOnly = (): boolean => {
        return currentNodeItem?.status !== NodeItemStatus.draft;
    };

    const handleEditParams = (val: boolean) => {
      setTestingDialog(val);
    };

    const handleSave = (dryRun: boolean) => {
        if (!!formData?.errors?.length || !currentNodeItem) {
            return;
        }
        if (!isValid) {
            return;
        }
        const nameValue = getValues().componentName;
        const data: ApiUpdateNodeRequest = {
            id: currentNodeItem.id,
            formData: {
                dryRun,
                ...currentNodeItem,
                data: {
                    ...formData.data,
                    name: nameValue
                },
                name: nameValue
            }
        };

        setInProgress(true);

        dispatch(updateNodeItem(data)).unwrap()
            .then((res) => {
                if (!dryRun) {
                    const text = 'Данные успешно обновлены.';
                    dispatch(showSuccess(text));
                    // reload node data
                    dispatch(getNodeItem(currentNodeItem.id.toString()));
                    if (saveRedirect) {
                        navigate(`/${ROUTE_PATH.newComponents}/type?types=${currentNodeItem.type}`);
                    }
                } else {
                    setDryRunResult(res);
                    setDryRunMessage(true);
                }
            })
            .finally(() => {
                setInProgress(false);
                if (!dryRun) {
                    setDryRunMessage(false);
                }
            });
    };

    const handleTestParams = async (dryRun: boolean) => {
        if (!currentNodeItem) {
            return;
        }

        const actualParams: ActualParamsProps = {};

        testParams.forEach(testParam => {
          if(testParam.type === TestParamsType.boolean) {
            return (actualParams[testParam.parameter] = {
              value: testParam.value === 'Да' ? true : false,
            });
          } else if(testParam.type !== TestParamsType.policy) {
            return (actualParams[testParam.parameter] = {
              value: `${testParam.value}`,
            });
          }
        });

        await Promise.all(testParams.filter(testParam => testParam.type === TestParamsType.policy).map(testParam => {
            if(typeof testParam.value === 'object'){
              return dispatch(getDictionary({ type: testParam.value.id })).unwrap().then((res: any) => {
                const policyProps = res.result.map((item: any) => (
                  {
                    predicate: item.data.predicate || '',
                    weight: item.data.weight || 0,
                    value: item.data.value || '',
                  }
                ));

                if(typeof testParam.value === 'object'){
                  return (
                    actualParams[testParam.parameter] = {
                      policy: policyProps,
                  }
                );
                }
            });
            }
            return;
          }));

        const nameValue = getValues().componentName;
        const data: ApiUpdateNodeRequest = {
            id: currentNodeItem.id,
            formData: {
                dryRun,
                actualParams,
                ...currentNodeItem,
                data: {
                    ...formData.data,
                    name: nameValue
                },
                name: nameValue
            }
        };

        setTestInProgress(true);
        dispatch(recalculateNodes(data)).unwrap()
          .then((res) => {
              setTestInProgress(false);
              setShowTestResults(true);
              setHasTestingError(false);
              setTestingErrors([]);
          }).catch((error) => {
            if(error?.response?.data?.Error) {
              setTestingErrors([error?.response?.data?.Error]);
            } else {
              setTestingErrors([error.message]);
            }
            setHasTestingError(true);
            dispatch(clearAll());
          }).finally(() => {
            setTestInProgress(false);
            setShowTestResults(true);
          });
    };

    const checkValidation = () => {
      const newTestParams = [...testParams];

      newTestParams.map((newTestParam: any, index) => {
          newTestParam.errors = [];
          const hasValue = Object.keys(newTestParam).filter(key => key !== 'id' && key !== 'errors').some(key => newTestParam[key] !== '');
          if(!hasValue) return;

          if (!newTestParam.parameter.trim() && !newTestParam.errors.includes('parameter')) {
            newTestParam.errors = [...newTestParams[index].errors, 'parameter'];
          }
          if (!newTestParam.type.trim() && !newTestParam.errors.includes('type')) {
            newTestParam.errors = [...newTestParams[index].errors, 'type'];
          }
          if (!newTestParam.errors.includes('value') && (typeof newTestParam.value === 'object' && !newTestParam.value.name.trim() || typeof newTestParam.value !== 'object' && !newTestParam.value.trim())) {
            newTestParam.errors = [...newTestParams[index].errors, 'value'];
          }

          return newTestParam;

        });
        setTestParams([...newTestParams]);
    };

    const toggleTesting = () => {
        checkValidation();
        const isValidTest = testParams.every(testParam => testParam.errors.length === 0);

        if(isValidTest) {
          setTestInProgress(true);
          if (currentNodeItem) {
            setTestingDialog(false);
            handleTestParams(true);
          }
        }
    };

    const cancelUpdate = () => {
        setDryRunMessage(false);
        if (saveRedirect) {
            navigate(`/${ROUTE_PATH.newComponents}/type?types=${currentNodeItem?.type}`);
        }
    };

    const handleButtonItemClick = () => {
        setSaveRedirect(true);
        handleSave(true);
    };

    const closeTestingDialog = () => {
      setTestingDialog(false);
    };

    useEffect(() => {
        if (Array.isArray(allNodeTypes) && !!currentNodeItem) {
            const nodeType = allNodeTypes.find(n => n.id === currentNodeItem.type);
            setJsonSchema(nodeType?.typeSchema || {});
            setUiSchema(nodeType?.typeUISchema || undefined);
            const linkedAvailable = Array.isArray(nodeType?.allowChildren) && !!nodeType?.allowChildren.length;
            setLinkedAvailable(linkedAvailable);
            setValue('componentName', currentNodeItem.name);
        }
    }, [allNodeTypes, currentNodeItem]);

    return (
        <AppSuspense condition={!!jsonSchema}>
            <Card style={{marginBottom: '32px'}}>
                <CardBody>
                    <div className="component-item__props title">Свойства</div>
                    <div className="component-item__form">

                        <form style={{width: '100%', marginBottom: '1.5em'}}>
                            <FormControl error={!!errors?.componentName} sx={{width: '100%'}}>
                                <TextField
                                    disabled={isReadOnly()}
                                    variant={'standard'}
                                    label={'Название'}
                                    required
                                    defaultValue={currentNodeItem?.name || ''}
                                    error={!!errors?.componentName}
                                    helperText={errors?.componentName ? errors?.componentName.message as string : ''}
                                    {...register('componentName', {
                                        required: 'Обязательное поле',
                                        ...currentNodeItem?.name && {'value': currentNodeItem?.name}
                                    })}
                                />
                            </FormControl>
                        </form>

                        {jsonSchema && (
                            <JsonForms
                                readonly={isReadOnly()}
                                data={currentNodeItem?.data}
                                schema={jsonSchema}
                                uischema={uiSchema}
                                renderers={renderers}
                                cells={materialCells}
                                config={currentNodeItem?.calculatedData}
                                onChange={handleFormChange}
                                i18n={{locale: 'ru', translate: translation}}
                            />
                        )}
                    </div>

                    <ComponentItemConstraint/>

                    {linkedAvailable && (
                        <>
                            <div className="linked-components">
                                <ComponentItemLinks
                                    title={'Компоненты'}
                                    type={ItemLinkSetType.links}
                                    linksSet={{
                                        links: {...currentNodeItem?.links || {}},
                                        linkVariants: {...currentNodeItem?.linkVariants || {}}
                                } as ApiNodeItemOptions}/>
                            </div>
                            <div className="component-options">
                                {currentNodeItem?.options && Object.entries(currentNodeItem?.options).map(([k, v]) => (
                                    <ComponentItemLinks
                                        key={v.id}
                                        title={v.name || ''}
                                        type={ItemLinkSetType.option}
                                        linksSet={{...v}}
                                        onEdit={handleOptionEdit}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    <div className="component-options-header" style={{marginBottom: '3em'}}>
                        {!isReadOnly() && (
                            <Button
                                variant={'outlined'}
                                onClick={handleAddOption}
                            >
                                <AddIcon/> Добавить опцию
                            </Button>
                        )}
                    </div>

            {showTestResults && (
              <div>
                <ComponentItemTestingResults
                  testParams={testParams}
                  testingErrors={testingErrors}
                  hasTestingError={hasTestingError}
                  toggleTesting={toggleTesting}
                  handleEditParams={handleEditParams}
                />
              </div>
            )}

            {itemOptionsDialog && (
              <ComponentItemOptionsDialog
                handleClose={handleOptionsDialogClose}
                handleUpdate={updateOptions}
                itemData={option}
                readonly={isReadOnly()}
              />
            )}

            {!showTestResults && (
              <Button
                variant={'outlined'}
                onClick={() => setTestingDialog(true)}
                sx={{ marginRight: '20px' }}
              >
                Запустить проверку
              </Button>
            )}

            {testingDialog && (
              <ComponentItemTestingDialog
                toggleTesting={toggleTesting}
                testParams={testParams}
                handleClose={closeTestingDialog}
                handleChangeTestParams={handleChangeTestParams}
              />
            )}

            {testInProgress && (
              <Dialog open={true} maxWidth={'lg'}>
                <div
                  className="loadingBox"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '329px',
                    height: '108px',
                  }}
                >
                  <CircularProgress color="primary" />
                  <p>Проверка...</p>
                </div>
              </Dialog>
            )}

                    {!isReadOnly() && <SplitLoaderButton
                        items={['Сохранить и выйти']}
                        inProgress={inProgress}
                        disabled={!!formData?.errors?.length || inProgress}
                        onButtonClick={() => handleSave(true)}
                        onItemClick={handleButtonItemClick}
                    />}


                </CardBody>
            </Card>
            {dryRunMessage && <ComponentItemConfirm data={dryRunResult}
                                                    handleClose={cancelUpdate}
                                                    handleSave={() => handleSave(false)}
            />}
        </AppSuspense>
    );
};

export {ComponentItemsForm as default};
