import styles from '../../styles/type-edit.module.scss';
import {FormControlLabel, Switch} from '@mui/material';
import * as React from 'react';

interface TypeContentHeader {
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const TypeContentHeader = ({onChange, checked}: TypeContentHeader) => {

    return (
        <div className={styles.adminTypeItem__content_header}>
            <span>Атрибуты типа компонента</span>
            <FormControlLabel
                style={checked ? {color: '#00BEC8'} : {}}
                control={
                    <Switch
                        onChange={onChange}
                        checked={checked} />
                } label="JSON"/>
        </div>
    );
};

export {TypeContentHeader};
