import {AppTab} from "../../components/app-tabs/app-tabs.interface";
import {ThemeOptions} from "@mui/material";

export const API_ADMIN_NODE = '/api/admin/nodeType';
export const API_ADMIN_NODE_TRAITS = '/api/admin/nodeTraits';

export const typeItemTabs: AppTab[] = [
    {
        title: 'Редактирование',
        path: 'form',
        position: 'left',
        hideInactive: true
    },
    {
        title: 'Редактирование',
        path: 'json',
        position: 'left',
        hideInactive: true
    }
];


export const typeAttributesTheme: ThemeOptions = {
  components: {
      MuiAccordion: {
          styleOverrides: {
              root:{
                  boxShadow: 'none',
                  border: 'none',

                  '& .MuiAccordionSummary-gutters': {
                      padding: 0,
                      margin: 0,
                      '&.Mui-expanded': {

                      },
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                      margin: 0,
                  },
                  '& .MuiAccordionDetails-root': {
                      padding: '8px 0 16px'
                  },
                  '& .MuiAccordionSummary-expandIconWrapper': {
                      padding: 4,
                      background: '#FAFAFA',
                      borderRadius: 4
                  }
              },
          }
      },
      MuiFormLabel: {
          styleOverrides: {
              root: {
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#6C757D',
                  marginBottom: 12
              }
          }
      },
      MuiInputLabel: {
          styleOverrides: {
              root: {
                  background: '#ffffff',
                  paddingRight: 4
              }
          }
      }
  }
}
