import {configureStore} from '@reduxjs/toolkit';
import authSlice from './slice/auth-slice';
import specTypeSlice from './slice/spec-type-slice';
import specSlice from './slice/spec-slice';
import alertSlice from './slice/alert-slice';
import nodeTypeSlice from './slice/node-type-slice';
import dataViewSlice from './slice/data-view-slice';
import toastSlice from './slice/toast-slice';
import nodeSlice from './slice/node-slice';
import dictionarySlice from './slice/dictionary-slice';
import dictionaryTypeSlice from './slice/dictionary-type-slice';
import productSlice from './slice/product-slice';
import userSlice from './slice/user-slice';
import roleSlice from './slice/role-slice';
import utilsSlice from './slice/utils.slice';
import settingsSlice from './slice/settings-slice';
import modelTypeSlice from './slice/model-type-slice';
import templateSlice from './slice/template-slice';
import adminSlice from "./slice/admin-slice";

const store = configureStore({
    reducer: {
        admin: adminSlice,
        auth: authSlice,
        user: userSlice,
        role: roleSlice,
        specType: specTypeSlice,
        spec: specSlice,
        nodeType: nodeTypeSlice,
        node: nodeSlice,
        alert: alertSlice,
        dataViewType: dataViewSlice,
        toast: toastSlice,
        dictionary: dictionarySlice,
        dictionaryType: dictionaryTypeSlice,
        product: productSlice,
        utils: utilsSlice,
        settings: settingsSlice,
        modelType: modelTypeSlice,
        template: templateSlice
    }
});

export default store;

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;
