import range from 'lodash/range';
import React, {useState, useCallback} from 'react';
import {
    ArrayLayoutProps,
    composePaths,
    computeLabel,
    createDefaultValue,
} from '@jsonforms/core';
import map from 'lodash/map';
import {ArrayLayoutToolbar} from "./ArrayToolbar";
import merge from 'lodash/merge';
import ExpandPanelRenderer from "./ExpandPanelRenderer";

const ArrayLayoutComponent = (props: ArrayLayoutProps) => {
    const [expanded, setExpanded] = useState<string[]>([]);
    const innerCreateDefaultValue = useCallback(() => createDefaultValue(props.schema), [props.schema]);

    const {
        enabled,
        data,
        path,
        schema,
        uischema,
        errors,
        addItem,
        renderers,
        cells,
        label,
        required,
        rootSchema,
        config,
        uischemas
    } = props;
    const appliedUiSchemaOptions = merge(
        {},
        config,
        props.uischema.options
    );

    const handleChange = useCallback((panel: string) => (_event: any, expandedPanel: boolean) => {
        let next
        if (!expandedPanel) {
            next = expanded.filter((p) => p !== panel);
        } else {
            next = [...expanded, panel]
        }
        setExpanded(next);
    }, [expanded]);
    const isExpanded = (index: number) =>
        expanded.includes(composePaths(props.path, `${index}`));

    const handleCollapse = useCallback(() => {
        setExpanded([]);
    }, [data]);

    const handleExpand = useCallback(() => {
        const next = data > 0 ? (
            map(range(data), index => {
                    return composePaths(props.path, `${index}`)
                }
            )) : ([]);
        setExpanded(next)
    }, [data]);


    return (
        <div>
            <ArrayLayoutToolbar
                label={computeLabel(
                    label,
                    !!required,
                    appliedUiSchemaOptions.hideRequiredAsterisk
                )}
                errors={errors}
                path={path}
                addItem={addItem}
                collapseAll={handleCollapse}
                expandAll={handleExpand}
                createDefault={innerCreateDefaultValue}
                enabled={enabled}
            />
            <div>
                {data > 0 ? (
                    map(range(data), index => {
                        return (
                            <ExpandPanelRenderer
                                enabled={enabled}
                                index={index}
                                expanded={isExpanded(index)}
                                schema={schema}
                                path={path}
                                handleExpansion={handleChange}
                                uischema={uischema}
                                renderers={renderers}
                                cells={cells}
                                key={index}
                                rootSchema={rootSchema}
                                enableMoveUp={index != 0}
                                enableMoveDown={index < data - 1}
                                config={config}
                                childLabelProp={appliedUiSchemaOptions.elementLabelProp}
                                uischemas={uischemas}
                            />
                        );
                    })
                ) : (
                    <p>No data</p>
                )}
            </div>
        </div>
    );
};

export const ArrayLayout = React.memo(ArrayLayoutComponent);
