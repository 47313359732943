import {UISchemaElement} from '@jsonforms/core';
import {SpecItem} from './spec.interface';

export interface SpecificationItemTabForm {
    key: string;
    schema: SpecificationItemTabFormSchema;
    uiSchema?: UISchemaElement;
    data?: { [key: string]: any };
}

export interface SpecificationItemTabFormSchema {
    properties: { [key: string]: any }
    required?: string[];
    type: string;
}

export interface SpecificationItemTab {
    type: 'form' | 'invoice';
    id: string;
    title: string;
    forms?: SpecificationItemTabForm[],
    required?: boolean;
    error?: boolean;
    filled?: boolean;
}

export interface SpecificationItemInterface {
    tabs: SpecificationItemTab[];
}

export interface SpecificationItemTabFormData {
    [key: string]: { [key: string]: any };
}

export enum SpecificationItemAction {
    'open' = 'Открыть',
    'open_stage' = 'Открыть этап',
    'open_all_stages' = 'Открыть все этапы',
    'rename' = 'Переименовать',
    'publish' = 'Опубликовать',
    'clone' = 'Дублировать',
    'clone_copy' = 'Создать новую версию',
    'clone_new' = 'Создать копию',
    'delete' = 'Удалить',
    'archive' = 'Архивировать',
    'unarchive' = 'Вернуть из архива',
    'changeOwner' = 'Переназначить другому пользователю',
    'template' = 'Сохранить как шаблон'
}

export type SpecificationItemActionListItem = {
    action: SpecificationItemAction,
    active: boolean;
    disabled?: boolean;
    options: SpecificationItemAction[]
}

export interface SpecificationItemActionData {
    action?: SpecificationItemAction;
    item?: SpecItem
}

export interface SpecificationCalculationProgress{
    calculationStatus: 'NotStarted' | 'InProgress' | 'Calculated' | 'InTheProcessOfCancellation';
    calculationStartedAtUtc: number
    percentage: number;
    stages: SpecificationCalculationProgress[];
}

export interface SpecificationItemCalculationProgress{
    id: string;
    progress: SpecificationCalculationProgress;
}
