import styles from '../../settings.module.scss';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import React, {useEffect, useState} from 'react';
import {showError} from '../../../../store/slice/toast-slice';
import {useAppDispatch} from '../../../../hook/store';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {JsonForms} from '@jsonforms/react';
import {ApiAdminSettings} from '../../../../interface';
import {useNavigate} from 'react-router-dom';
import AsyncSelectControl, {
    asyncSelectTester
} from '../../../../ui/json-form-renderers/async-select-renderer/async-select-control';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';
import {AppBreadcrumb} from '../../../../interface/breadcrumbs.interface';
import {AppBreadcrumbs} from '../../../../components/app-breadcrumbs/app-breadcrumbs';
import {ROUTE_PATH} from '../../../../constants/routes';
import {translation} from "../../../../helpers";
import TextControl, {textControlTester} from "../../../../ui/json-form-renderers/input-control-renderers/TextControl";
import IntegerControl, {
    integerControlTester
} from "../../../../ui/json-form-renderers/input-control-renderers/IntegerControl";

const SettingsSystemCommon = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState<ApiAdminSettings>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({});

    const renderers = [...materialRenderers,
        {tester: asyncSelectTester, renderer: AsyncSelectControl},
        {tester: formulaInputTester, renderer: FormulaInputRenderer},
        {tester: textControlTester, renderer: TextControl},
        {tester: integerControlTester, renderer: IntegerControl},
    ];

    const breadcrumbs: AppBreadcrumb[] = [
        {
            title: 'Настройки',
            path: `/${ROUTE_PATH.settings}`
        },
        {
            title: 'Система',
            path: ''
        }
    ];

    const handleFormChange = (formData: any) => {
        setFormData(formData.data);
    };

    const updateSettings = async () => {
        const data = await fetch('/api/admin/settings', {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'content-type': 'application/json'
            }
        });
        return data;
    };

    const saveSettings = () => {
        setInProgress(true);
        updateSettings()
            .then(() => {
                navigate(`/${ROUTE_PATH.settings}`);
            })
            .catch((e) => {
                dispatch(showError('Ошибка обновления данных'));
            })
            .finally(() => {
                setInProgress(false);
            });
    };

    useEffect(() => {
        const getSettings = async () => {
            const data = await fetch('/api/admin/settings', {
                method: 'GET'
            });
            const jsonData = await data.json();
            return jsonData;
        };
        getSettings()
            .then((data: ApiAdminSettings) => {
                console.log(data);
                setData(data);
                setFormData(data?.settingsDocument || {});
            })
            .catch((e) => {
                dispatch(showError('Ошибка получения данных'));
            });
    }, [dispatch]);


    return (
        <>
            <div className={styles.settingsCard}>
                <AppBreadcrumbs items={breadcrumbs}/>
            </div>

            <Card className={styles.settingsCard}>
                <CardBody className={styles.settingsBody}>
                    <div className={styles.rowTitle}>Системные настройки</div>
                    <AppSuspense condition={!!data}>
                        <div className={styles.row}>
                            <JsonForms
                                data={formData}
                                schema={data?.settingsSchema ?? {}}
                                uischema={data?.uiSettingsSchema ?? undefined}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={handleFormChange}
                                i18n={{locale: 'ru', translate: translation}}
                            />
                        </div>
                        <div className={styles.row} style={{justifyContent: 'flex-start'}}>
                            <Button
                                variant={'outlined'}
                                className={styles.settingsBtn}
                                disabled={inProgress}
                                onClick={() => navigate(`/${ROUTE_PATH.settings}`)}
                            >Отмена</Button>
                            <LoadingButton
                                variant={'contained'}
                                className={styles.settingsBtn}
                                onClick={saveSettings}
                                disabled={inProgress}
                                loading={inProgress}
                            >Сохранить</LoadingButton>
                        </div>
                    </AppSuspense>
                </CardBody>
            </Card>

        </>

    );
};

export {SettingsSystemCommon};
