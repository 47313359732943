import {useAppDispatch} from '../../../../hook/store';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {SpecCloneRequest, SpecificationItemActionData} from '../../../../interface';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    TextField
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {cloneSpecificationToNew} from '../../../../store/slice/spec-slice';
import {ROUTE_PATH} from '../../../../constants/routes';
import {useNavigate} from 'react-router-dom';

interface CloneDialogProps {
    specification: SpecificationItemActionData;
    isItemPage?: boolean;
    onClose: () => void;
}

const CloneDialog = ({specification: {item}, isItemPage, onClose}: CloneDialogProps) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'all'
    });

    const onSubmit = async (formData: any) => {
        if (!item?.id) {
            return;
        }
        setInProgress(true);
        const data: SpecCloneRequest = {
            id: item.id,
            name: formData.name
        };
        await dispatch(cloneSpecificationToNew(data)).unwrap()
            .then((data: any) => {
                if (!data.error) {
                    navigate(`/${ROUTE_PATH.specifications}/${data.clonedId}`);
                }
            })
            .catch((error) => {
                setInProgress(false);
                onClose();
            });
    };


    return (
        <Dialog
            className="rename-dialog"
            open={true}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={onClose}
        >
            <DialogTitle className="dialog-title">Дублирование спецификации</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <FormGroup className={errors?.message ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl>
                            <TextField {...register('name', {
                                value: `${item?.name} (новая)` || '',
                                required: 'Обязательное поле',
                                maxLength: {value: 100, message: 'Название должно содержать максимум 100 символов'}
                            })}
                                       multiline
                                       rows={3}
                                       variant="outlined"
                                       error={!!errors?.name}
                                       helperText={errors?.name ? errors?.name.message as string : ''}

                            />
                        </FormControl>
                    </FormGroup>

                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        variant={'outlined'}
                        onClick={onClose}
                        disabled={inProgress}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={inProgress}
                        type={'submit'}
                        variant={'contained'}
                        disabled={!isValid || inProgress}
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>
    );
};

export {CloneDialog};
