import {
    Role,
    SpecificationItemAction,
    SpecificationItemActionListItem,
    SpecItem,
    SpecItemStatus
} from '../../../../interface';


export const getActionItems = (itemPage: boolean, itemData: SpecItem, userRole: Role, isValid: boolean): SpecificationItemActionListItem[] => {
    const stageItem = !!itemData.masterSpecificationId;
    return [
        {
            action: SpecificationItemAction.open,
            active: !itemPage && !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.open_stage,
            active: !itemPage && stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.open_all_stages,
            active: stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.archive,
            active: itemData.status === SpecItemStatus.published && !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.publish,
            active: itemData.status === SpecItemStatus.draft && !itemPage && !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.unarchive,
            active: itemData.status === SpecItemStatus.archive  && !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.rename,
            active:  !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.clone,
            active: !stageItem,
            options: [
                SpecificationItemAction.clone_new,
                SpecificationItemAction.clone_copy
            ]
        },
        {
            action: SpecificationItemAction.clone,
            active: stageItem,
            options: [
                SpecificationItemAction.clone_new,
            ]
        },
        {
            action: SpecificationItemAction.delete,
            active:  !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.changeOwner,
            active: (userRole === Role.ROLE_ADMIN || userRole === Role.ROLE_SALESMAN) && !stageItem,
            options: []
        },
        {
            action: SpecificationItemAction.template,
            active: itemPage,
            disabled: !isValid,
            options: []
        }
    ];

};
