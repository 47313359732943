import {Autocomplete, createTheme, TextField, ThemeProvider} from '@mui/material';
import {OptionListItem} from '../../../../interface/common.interface';
import * as React from 'react';
import {
    AutocompleteChangeReason,
} from '@mui/base/useAutocomplete/useAutocomplete';
import {useState} from 'react';
import {multiselectTheme} from './app-multiselect.meta';
import {Close} from '@mui/icons-material';


interface AppMultiselectProps {
    options: OptionListItem[];
    selected?: OptionListItem[];
    placeholder?: string;
    limitTags?: number;
    onChange: (event: React.SyntheticEvent,
               value: any,
               reason: AutocompleteChangeReason
    ) => void;
}


const theme = createTheme(multiselectTheme);


const AppMultiselect = ({options, selected, limitTags, placeholder, onChange}: AppMultiselectProps) => {

    const [open, setOpen] = useState<boolean>(false);

    return (
        <ThemeProvider theme={theme}>
            <Autocomplete
                multiple
                limitTags={limitTags || 2}
                open={open}
                options={options}
                getOptionLabel={(option) => option.title}
                defaultValue={selected || []}
                onChange={onChange}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                ChipProps={{deleteIcon: <Close/>}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder || undefined}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />

                )}
                sx={{width: '500px'}}
            />
        </ThemeProvider>
    );
};

export {AppMultiselect};
