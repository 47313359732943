import {useAppSelector} from '../../../../../hook/store';
import {SpecificationState} from '../../../../../store/slice/spec-slice';
import {useNavigate, useParams} from 'react-router-dom';
import React, {ComponentType, ReactElement, ReactNode, useCallback, useMemo, useState} from 'react';
import {AppSuspense} from '../../../../../components/app-suspense/app-suspense';
import {SpecificationTabWrapper} from '../tab-header/specification-tab-wrapper';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Grid, GridCellProps, GridColumn, GridSortChangeEvent} from '@progress/kendo-react-grid';
import {GridNoRecords as NoRecords} from '@progress/kendo-react-grid/dist/npm/GridNoRecords';
import {orderBy, SortDescriptor} from '@progress/kendo-data-query';
import {MenuActions} from '../../../../../components/menu-actions/menu-actions';
import {DropDownButtonItem} from '@progress/kendo-react-buttons';

import {ButtonsPopupSettings} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/PopupSettings';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {SpecItem} from '../../../../../interface';
import {ROUTE_PATH} from '../../../../../constants/routes';
import {GridRowClickEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";

const SpecificationStages = () => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);
    const [sort, setSort] = useState<SortDescriptor[]>([]);

    const menuPosition = useMemo((): ButtonsPopupSettings => {
        return {
            anchorAlign: {horizontal: 'right', vertical: 'bottom'},
            popupAlign: {horizontal: 'right', vertical: 'top'}
        };
    }, []);

    const isReady = useMemo((): boolean => {
        return !!urlParams?.id && !!currentSpec?.id && urlParams.id === currentSpec.id;
    }, [urlParams, currentSpec]);

    const totalCell = useCallback((props: GridCellProps) => {
        const total = props?.dataItem?.doc?.invoice?.summary?.total || '';
        const parts = total.toString().split('.');

        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        return <td style={{textAlign: 'right'}}>
            {parts.join('.')}
        </td>;
    }, [currentSpec]);

    const deliveryCell = useCallback((props: GridCellProps) => {
        return <td style={{textAlign: 'right'}}>
            {props?.dataItem?.deliveryWeeks || '-'}
        </td>;
    }, [currentSpec]);

    const onAction = (e: DropDownButtonItemClickEvent, dataItem: SpecItem) => {
        navigateToStage (e?.nativeEvent?.ctrlKey || e?.nativeEvent?.metaKey, dataItem.id);
    };

    const handleRowClick = (e: GridRowClickEvent) => {
        navigateToStage (e?.nativeEvent?.ctrlKey || e?.nativeEvent?.metaKey, e.dataItem.id);
    };

    const gridActions = ({dataItem, className, style}: GridCellProps): ReactElement => {
        return <td style={{...style, textAlign: 'right'}} className={className}>
            <MenuActions onAction={(action) => onAction(action, dataItem)} popupSettings={menuPosition}>
                <DropDownButtonItem key={'Открыть'} text={'Открыть'}/>
            </MenuActions>
        </td>;
    };

    const navigateToStage = (tewTab: boolean, id: string): void => {
        if (tewTab) {
            window.open(`/${ROUTE_PATH.specifications}/${id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.specifications}/${id}`);
        }
    }

    return (
        <SpecificationTabWrapper>
            <AppSuspense condition={isReady}>
                <Card className="h100">
                    <CardBody>
                        <Grid
                            style={{height: '100%', width: '100%'}}
                            sortable={true}
                            scrollable={'none'}
                            data={orderBy(currentSpec?.stages || [], sort)}
                            sort={sort}
                            rowHeight={64}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                            }}
                            onRowClick={handleRowClick}
                        >
                            <GridColumn field="stageNumber" title="№" sortable={true}/>
                            <GridColumn field="doc.invoice.summary.total"
                                        title="Стоимость, ₽"
                                        sortable={true}
                                        cell={totalCell}
                                        headerClassName={'text-right'}
                            />
                            <GridColumn
                                field="deliveryWeeks" title="Время поставки, нед." sortable={true}
                                cell={deliveryCell}
                                headerClassName={'text-right'}
                            />
                            <GridColumn field="" title="" width={100} cell={gridActions}/>

                            <NoRecords>
                                Список пуст.
                            </NoRecords>
                        </Grid>
                    </CardBody>
                </Card>
            </AppSuspense>
        </SpecificationTabWrapper>
    );
};

export {SpecificationStages};
