import {ThemeOptions} from '@mui/material';

export const multiselectTheme: ThemeOptions = {
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    minWidth: '100%'
                },
                inputRoot: {
                    padding: 8,
                    background: '#FAFAFA',
                    borderColor: 'transparent',
                    borderRadius: 8,
                    gap: 8,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    '& span.MuiAutocomplete-tag': {
                        border: '1px solid #00BEC8',
                        borderRadius: 4,
                        background: 'transparent',
                        color: '#6C757D'
                    },

                },
                tag: {
                    color: '#FFFFFF',
                    background: '#00BEC8',
                    borderRadius: 4,
                    padding: '4px 6px',
                    margin: 0
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    paddingRight: 22,
                    paddingLeft: 6,
                    paddingBottom: 4,
                    paddingTop: 4,
                },
                label: {
                    padding: 0,
                    paddingRight: 4,
                },
                deleteIcon: {
                    fontSize: 16,
                    color: '#ffffff',
                    margin: 0
                }
            }
        }
    }
};
