import {Card, CardBody} from '@progress/kendo-react-layout';
import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {AddDialog} from './add-dialog/add-dialog';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import {getSpecification, updateSpecificationForms} from '../../../store/slice/spec-slice';
import {SpecItem} from '../../../interface';
import {
    getSelectedState,
    Grid,
    GridCellProps,
    GridColumn,
    GridHeaderSelectionChangeEvent, GridSelectionChangeEvent,
    GridSortChangeEvent
} from '@progress/kendo-react-grid';
import {showError, showWarning} from '../../../store/slice/toast-slice';
import {getAllSpecTypes, SpecTypeState} from '../../../store/slice/spec-type-slice';
import {GridNoRecords as NoRecords} from '@progress/kendo-react-grid/dist/npm/GridNoRecords';
import {AppSuspense} from '../../../components/app-suspense/app-suspense';
import {getter, orderBy, SortDescriptor} from '@progress/kendo-data-query';
import {LoadingButton} from '@mui/lab';
import {DropDownButton, DropDownButtonItem} from '@progress/kendo-react-buttons';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {ROUTE_PATH} from '../../../constants/routes';


interface BulkItem {
    item: SpecItem;
    status?: BulkItemStatus;
    total?: string;
    selected?: boolean;
}

enum BulkItemStatus {
    ok = 'OK',
    error = 'Ошибка',
    warning = 'ОК',
    notFilled = 'Данные не заполнены',
    inProgress = '(пересчет...)'
}

enum BulkItemActions {
    open = 'Открыть',
    recalculate = 'Пересчитать',
    delete = 'Удалить'
}

const BULK_STORAGE = 'bulkItems';
const DATA_ITEM_KEY: string = 'item.id';
const SELECTED_FIELD: string = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const BulkCalculation = () => {

    const dispatch = useAppDispatch();
    const {allSpecTypes} = useAppSelector<SpecTypeState>(state => state.specType);
    const [addDialog, setAddDialog] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [preload, setPreload] = useState<boolean>(true);
    const [data, setData] = useState<BulkItem[]>([]);
    const [storage, setStorage] = useState<string[]>([]);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [queue, setQueue] = useState<string[]>([]);

    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    const onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const hasSelected = (): boolean => {
        return Object.values(selectedState).indexOf(true) !== -1;
    };

    const addSpecifications = (ids: string) => {
        const specIds = ids.match(/[+-]?\d+(\.\d+)?/g);
        if (!specIds) {
            dispatch(showWarning('Введен некорректный номер спцификации (спецификаций)'));
            return;
        }
        const dataIds = Array.isArray(data) ? data.map((v) => v.item.id) : [];

        const newIds = specIds.filter((v) => !dataIds.includes(v));
        const existingIds = specIds.filter((v) => dataIds.includes(v));

        if (existingIds.length) {
            const text = existingIds.length > 1 ? `Спецификации ${existingIds.join(',')} уже добавлены.` : `Спецификация ${existingIds.join('')} уже добавлена.`;
            dispatch(showWarning(text));
        }

        //

        if (newIds.length) {
            setQueue(newIds);
        }

        // if (data.find((v) => v.item.id === id)) {
        //     dispatch(showWarning(`Спецификация ${id} уже добавлена.`));
        //     return;
        // }

        // if (data.find((v) => v.item.id === id)) {
        //     dispatch(showWarning(`Спецификация ${id} уже добавлена.`));
        //     return;
        // }
        // setInProgress(true);
        // dispatch(getSpecification(id)).unwrap()
        //     .then(
        //         (res) => {
        //             setStorage((prevState) => {
        //                 const newState = [...prevState, id];
        //                 const unique = new Set(newState);
        //                 localStorage.setItem(BULK_STORAGE, JSON.stringify(Array.from(unique)));
        //                 return newState;
        //             });
        //             setData((prevState) => {
        //                 if (prevState.find((v) => v.item.id === id)) {
        //                     return prevState;
        //                 }
        //                 return [...prevState, {item: res}];
        //             });
        //             setAddDialog(false);
        //         }
        //     )
        //     .finally(() => setInProgress(false));
    };



    const addSpecification = () => {
        if (!queue.length) {
            setInProgress(false);
            setAddDialog(false);
            return;
        }
        setInProgress(true);
        const ids = [...queue];
        const id = ids.shift();
        if (id) {
            try {
                dispatch(getSpecification(id)).unwrap()
                    .then(
                        (res) => {
                            setStorage((prevState) => {
                                const newState = [...prevState, id];
                                const unique = new Set(newState);
                                localStorage.setItem(BULK_STORAGE, JSON.stringify(Array.from(unique)));
                                return newState;
                            });
                            setData((prevState) => {
                                if (prevState.find((v) => v.item.id === id)) {
                                    return prevState;
                                }
                                return [...prevState, {item: res}];
                            });
                        }
                    )
            } catch (e) {
                dispatch(showError(`Ошибка добавления спецификации ;${id}`))
            } finally {
                setQueue(ids);
            }
        }

    };


    const deleteItem = (id: string) => {
        setData((prevState) => {
            return prevState.filter((v) => v.item.id !== id);
        });
        setStorage((prevState) => {
            const newState = prevState.filter((v) => v !== id);
            localStorage.setItem(BULK_STORAGE, JSON.stringify(newState));
            return newState;
        });
    };

    const typeCell = (props: GridCellProps): ReactElement => {
        if (!allSpecTypes) {
            return <></>;
        }
        const type = allSpecTypes.result.find(t => t.id === props.dataItem.item.typeId);
        return <td>
            <div className="grid-cell">
                <p>{type?.name || ''}</p>
                <span className={'version'}>v{type?.version}</span>
            </div>
        </td>;
    };

    const statusCell = (props: GridCellProps): ReactElement => {
        return <td>
            <div className="grid-cell">
                {props.dataItem.status === BulkItemStatus.error && (
                    <span style={{color: '#F15146'}}>{props.dataItem.status || ''}</span>
                )}
                {props.dataItem.status === BulkItemStatus.notFilled && (
                    <span style={{color: '#F15146'}}>{props.dataItem.status || ''}</span>
                )}
                {props.dataItem.status === BulkItemStatus.ok && (
                    <span style={{color: '#00BEC8'}}>{props.dataItem.status || ''}</span>
                )}
                {props.dataItem.status === BulkItemStatus.warning && (
                    <span style={{color: '#EFAA42'}}>{props.dataItem.status || ''}</span>
                )}
                {props.dataItem.status === BulkItemStatus.inProgress && (
                    <span>{props.dataItem.status || ''}</span>
                )}
            </div>
        </td>;
    };

    const gridActions = (props: GridCellProps): ReactElement => {
        const itemRender = (itemData: { item: any; itemIndex: number }) => {
            return <div className="action-item">
                <span data-action={itemData.item.text}>{itemData.item.text}</span>
            </div>;
        };

        return <td style={{textAlign: 'right'}}>
            <DropDownButton
                className={'icon-button'}
                size={'small'}
                themeColor={'primary'}
                fillMode={'solid'}
                rounded={'small'}
                icon={'more-vertical'}
                itemRender={itemRender}
                onItemClick={(e) => handleAction(e, props.dataItem.item.id)}
                disabled={inProgress}
            >
                <DropDownButtonItem text={BulkItemActions.open}/>
                <DropDownButtonItem text={BulkItemActions.recalculate}/>
                <DropDownButtonItem text={BulkItemActions.delete}/>

            </DropDownButton>
        </td>;
    };

    const handleAction = (action: DropDownButtonItemClickEvent, id: string) => {
        switch (action.item.text) {
            case BulkItemActions.recalculate:
                setInProgress(true);
                const item = data.find((v) => v.item.id === id);
                if (item?.item) {
                    recalculateItem(item.item, -1);
                }
                break;
            case BulkItemActions.delete:
                deleteItem(id);
                break;
            case BulkItemActions.open:
                window.open(`/${ROUTE_PATH.specifications}/${id}`, '_blank');
                break;
        }
    };

    const loadItems = (items: string[]) => {
        const itemsList: BulkItem[] = [];
        let total = items.length;
        const errorIds: string[] = [];
        items.forEach((id) => {
            dispatch(getSpecification(id)).unwrap()
                .then((res) => {
                    if (!itemsList.find((v) => v.item.id === id)) {
                        itemsList.push({item: res});
                    }
                })
                .catch((e: any) => {
                    errorIds.push(id);
                    total--;
                })
                .finally(() => {
                    if (itemsList.length === total) {
                        setPreload(false);
                        setData(itemsList);
                        if (errorIds.length) {
                            setStorage((prevState) => {
                                const newState = prevState.filter((v) => !errorIds.includes(v));
                                localStorage.setItem(BULK_STORAGE, JSON.stringify(newState));
                                return newState;
                            });
                        }
                    }
                });
        });
    };

    const setItemStatus = (id: string, status: BulkItemStatus) => {
        setData((prevState) => {
            return prevState.map((v) => v.item.id === id ? {...v, status} : v);
        });
    };

    const setItemTotal = (id: string, total: string) => {
        setData((prevState) => {
            return prevState.map((v) => v.item.id === id ? {...v, total} : v);
        });
    };

    const checkStatus = (id: string, res: Partial<SpecItem>) => {
        const messages = res?.doc?.validation?.messages || [];
        if (messages.find((m) => m.type === 'error')) {
            setItemStatus(id, BulkItemStatus.error);
            return;
        }
        if (messages.find((m) => m.type === 'warning')) {
            setItemStatus(id, BulkItemStatus.warning);
        } else {
            setItemStatus(id, BulkItemStatus.ok);
        }
        const total = `${getTotal(res?.doc?.invoice?.summary?.total)} ₽`;
        setItemTotal(id, total);
    };

    const getTotal = (total: number | undefined): string => {
        if (!total) {
            return '-';
        }
        const parts = total.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    };

    const recalculateItem = (item: SpecItem, order: number) => {
        setItemStatus(item.id, BulkItemStatus.inProgress);
        dispatch(getSpecification(item.id)).unwrap().then(
            (v: SpecItem) => {
                if (!formsFilled(v)) {
                    setItemStatus(item.id, BulkItemStatus.notFilled);
                    if (order > -1) {
                        recalculate(order + 1);
                    } else {
                        setInProgress(false);
                    }
                    return;
                }

                const forms = Object.entries(v.doc.forms).reduce((acc: any, [key, value]) => {
                    acc[key] = value.data;
                    return acc;
                }, {});
                dispatch(updateSpecificationForms({id: item.id, forms})).unwrap()
                    .then((res) => {
                        checkStatus(item.id, res);
                    })
                    .catch(() => {
                        setItemStatus(item.id, BulkItemStatus.error);
                    })
                    .finally(() => {
                        if (order > -1) {
                            recalculate(order + 1);
                        } else {
                            setInProgress(false);
                        }
                    });
            }
        );
    };

    const formsFilled = (spec: SpecItem): boolean => {
        const forms = spec.meta.formTabs.reduce((acc: any, curr: any) => {
            if (curr?.forms && Array.isArray(curr.forms)) {
                acc = acc.concat(curr.forms);
            }
            return acc;
        }, []);
        for (const [key, value] of Object.entries(spec.doc.forms)) {
            if (!forms.includes(key)) {
                continue;
            }
            const required = spec.meta.forms[key]?.schema?.required;
            if (required) {
                for (const field of required) {
                    if (value.data[field] === undefined || value.data[field] === null) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    const recalculate = (order: number) => {
        setInProgress(true);
        if (data.length > order) {
            recalculateItem(data[order].item, order);
        } else {
            setInProgress(false);
        }
    };

    const getLink = (): string => {
        const ids = Object.entries(selectedState).filter(([k, v]) => !!v).map(
            ([k, v]) => `SpecIds=${k}`
        ).join('&');
        return `/api/spec2/exportSpecReports?${ids}`;
    };

    useEffect(() => {
        if (!allSpecTypes) {
            dispatch(getAllSpecTypes());
        }
        const storedItems = localStorage.getItem(BULK_STORAGE);

        if (storedItems && storedItems.length > 2) {
            const items = JSON.parse(storedItems);
            setStorage(items);
            loadItems(items);
        } else {
            setPreload(false);
        }
    }, [dispatch]);

    useEffect(() => {
        setData(orderBy(data, sort));
    }, [sort]);

    useEffect(() => {
        addSpecification();
    }, [queue]);

    return (
        <AppSuspense condition={!preload}>
            <Card>
                <CardBody style={{display: 'flex', gap: '8px'}}>
                    <Button
                        type={'button'}
                        variant={'contained'}
                        onClick={() => setAddDialog(true)}
                        disabled={inProgress}
                    >
                        <AddIcon/> Добавить
                    </Button>
                    {!!data?.length && (
                        <LoadingButton
                            type={'button'}
                            variant={'contained'}
                            disabled={inProgress}
                            loading={inProgress}
                            loadingIndicator={<span style={{whiteSpace: 'nowrap'}}>Идет пересчет...</span>}
                            onClick={() => recalculate(0)}
                        >
                            Пересчитать
                        </LoadingButton>
                    )}
                    {!hasSelected() && (
                        <Button
                            style={{marginLeft: 'auto'}}
                            type={'button'}
                            variant={'contained'}
                            disabled={true}
                        >
                            Выгрузить отчеты
                        </Button>
                    )}
                    {hasSelected() && (
                        <a style={{marginLeft: 'auto'}} href={getLink()} download>
                            <Button
                                type={'button'}
                                variant={'contained'}
                            >
                                Выгрузить отчеты
                            </Button>
                        </a>
                    )}
                </CardBody>
            </Card>

            <Card style={{marginTop: '10px'}}>
                <CardBody>
                    <div className={'universal-grid'}>
                        <Grid
                            style={{width: '100%'}}
                            data={data.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            sortable={true}
                            sort={sort}
                            onSortChange={(e: GridSortChangeEvent) => {
                                setSort(e.sort);
                            }}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: 'multiple',
                            }}
                            onSelectionChange={onSelectionChange}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                        >

                            <GridColumn
                                field={SELECTED_FIELD}
                                width={70}
                                headerSelectionValue={
                                    data.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                }
                            />
                            <GridColumn
                                title={'№'}
                                field={'item.id'}
                                width={120}
                            />
                            <GridColumn
                                title={'Тип'}
                                field={'item.typeId'}
                                cell={typeCell}
                                width={150}
                            />
                            <GridColumn
                                title={'Имя'}
                                field={'item.name'}
                            />
                            <GridColumn
                                title={'Статус'}
                                field={'status'}
                                cell={statusCell}
                                width={200}
                            />
                            <GridColumn
                                title={'Общая сумма'}
                                field={'total'}
                                width={300}
                            />
                            <GridColumn
                                field=""
                                title=""
                                width={100}
                                reorderable={false}
                                cell={gridActions}
                            />
                            <NoRecords>
                                Список пуст.
                            </NoRecords>
                        </Grid>
                    </div>
                </CardBody>
            </Card>

            {addDialog && (
                <AddDialog
                    onClose={() => setAddDialog(false)}
                    onAdd={addSpecifications}
                    inProgress={inProgress}
                />
            )}
        </AppSuspense>
    );
};

export {BulkCalculation};

