import {AppPromptData, SpecificationItemAction, SpecificationItemActionData} from '../../../../interface';
import './action-prompt.scss';
import {useEffect, useState} from 'react';
import {AppPrompt} from '../../../../components/app-prompt/app-prompt';

interface ActionPromptProps {
    actionData: SpecificationItemActionData,
    onConfirm: () => void;
    onClose: () => void;
}

const ActionPrompt = ({actionData: {action, item}, onConfirm, onClose}: ActionPromptProps) => {
    const [actionData, setActionData] = useState<AppPromptData>({
        title: 'Подтвердите действие',
        message: [''],
        confirmButton: 'Подтвердить'
    });


    useEffect(() => {
        switch (action) {
            case SpecificationItemAction.publish:
                setActionData({
                    title: 'Публикация спецификации',
                    message: [
                        `Вы хотите опубликовать спецификацию ${item?.id} "${item?.name}"?`,
                        'После публикации редактировать спецификацию будет нельзя.'
                    ],
                    confirmButton: 'Опубликовать'
                });
                break;
            case SpecificationItemAction.archive:
                setActionData({
                    title: 'Архивирование спецификации',
                    message: [`Вы хотите перенести спецификацию ${item?.id} "${item?.name}" в архив?`],
                    confirmButton: 'Подтвердить'
                });
                break;
            case SpecificationItemAction.unarchive:
                setActionData({
                    title: 'Архивирование спецификации',
                    message: [`Вы хотите вернуть спецификацию ${item?.id} "${item?.name}" из архива?`],
                    confirmButton: 'Подтвердить'
                });
                break;
            case SpecificationItemAction.delete:
                setActionData({
                    title: 'Удаление спецификации',
                    message: [
                        `Вы хотите удалить спецификацию ${item?.id} "${item?.name}"?`,
                        'Удаленная спецификация не подлежит восстановлению.'
                    ],
                    confirmButton: 'УДАЛИТЬ',
                    buttonError: true
                });
                break;
            case SpecificationItemAction.clone_copy:
                setActionData({
                    title: 'Дублирование спецификации',
                    message: [
                        `Вы хотите создать новую версию спецификации ${item?.id} ?`
                    ],
                    confirmButton: 'Подтвердить',
                });
                break;
        }
    }, [action]);

    return <>
        <AppPrompt data={actionData} onClose={onClose} onConfirm={onConfirm} />
    </>;
};

export {ActionPrompt};
