import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface UtilsState {
    searchFocus: boolean;
}


const initialState: UtilsState = {
    searchFocus: false
};

const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        setSearchFocus(state: UtilsState, action: PayloadAction<boolean>) {
            return {
                ...state,
                searchFocus: action.payload
            };
        },
        resetUtils() {
            return initialState;
        }
    }
});

export const {setSearchFocus, resetUtils} = utilsSlice.actions;
export default utilsSlice.reducer;
