import {useNavigate} from 'react-router-dom';
import {DropDownButton, DropDownButtonItem} from '@progress/kendo-react-buttons';
import React, {ReactElement, useState} from 'react';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {ROUTE_PATH} from '../../../../constants/routes';
import {AppPromptData} from '../../../../interface';
import {AppPrompt} from '../../../../components/app-prompt/app-prompt';
import {AppRenameDialog} from '../../../../components/dialog/rename/app-rename-dialog';
import axios from 'axios';
import {API_ADMIN_NODE_TRAITS} from '../../admin.meta';
import {useAppDispatch} from '../../../../hook/store';
import {showError} from '../../../../store/slice/toast-slice';
import {AdminTrait} from '../../../../interface/admin/admin-traits.interface';


export const TraitsItemAction = {
    edit: 'Редактировать',
    rename: 'Переименовать',
    delete: 'Удалить',
} as const;

type TraitsItemCodeKey = keyof typeof TraitsItemAction;
type TraitsItemCode = typeof TraitsItemAction[TraitsItemCodeKey];


interface TraitsItemActionsProps {
    traitsItem: AdminTrait;
    onReload: () => void;
}

const TraitsItemActions = ({traitsItem, onReload}: TraitsItemActionsProps) => {

    const actions = Object.entries(TraitsItemAction);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [lastAction, setLastAction] = useState<TraitsItemCode>();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [confirmData, setConfirmData] = useState<AppPromptData>();
    const [showRename, setShowRename] = useState<boolean>(false);


    const itemRender = (itemData: { item: any; itemIndex: number }): ReactElement => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };


    const editItem = (e: DropDownButtonItemClickEvent) => {
        if (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey) {
            window.open(`/${ROUTE_PATH.adminNodeTraits}/${traitsItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.adminNodeTraits}/${traitsItem.id}`);
        }
    };

    const handleAction = (e: DropDownButtonItemClickEvent): void => {
        if (e.item.text === TraitsItemAction.edit) {
            editItem(e);
        } else {
            showConfirmModal(e.item.text);
        }
    };

    const getConfirmData = (traitItem: AdminTrait, action: TraitsItemCode): AppPromptData => {
        switch (action) {
            case TraitsItemAction.rename:
                return {
                    title: 'Переименование характеристики',
                    message: [`Вы хотите переименовать характеристику "${traitItem.name || traitItem.id}"?`],
                    confirmButton: 'Переименовать',
                    buttonError: false
                };
            case TraitsItemAction.delete:
                return {
                    title: 'Удаление характеристики',
                    message: [`Вы хотите удалить характеристику "${traitItem.name || traitItem.id}"`, 'Удаленные характеристики не подлежат восстановлению.'],
                    confirmButton: 'Удалить',
                    buttonError: true
                };
            default:
                return {} as AppPromptData;
        }
    };

    const handleConfirm = () => {
        switch (lastAction) {
            case TraitsItemAction.rename:
                setShowRename(true);
                setShowConfirm(false);
                break;
            case TraitsItemAction.delete:
                deleteItem();
                break;
        }
    };


    const showConfirmModal = (action: TraitsItemCode): void => {
        setLastAction(action);
        setConfirmData(getConfirmData(traitsItem, action));
        setShowConfirm(true);
    };

    const handleCloseConfirm = () => {
        setShowConfirm(false);
        setIsLoading(false);
    };

    const handleRenameClose = () => {
        setShowRename(false);
    };

    const handleRenameSubmit = (id: string, name: string) => {
        axios.put(`${API_ADMIN_NODE_TRAITS}/${id}/rename`, {name})
            .then(() => onReload())
            .catch(() => dispatch(showError('Не удалось переименовать характеристику')))
            .finally(() => {
                setIsLoading(false);
                setShowRename(false);
            });
    };

    const deleteItem = () => {
        setIsLoading(true);
        axios.delete(`${API_ADMIN_NODE_TRAITS}/${traitsItem.id}`)
            .then(() => onReload())
            .catch(() => dispatch(showError('Не удалось удалить характеристику')))
            .finally(() => {
                setIsLoading(false);
                setShowConfirm(false);
            });
    };


    return (
        <>
            <DropDownButton
                size={'small'}
                themeColor={'primary'}
                fillMode={'solid'}
                rounded={'small'}
                icon={'more-vertical'}
                itemRender={itemRender}
                onItemClick={handleAction}
            >
                {actions.map(([key, value]) => (
                    <DropDownButtonItem key={key} text={value}/>
                ))}
            </DropDownButton>
            {showConfirm && !!confirmData && (
                <AppPrompt data={confirmData}
                           onClose={handleCloseConfirm}
                           onConfirm={handleConfirm}
                           isLoading={isLoading}/>
            )}
            {showRename && (
                <AppRenameDialog
                    id={traitsItem.id}
                    name={traitsItem.name}
                    title={'Переименование характеристики'}
                    onClose={handleRenameClose}
                    onSubmit={handleRenameSubmit}
                />
            )}
        </>
    );
};

export {TraitsItemActions};
