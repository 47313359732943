import {useState} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Button, FormControl, FormHelperText, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import styles from '../../settings.module.scss';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {showAlert} from '../../../../store/slice/alert-slice';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {AppBreadcrumb} from '../../../../interface/breadcrumbs.interface';
import {AppBreadcrumbs} from '../../../../components/app-breadcrumbs/app-breadcrumbs';
import {UserState} from '../../../../store/slice/user-slice';
import {ROUTE_PATH} from '../../../../constants/routes';

interface IUserPassword {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}


const SettingsUserPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [formData, setFormData] = useState<IUserPassword>({ currentPassword:'', newPassword: '', confirmPassword: '' });
    const {currentUser} = useAppSelector<UserState>(store => store.user);

    const breadcrumbs: AppBreadcrumb[] = [
        {
            title: 'Настройки',
            path: `/${ROUTE_PATH.settings}`
        },
        {
            title: 'Пользователь',
            path: ''
        }
    ];

    const handleTypeCurrentPass = (e: any) => {
        setFormData({ ...formData, currentPassword: e.target.value });
    };

    const handleChangePassword = (e: any) => {
        setFormData({ ...formData, newPassword: e.target.value });
    };

    const handleConfirmPassword = (e: any) => {
        setFormData({ ...formData, confirmPassword: e.target.value });
    };

    const updatePassword = async () => {
        const data = await fetch('api/user/current/password', {
            method: 'PUT',
            body: JSON.stringify({currentPassword: formData.currentPassword, newPassword: formData.newPassword}),
            headers: {
                'content-type': 'application/json'
            }
        });
        return data;
    };

    const saveSettings = () => {
        setInProgress(true);
        updatePassword()
            .then((response) => {
                if(response.status === 200) {
                    dispatch(showAlert({message: 'Пароль изменен.', header: 'Изменение настроек пользователя'}));
                    return;
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                if(response) {
                    dispatch(showAlert({message: response.Error, header: 'Изменение настроек пользователя', errorColor: 'red'}));
                }
            })
            .finally(() => {
                console.log('ended');
                setInProgress(false);
            });
    };

    return (
        <>
            <div className={styles.settingsCard}>
                <AppBreadcrumbs items={breadcrumbs}/>
            </div>

            <Card className={styles.settingsCard}>
                <CardBody className={styles.settingsBody}>
                    <div className={styles.rowTitle}>Изменение пароля</div>
                    <AppSuspense condition={!!currentUser}>
                        <div className={styles.row}>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField 
                                        label="Текущий пароль"
                                        fullWidth
                                        variant={'standard'}
                                        defaultValue={''}
                                        type={'password'}
                                        onChange={handleTypeCurrentPass}
                                />
                            </FormControl>
                        </div>
                        <div className={styles.row}>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField 
                                        label="Новый пароль"
                                        fullWidth
                                        error={(formData.confirmPassword && formData.newPassword && formData.confirmPassword !== formData.newPassword) || undefined}
                                        variant={'standard'}
                                        defaultValue={''}
                                        type={'password'}
                                        onChange={handleChangePassword}
                                        sx={{
                                            '& .MuiFormLabel-root.Mui-error': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                            input: { color: formData.confirmPassword && formData.newPassword && formData.confirmPassword !== formData.newPassword ? 'red' : ''},
                                        }}
                                        className={styles.settingsInput}
                                />
                                <FormHelperText style={{color: 'red'}}>{formData.confirmPassword && formData.newPassword && formData.confirmPassword !== formData.newPassword ? 'Пароль не совпадает.' : ''}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className={styles.row}>
                            <FormControl fullWidth margin={'normal'}>
                                <TextField 
                                        label="Новый пароль (введите еще раз)"
                                        fullWidth
                                        error={(formData.confirmPassword && formData.newPassword && formData.confirmPassword !== formData.newPassword)|| undefined}
                                        variant={'standard'}
                                        defaultValue={''}
                                        type={'password'}
                                        onChange={handleConfirmPassword}
                                        sx={{
                                            '& .MuiFormLabel-root.Mui-error': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                            },
                                            input: { color: formData.confirmPassword && formData.newPassword && formData.confirmPassword !== formData.newPassword ? 'red' : ''},
                                        }}
                                        className={styles.settingsInput}
                                />
                                <FormHelperText style={{color: 'red'}}>{formData.confirmPassword && formData.newPassword && formData.confirmPassword !== formData.newPassword ? 'Пароль не совпадает.' : ''}</FormHelperText>
                            </FormControl>
                        </div>
                        <div className={styles.row} style={{justifyContent: 'flex-start'}}>
                            <Button
                                variant={'outlined'}
                                className={styles.settingsBtn}
                                disabled={inProgress}
                                onClick={() => navigate(`/${ROUTE_PATH.settings}`)}
                            >Отмена</Button>
                            <LoadingButton
                                variant={'contained'}
                                className={styles.settingsBtn}
                                onClick={saveSettings}
                                disabled={inProgress || formData.confirmPassword !== formData.newPassword || !formData.currentPassword || !formData.confirmPassword || !formData.newPassword}
                                loading={inProgress}
                            >Изменить</LoadingButton>
                        </div>
                    </AppSuspense>
                </CardBody>
            </Card>

        </>

    );
};

export {SettingsUserPassword};
