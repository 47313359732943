import {orderBy, SortDescriptor} from '@progress/kendo-data-query';
import {Grid, GridCellProps, GridColumn, GridSortChangeEvent, GridNoRecords as NoRecords} from '@progress/kendo-react-grid';
import {ColumnInterface, TypeColumnMenu} from '../../../../../components';
import React, {ReactElement, SyntheticEvent, useEffect, useState} from 'react';
import {useAppSelector} from '../../../../../hook/store';
import {NodeTypeState} from '../../../../../store/slice/node-type-slice';
import {ComponentItemNameCell} from '../component-item-name-cell/component-item-name-cell';
import {ItemComponentActions} from '../component-item-links.interface';
import {ApiLinkedNodeItem} from '../../../../../interface';
import {componentItemLinkColumns} from './component-item-links-grid.meta';
import ObjectHelper from '../../../../../helpers/object.helper';
import {ReactComponent as IconFunction} from '../../../../../assets/img/icon/function.svg';
import {GridRowClickEvent} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import {Menu, MenuItem} from '@progress/kendo-react-layout';
import {NodeState} from '../../../../../store/slice/node-slice';
import {MenuSelectEvent} from '@progress/kendo-react-layout/dist/npm/menu/events';

interface ComponentItemLinksGridProps {
    gridData: ApiLinkedNodeItem[];
    onAction: (action: MenuSelectEvent, data: ApiLinkedNodeItem, optionId?: string) => void;
    optionId?: string;
    readonly?: boolean
}

const ComponentItemLinksGrid = ({onAction, gridData, readonly = false, optionId}: ComponentItemLinksGridProps) => {
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const [columns, setColumns] = useState<ColumnInterface[]>([]);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);


    const handleNameClick = (event: SyntheticEvent, item: ApiLinkedNodeItem) => {
        onAction({item: {text: ItemComponentActions.edit}} as MenuSelectEvent, item);
    };

    const handleRowClick = (event: GridRowClickEvent) => {
        onAction({item: {text: ItemComponentActions.edit}} as MenuSelectEvent, event.dataItem);
    };


    const onColumnsChange = (columns: ColumnInterface[]) => {
        setColumns(columns);
    };


    const typeCell = ({dataItem}: GridCellProps): ReactElement => {
        const type = dataItem.linkedNode?.type;
        let text = '-';
        if (Array.isArray(allNodeTypes)) {
            if(Array.isArray(type)){
                const nodes = allNodeTypes?.filter(t => type.includes(t.id));
                if(Array.isArray(nodes)){
                    text = nodes.map(n => n.typeName || '').join(', ');
                }
            }else{
                const node = allNodeTypes?.find(t => t.id === type);
                text = node?.typeName || '-';
            }
        }
        return <td onClick={(e => handleNameClick(e, dataItem))}>
            <span>{text}</span>
        </td>;
    };


    const nameCell = (props: GridCellProps): ReactElement => {
        return <td onClick={(e => handleNameClick(e, props.dataItem))}>
            <ComponentItemNameCell data={props.dataItem} showStatus={true}/>
        </td>;
    };


    const valueCell = ({dataItem}: GridCellProps): ReactElement => {
        const isFormula = !!dataItem.quantityFormula;
        const value = dataItem.quantityValue ?? dataItem.quantityFormula;
        return <td title={isFormula ? dataItem.quantityFormula : ''}
                   onClick={(e => handleNameClick(e, dataItem))}
                   style={isFormula ? {whiteSpace:'nowrap'} : {}}
        >
            <span>
                {isFormula && <span><IconFunction style={{verticalAlign: 'middle'}}/> = </span>}
                {value || '-'}
            </span>
        </td>;
    };


    const handleMenuSelect = (e: MenuSelectEvent, dataItem: ApiLinkedNodeItem): void => {
        if (currentNodeItem?.options) {
            const optionId = Object.values(currentNodeItem.options).find((o) => o.name === e.item.text);
            if (optionId) {
                onAction(e, dataItem, optionId.id);
            } else {
                onAction(e, dataItem);
            }
        } else {
            onAction(e, dataItem);
        }
    };


    const gridActions = ({dataItem, className, style}: GridCellProps): ReactElement => {
        const isVariant = Array.isArray(dataItem.nodeTypes) && dataItem.nodeTypes.length;

        let moveOptions: string[] = !!optionId ? ['Компоненты'] : [];
        if (currentNodeItem?.options) {
            const options = Object.values(currentNodeItem.options).filter((o) => o.id !== optionId).map((o) => o.name);
            moveOptions = [...moveOptions, ...options];
        }
        return <td style={{...style, textAlign: 'right'}} className={className}>
            <Menu openOnClick={true}
                  onSelect={(e) => handleMenuSelect(e, dataItem)}
                  className={'option-grid-actions'}
            >
                <MenuItem icon={'more-vertical'}>
                    {currentNodeItem?.options && moveOptions.length && !readonly && (
                        <MenuItem text={ItemComponentActions.move}>
                            {moveOptions.map((v) => (
                                <MenuItem text={v} key={v} data={ItemComponentActions.move} />
                            ))}
                        </MenuItem>
                    )}
                    {currentNodeItem?.options && moveOptions.length && !readonly && (
                        <MenuItem text={ItemComponentActions.clone}>
                            {moveOptions.map((v) => {
                                return (
                                <MenuItem text={v} key={v} data={ItemComponentActions.clone} />
                            )})}
                        </MenuItem>
                    )}
                    {!readonly && (<MenuItem text={ItemComponentActions.edit}/>)}
                    {!isVariant && (<MenuItem text={ItemComponentActions.goto}/>)}
                    {!readonly && (<MenuItem text={ItemComponentActions.delete}/>)}
                </MenuItem>
            </Menu>
        </td>;
    };


    useEffect((() => {
        if (allNodeTypes) {
            const columnsSet: ColumnInterface[] = componentItemLinkColumns(typeCell, nameCell, valueCell);
            let additionalSet: ColumnInterface[] = [];
            const typesProps: { [key: string]: any } = {}; // to collect properties from different types

            for (const item of gridData) {
                const type = allNodeTypes.find(t => t.id === item.linkedNode?.type);
                if (type && ObjectHelper.isObject(type.typeSchema?.properties)) {

                    for (const [key, value] of Object.entries(type.typeSchema?.properties)) {
                        if (!!typesProps[key]) {
                            if (value.title) {
                                const currTitle = typesProps[key].replace('*', '');
                                const hasAstrix = value.title.length !== currTitle.length;
                                const title = value.title.length < currTitle.length ? value.title : currTitle;
                                typesProps[key] = hasAstrix ? `${title}*` : title;
                            }
                        } else {
                            typesProps[key] = value.title || '-';
                        }
                    }
                }
            }
            additionalSet = Object.entries(typesProps).map(([k, v]) => (
                {

                    title: v,
                    field: `linkedNode.calculatedData.${k}`,
                    show: false,
                    sortable: true,
                    width: '200px'
                }
            ));

            setColumns([...columnsSet, ...additionalSet]);
        }
    }), [gridData, allNodeTypes]);

    return (
        <Grid
            style={{height: '100%', width: '100%'}}
            scrollable={'scrollable'}
            data={orderBy(gridData, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
            }}
            rowHeight={64}
            onRowClick={handleRowClick}
        >
            {columns.map((col, idx) => col.show && (
                <GridColumn
                    key={idx}
                    field={col.field}
                    title={col.title}
                    sortable={col.sortable}
                    cell={col.cell}
                    width={col.width || 'auto'}
                />
            ))}
            <GridColumn title="" width={100} cell={gridActions}
                        headerClassName={'actions-menu'}
                        reorderable={false}
                        locked={true}
                        headerCell={() => (
                            <TypeColumnMenu
                                columns={columns}
                                onColumnsChange={onColumnsChange}/>
                        )}
            />
            <NoRecords>
                Список пуст.
            </NoRecords>
        </Grid>
    );
};

export {ComponentItemLinksGrid};

