export enum ROUTE_PATH {
    components = 'components1',
    newComponents = 'components',
    specifications = 'specifications',
    templates = 'templates',
    dictionary = 'dictionary',
    products = 'products',
    users = 'users',
    configurations = 'configurations',
    settings = 'settings',
    login = 'login',
    loginError = '/sso/error',
    nodeTypes = 'nodes',
    admin = "admin",
    adminNodes = 'admin/nodes',
    adminNodeTraits = 'admin/traits',
}
