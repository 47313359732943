import React, {useState, useEffect} from 'react';
import {TextField} from '@mui/material';
import {
    GridColumnMenuItemContent,
    GridColumnMenuItemGroup
} from '@progress/kendo-react-grid';
import {ColumnInterface} from './type-column-menu.interface';
import {ReactComponent as IconFilterOutlined} from '../../assets/img/icon/filter-outlined.svg';
import {ReactComponent as IconFilter} from '../../assets/img/icon/filter.svg';
import {Icon} from '@progress/kendo-react-common';
import {Popover} from '@mui/material';
import styles from './type-column-menu.module.scss';
import {useSearchParams} from 'react-router-dom';

interface TypeColumnMenuProps {
    searchParams?: any;
    columns: ColumnInterface[];
    onFilter?: (filter: string) => void;
    onColumnsChange: (event: any) => void;
    type?: 'component' | 'specification' | 'dictionaryType' | 'dictionary' | 'templates';
    exportLink?: string;
}

const TypeColumnMenu = (props: TypeColumnMenuProps) => {
    const [columns, setColumns] = useState<ColumnInterface[]>(props.columns);
    const [searchColumn, setSearchColumn] = useState('');
    const [showFiltered, setShowFiltered] = useState(false);
    const allChecked = columns.filter((c) => c.show).length === columns.length;
    const [checkAll, setCheckAll] = useState<boolean>(allChecked);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const handleOpen = (event: React.MouseEvent<any>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
        setColumns(props.columns);
    };

    const sortedColumns = [...columns].sort((column1, column2): any => {
        if (column1.show === column2.show) {
            return column1.title?.localeCompare(column2.title!);
        } else if (column1.show && !column2.show) {
            return -1;
        } else {
            return 1;
        }
    });

    const onToggleColumn = (event: any, id: number) => {
        event.stopPropagation();
        const newColumns = sortedColumns.map((column, idx) => {
            return idx === id ? {...column, show: !column.show} : column;
        });
        setColumns(newColumns);
    };

    const handleSearch = (searchString: string) => {
        setColumns(props.columns.filter(col => col.show || col.title?.toLowerCase()?.includes(searchString.toLowerCase().trim())));
    };

    const onSearch = (e: any) => {
        setSearchColumn(e.target.value);
        handleSearch(e.target.value);
    };


    const onSubmit = (event: any) => {
        const selected = columns.filter((c) => c.show).length;
        if (event) {
            event.preventDefault();
        }
        if (!selected) {
            return;
        }
        props.onColumnsChange(columns);
    };

    const onReset = (event: any, checkAll: boolean) => {
        !!event && event.preventDefault();
        const newColumns: ColumnInterface[] = columns.map((col) => {
            return {
                ...col,
                show: checkAll,
            };
        });
        setColumns(newColumns);
    };


    const getFilterMode = (column: any) => {
        switch (column.type) {
            case 'string':
            case 'boolean':
                return 'search';
            case 'number':
            case 'integer':
                return 'range';
        }
    };

    const getFieldFilter = (column: any): string => {
        let columnsList: string[] = [];
        if (props.type === 'specification') {
            columnsList = ['price', 'deliveryWeeks', 'currencyRate', 'supportYears'];
        }
        if (column.type === 'modified') {
            return column.searchField;
        }
        if (column.type === 'name') {
            return 'searchQuery';
        }
        if (column.type === 'status' || column.type === 'author') {
          return column.type;
        }
        if (columnsList.includes(column.searchField)) {
            return column.searchField;
        }
        if (column.nodeType) {
            return `d.${column.searchField}.${getFilterMode(column)}[${column.nodeType?.join(';')}]`;
        }
        return `${column.searchField}.${getFilterMode(column)}`;
    };

    const handleClickFilter = () => {
        setShowFiltered(showFiltered => !showFiltered);
    };

    useEffect(() => {
        setCheckAll(allChecked);
    }, [allChecked]);

    const selectedColumnsCount = columns.filter((c) => c.show).length;
    const oneVisibleColumn = selectedColumnsCount === 1;
    const open = Boolean(anchorEl);

    const showExportIcon = !!props.type || !!props.exportLink;

    let link: string = `${props.exportLink}?${searchParams.toString()}`;

    if (searchParams) {
        searchParams.set('limit', '-1');
        searchParams.delete('offset');
    }

    switch (props.type) {
        case 'component':
            link = `/api/node2/export?${searchParams.toString()}`;
            break;
        case 'specification':
            link = `/api/spec2/export?${searchParams.toString()}`;
            break;
        case 'dictionaryType':
            link = `/api/dictionaryType2/export?${searchParams.toString()}`;
            break;
        case 'dictionary':
            link = `/api/dictionary2/export?${searchParams.toString()}`;
            break;
        case 'templates':
            link = `/api/spec2/templates/export?${searchParams.toString()}`;
            break;
    }



    return (
        <div>
            <div className={styles.menu_icon}>
                {showExportIcon && (
                    <a href={link} download title={'Скачать таблицу'}>
                        <i className="pi pi-download"></i>
                    </a>
                )}
                <span onClick={handleOpen}><Icon name="more-vertical"/></span>
            </div>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={(e) => e.stopPropagation()}
            >
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItemContent show={true} style={{width: '250px'}}>
                        <div className={styles.searchColumn}>
                            <TextField
                                className={'search-field'}
                                label="Колонки"
                                variant="outlined"
                                value={searchColumn}
                                size={'small'}
                                onChange={onSearch}
                            />
                            {props.searchParams && (
                                <span className={`k-icon ${styles.filterIcon}`}
                                      onClick={handleClickFilter}
                                      title={showFiltered ? 'Показать все столбцы' : 'Показать отфильтрованные столбцы'}
                                >
                                    {showFiltered ? <IconFilter/> : <IconFilterOutlined/>}
                                </span>
                            )}

                        </div>
                        <div className={'k-column-list-wrapper'}>
                            <form onSubmit={onSubmit} onReset={(e) => onReset(e, checkAll)}>
                                <div className={`k-column-list ${styles.column_list}`}>
                                    <div className={styles.list_item}
                                         onClick={(e) => {
                                             onReset(e, !checkAll);
                                         }}
                                    >
                                        <span>
                                        <input
                                            className={`k-checkbox k-checkbox-md k-rounded-md ${styles.checkbox}`}
                                            type="checkbox"
                                            readOnly={true}
                                            checked={allChecked && checkAll}
                                            onClick={(e) => {
                                                onReset(e, !checkAll);
                                            }}
                                        />
                                        <label
                                            className={`k-checkbox-label ${styles.checkbox_label}`}
                                            style={{userSelect: 'none', paddingLeft: '4px'}}
                                        >
                                            Выбрать все
                                        </label>
                                        </span>
                                    </div>
                                    {sortedColumns.map((column, idx) => {
                                        let hasTypesFilter, getColumnFilter, searchParamsKey, hasFilter, hasIdFilter, hasDictionaryType, hasDictionaryCode;
                                        if (props.searchParams) {
                                            hasTypesFilter = props.searchParams.get('types') && (column.field === 'type' || (props.type === 'specification' && column.field  === 'typeId'));
                                            hasDictionaryType = props.type === 'dictionary' && !!props.searchParams.get('type') && column.type === 'type';
                                            hasDictionaryCode = [...props.searchParams.keys()].some(key => key.includes('d.code.search')) && column.field === 'data.code';
                                            hasIdFilter = props.searchParams.get('id') && column.field === 'id';
                                            getColumnFilter = getFieldFilter(column);
                                            searchParamsKey = [...props.searchParams.keys()].includes(getColumnFilter);
                                            hasFilter = hasTypesFilter || hasIdFilter || searchParamsKey || hasDictionaryType || hasDictionaryCode;
                                        }
                                        return (
                                            <div key={idx}
                                                 className={`${styles.list_item} ${showFiltered && !hasFilter && styles.hidden}`}
                                                 onClick={(e) => {
                                                     if ((column.show && !oneVisibleColumn) || !column.show) {
                                                         onToggleColumn(e, idx);
                                                     }
                                                 }}
                                            >
                                        <span>
                                        <input
                                            className={`k-checkbox k-checkbox-md k-rounded-md ${styles.checkbox}`}
                                            type="checkbox"
                                            readOnly={true}
                                            disabled={column.show && oneVisibleColumn}
                                            checked={column.show}
                                            onClick={(e) => {
                                                onToggleColumn(e, idx);
                                            }}
                                        />
                                        <label
                                            className={`k-checkbox-label ${styles.checkbox_label}`}
                                            style={{userSelect: 'none', paddingLeft: '4px'}}
                                        >
                                            {column.title}
                                        </label>
                                            {hasFilter && <IconFilterOutlined/>}
                                        </span>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className={`k-actions k-hstack k-justify-content-stretch ${styles.actions}`}>
                                    <button
                                        disabled={!selectedColumnsCount}
                                        className={
                                            'k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-primary'
                                        }
                                    >
                                        Применить
                                    </button>
                                </div>
                            </form>
                        </div>

                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>

            </Popover>
        </div>
    );
};

export {TypeColumnMenu};
