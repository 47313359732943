import {AppSuspense} from '../../../components/app-suspense/app-suspense';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {
    Grid,
    GridCell,
    GridCellProps,
    GridColumn,
    GridColumnReorderEvent,
    GridSortChangeEvent
} from '@progress/kendo-react-grid';
import {GridNoRecords as NoRecords} from '@progress/kendo-react-grid/dist/npm/GridNoRecords';
import {AppPagination} from '../../../components/app-pagination/app-pagination';
import React, {ReactElement, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import {Role, SearchParams, SpecInterface, SpecTemplate, SpecTemplateAction} from '../../../interface';
import {SortDescriptor} from '@progress/kendo-data-query';
import {setSortParam} from '../../../helpers';
import {format, parseISO} from 'date-fns';
import {DATE_TIME} from '../../../constants';
import {DropDownButtonItem} from '@progress/kendo-react-buttons';
import {TemplateActions} from './template-actions';
import {LoaderOverlay} from '../../../components/loader-overlay/loader-overlay';
import {ColumnInterface, TypeColumnMenu} from '../../../components';
import {NODE_FILTER} from '../../component/node-item/node-item.interface';
import ObjectHelper from '../../../helpers/object.helper';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {TextFilter} from '../../component/components/grid-header-filters/text-filter';
import {NodeFilter} from '../../component/components/grid-header-filters/node-filter';
import {CheckboxFilter, CheckboxFilterOption} from '../../component/components/grid-header-filters/type-filter-cell';
import {SpecTypeState} from '../../../store/slice/spec-type-slice';
import {DateFilter} from '../../component/components/grid-header-filters/date-filter';
import {UserState} from '../../../store/slice/user-slice';
import {RoleState} from '../../../store/slice/role-slice';
import {getTemplateGroups, TemplateState} from "../../../store/slice/template-slice";

interface SpecificationTemplateGridProps {
    searchParams: URLSearchParams;
    sort: SortDescriptor[];
    columns: ColumnInterface[];
    data: SpecTemplate[] | null;
    total: number;
    onChanges: (params: any) => void;
    onColumnsChange: (columns: ColumnInterface[]) => void;
    resetFilter: (field: string) => void;
}

const exceptionalFields: any[] = [];

const SpecificationTemplateGrid = ({
                                       searchParams,
                                       sort,
                                       columns,
                                       data,
                                       total,
                                       onChanges,
                                       onColumnsChange,
                                       resetFilter
                                   }: SpecificationTemplateGridProps) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {allSpecTypes} = useAppSelector<SpecTypeState>(store => store.specType);
    const {currentUser} = useAppSelector<UserState>(store => store.user);
    const {roleList} = useAppSelector<RoleState>(store => store.role);
    const {templateGroups} = useAppSelector<TemplateState>(store => store.template);
    const [role, setRole] = useState<Role>();

    useEffect(() => {
        setIsLoading(false);
    }, [data]);

    useEffect(() => {
        if (currentUser && Array.isArray(roleList)) {
            const role = roleList.find((r) => r.id === currentUser.role);
            const userRole = role ? role.name : Role.ROLE_SALESMAN;
            setRole(userRole);
        }

    }, [currentUser, roleList]);

    useEffect(() => {
        dispatch(getTemplateGroups());
    }, []);

    const onSort = (data: SortDescriptor[]): void => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        onChanges(setSortParam(data, searchParams as unknown as SearchParams));
    };

    const dateCell = (props: GridCellProps): ReactElement => {
        const dataItem: SpecTemplate = props.dataItem;
        return (
            <td>
                <div className="grid-cell">
                    <p>{format(parseISO(dataItem.created), DATE_TIME)}</p>
                </div>
            </td>
        );
    };

    const isAdmin = (): boolean => {
        return role === Role.ROLE_ADMIN;
    };

    const gridActions = (props: GridCellProps): ReactElement => {
        return <td style={{textAlign: 'right'}}>
            <TemplateActions template={props.dataItem}>
                <DropDownButtonItem text={SpecTemplateAction.create}/>
                <DropDownButtonItem text={SpecTemplateAction.rename}/>
                <DropDownButtonItem text={SpecTemplateAction.delete}/>
            </TemplateActions>
        </td>;
    };


    const handleReorderColumns = (event: GridColumnReorderEvent) => {
        const newOrder = [...event.columns];
        onChanges({
            ...Object.fromEntries(searchParams.entries()),
            'columns': newOrder.sort((a, b) => a.orderIndex! - b.orderIndex!).map(col => col.field).join(';'),
        });
    };


    const pageChange = (params: SearchParams): void => {
        onChanges(
            {
                ...Object.fromEntries(searchParams.entries()),
                offset: params.offset || '0',
                limit: params.limit || '50'
            });
    };


    const updateFilter = (field: string, filter: string, params: string | null) => {

        if( filter === params){
            return;
        }

        if (!!filter || filter === '') {
            if (field === 'typeId') {
                const params = ObjectHelper.pickParams(Object.fromEntries(searchParams.entries()), ['limit']) as URLSearchParams;
                onChanges(
                    {
                        ...Object.fromEntries(searchParams.entries()),
                        ...params,
                        offset: '0',
                        [field]: filter
                    });
            } else if (field === NODE_FILTER.expertMode) {
                onChanges({
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    [field]: filter.toLowerCase() === 'нет' ? false : true,
                });
            } else {
                onChanges({
                    ...Object.fromEntries(searchParams.entries()),
                    offset: '0',
                    [field]: filter,
                });
            }
        }
    };


    const typeCell = (props: GridCellProps): ReactElement => {
        if (!allSpecTypes) {
            return <></>;
        }
        const type = allSpecTypes.result.find(t => t.id === props.dataItem.typeId);
        return <td>
            <div className="grid-cell">
                <p>{type?.name || ''}</p>
                <span className={'version'}>v{type?.version}</span>
            </div>
        </td>;
    };

    const nameCell = (props: GridCellProps): ReactElement => {
        const dataItem: SpecInterface = props.dataItem;
        return <td>
            <div className="grid-cell" style={{paddingRight: '8px'}}>{dataItem.name}</div>
        </td>;
    };

    const getCell = (props: GridCellProps): ReactElement<HTMLTableCellElement> => {
        if (props.field === 'name') {
            return nameCell(props);
        }
        if (props.field === 'typeName') {
            return typeCell(props);
        }
        if (props.field === 'created') {
            return dateCell(props);
        }

        return <GridCell {...props}/>;
    };


    const typeSelectCell = (props: GridHeaderCellProps): ReactElement<any> => {
        const search = searchParams.get('typeId');
        let options: CheckboxFilterOption[] = [];
        if (allSpecTypes && Array.isArray(allSpecTypes.result)) {
            options = [...allSpecTypes.result]
                .sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
                .map((v) => {
                    const current = allSpecTypes.result.filter((o) => o.code === v.code);
                    const multiple = Array.isArray(current) && current.length > 1;
                    return {
                        name: v.name,
                        value: v.id,
                        version: multiple ? v.version : undefined,
                        checked: false
                    };
                });
        }
        return <div>
            <CheckboxFilter {...props}
                            options={options}
                            selected={search}
                            onFilter={(filter) => updateFilter('typeId', filter, search)}
                            onReset={() => resetFilter('typeId')}
            />
        </div>;
    };

    const textSearchCell = (props: any): ReactElement<any> => {
        const search = searchParams.get('name');
        return <div>
            <TextFilter
                {...props}
                onFilter={(filter) => updateFilter('name', filter, search)}
                selected={search}
                onReset={() => resetFilter('name')}
            />
        </div>;
    };

    const authorCell = (props: any): ReactElement<any> => {
        const search = searchParams.get('author');
        return <div>
            <TextFilter
                {...props}
                onFilter={(filter) => updateFilter('author', filter, search)}
                selected={search}
                onReset={() => resetFilter('author')}
            />
        </div>;
    };

    const dateSearchCell = (props: any, field: NODE_FILTER): ReactElement<any> => {
        const search = searchParams.get(field);
        return <div>
            <DateFilter
                {...props}
                onFilter={(filter) => updateFilter(field, filter, search)}
                selected={search}
                onReset={() => resetFilter(field)}
            />
        </div>;
    };

    const groupCell = (props: any): ReactElement<any> => {
        const search = searchParams.get('groupName');
        let options: CheckboxFilterOption[] = [];
        if (Array.isArray(templateGroups)) {
            options = [...templateGroups].sort().map((v) => {
                return {
                    name: v || '- без группы -',
                    value: v,
                    checked: false
                }
            })

        }
        return <div>
            <CheckboxFilter
                {...props}
                options={options}
                onFilter={(filter) => updateFilter('groupName', filter, search)}
                selected={search}
                onReset={() => resetFilter('groupName')}
            />
        </div>;
    };

    const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
        switch (column.type) {
            case 'type':
                return <>{typeSelectCell(props)}</>;
            case 'name':
                return <>{textSearchCell(props)}</>;
            case 'group':
                return <>{groupCell(props)}</>;
            case 'author':
                return <>{authorCell(props)}</>;
            case 'created':
                return <>{dateSearchCell(props, NODE_FILTER.created)}</>;
            default:
                return <div>
                    <NodeFilter {...props}
                                onFilter={(field, filter) => updateFilter(field, filter, null)}
                                column={column}
                                onReset={resetFilter}
                                ignoreSearchType={exceptionalFields.includes(column.searchField || '')}
                    />
                </div>;
        }
        return null;
    };

    return (
        <AppSuspense condition={!!allSpecTypes}>
            <Card className="h100">
                <CardBody>
                    {(isLoading) && (
                        <LoaderOverlay/>
                    )}
                    <div className={'universal-grid'}>
                        <Grid
                            className={'templates-grid'}
                            style={{width: '100%'}}
                            sortable={true}
                            sort={sort}
                            data={data ?? []}
                            onSortChange={(e: GridSortChangeEvent) => {
                                onSort(e.sort);
                            }}
                            reorderable={true}
                            resizable={true}
                            rowHeight={32}
                            onColumnReorder={handleReorderColumns}

                        >
                            {columns.map((col, idx) => col.show && (
                                <GridColumn
                                    key={idx}
                                    field={col.field}
                                    title={col.title}
                                    orderIndex={idx}
                                    sortable={col.sortable}
                                    cell={(props) => getCell(props)}
                                    width={col.width || 'auto'}
                                    minResizableWidth={100}
                                    headerCell={(props) => getHeaderCell(props, col)}
                                />
                            ))}
                            <GridColumn
                                field=""
                                title=""
                                width={100}
                                cell={gridActions}
                                headerClassName={'actions-menu'}
                                orderIndex={columns.length + 1}
                                locked={true}
                                reorderable={false}
                                resizable={false}
                                headerCell={() => (
                                    <TypeColumnMenu
                                        columns={columns}
                                        searchParams={searchParams}
                                        onFilter={(filter: string) => updateFilter('columns', filter, null)}
                                        onColumnsChange={onColumnsChange}
                                        type={'templates'}
                                    />
                                )}
                            />
                            <NoRecords>
                                {!!data && !data?.length && ('Список пуст.')}
                                {!data && ('Загрузка данных...')}
                            </NoRecords>
                        </Grid>
                    </div>
                    <div className="spec-pager-wrapper">
                        <div className="page-info">

                        </div>
                        <div className="spec-pager">
                            <AppPagination params={{
                                offset: searchParams.get('offset') || undefined,
                                limit: searchParams.get('limit') || undefined,
                            }} total={total}
                                           onPageChange={pageChange}
                                           showButtons={true}
                                           pageSizes={[10, 50, 100, 200]}
                            />

                        </div>
                        <div className="download">

                        </div>
                    </div>
                </CardBody>
            </Card>
        </AppSuspense>

    );
};

export {SpecificationTemplateGrid};
