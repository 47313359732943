import {ColumnInterface, TypeColumnMenu} from '../index';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Grid, GridCellProps, GridColumn, GridColumnReorderEvent, GridSortChangeEvent} from '@progress/kendo-react-grid';
import {GridNoRecords as NoRecords} from '@progress/kendo-react-grid/dist/npm/GridNoRecords';
import {AppPagination} from '../app-pagination/app-pagination';
import {AppSuspense} from '../app-suspense/app-suspense';
import React, {ReactElement} from 'react';
import {SortDescriptor} from '@progress/kendo-data-query';
import {useSearchParams} from 'react-router-dom';
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import {SearchParams} from '../../interface';
import {DEFAULT_PAGE_SIZE, PAGE_SIZE} from '../../constants';

export interface CommonGrid {
    columns: ColumnInterface[];
    data: any[] | null;
    total: number;
    sort: SortDescriptor[];
    onSort: (data: SortDescriptor[]) => void;
    onRowClick: (data: any) => void;
    gridActions: (props: GridCellProps) => ReactElement;
    onUpdateFilter: (field: string, filter: string) => void;
    exportLink?: string;
}


const CommonGrid = ({
                        columns,
                        data,
                        total,
                        onSort,
                        sort,
                        onRowClick,
                        gridActions,
                        onUpdateFilter,
                        exportLink
                    }: CommonGrid) => {

    const [searchParams, setSearchParams] = useSearchParams();


    const handleReorderColumns = (event: GridColumnReorderEvent) => {
        const newOrder = [...event.columns];
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            'columns': newOrder.sort((a, b) => a.orderIndex! - b.orderIndex!).map(col => col.field).join(';'),
        }, {replace: true});
    };

    const getHeaderCell = (props: GridHeaderCellProps, column: ColumnInterface) => {
        if (!!column.headerCell) {
            return column.headerCell?.(props);
        }
        return null;
    };


    const onColumnsChange = (newColumns: ColumnInterface[]) => {
        const columnsParam = searchParams.get('columns');
        let sortedColumns: ColumnInterface[] = [];
        let defaultCols: ColumnInterface[] = [];
        if (columnsParam) {
            const columnsParamsArr = columnsParam.split(';');
            const idCol = newColumns.find(col => col.field === 'id');
            if (idCol && !columnsParamsArr.includes('id')) {
                sortedColumns[0] = idCol;
                defaultCols = newColumns.filter(col => col.field !== 'id' && columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            } else if (idCol) {
                defaultCols = newColumns.filter(col => columnsParamsArr.includes(col.field!)).sort((a, b) => columnsParamsArr.indexOf(a.field!) - columnsParamsArr.indexOf(b.field!));
            }
            const others: ColumnInterface[] = newColumns.filter(col => defaultCols.every(c => c.field !== col.field)) || [];

            sortedColumns = [...sortedColumns, ...defaultCols, ...others];
            setSearchParams({
                ...Object.fromEntries(searchParams.entries()),
                'columns': sortedColumns.filter(col => col?.show).map(col => col?.field).join(';'),
            }, {replace: true});
        }
    };

    const handlePageChange = (params: SearchParams): void => {
        setSearchParams(
            {
                ...Object.fromEntries(searchParams.entries()),
                offset: params.offset || '0',
                limit: params.limit || DEFAULT_PAGE_SIZE.toString(),
            });
    };

    return (
        <AppSuspense condition={!!columns}>
            <Card>
                <CardBody>
                    <div className={'universal-grid'}>
                        <Grid
                            className={'node-grid'}
                            style={{height: 'calc(100vh - 274px)', width: '100%'}}
                            sortable={true}
                            onSortChange={(e: GridSortChangeEvent) => {
                                onSort(e.sort);
                            }}
                            sort={sort}
                            data={data ?? []}
                            reorderable={true}
                            resizable={true}
                            onRowClick={onRowClick}
                            onColumnReorder={handleReorderColumns}
                        >
                            {columns.map((col, idx) => col.show && (
                                <GridColumn
                                    key={idx}
                                    orderIndex={idx}
                                    field={col.field}
                                    title={col.title?.toString()}
                                    sortable={col.sortable}
                                    width={col.width || 'auto'}
                                    headerCell={(props) => getHeaderCell(props, col)}
                                    minResizableWidth={100}
                                />
                            ))}
                            <GridColumn field="" title="" width={100}
                                        orderIndex={columns.length + 1}
                                        headerClassName={'actions-menu'}
                                        reorderable={false}
                                        resizable={false}
                                        locked={true}
                                        cell={gridActions}
                                        headerCell={() => (
                                            <TypeColumnMenu
                                                columns={columns}
                                                searchParams={searchParams}
                                                onFilter={(filter: string) => onUpdateFilter('columns', filter)}
                                                onColumnsChange={onColumnsChange}
                                                exportLink={exportLink}

                                            />
                                        )}/>


                            <NoRecords>
                                {!!data && !data?.length && ('Список пуст.')}
                                {!data && ('Загрузка данных...')}
                            </NoRecords>

                        </Grid>
                    </div>
                    <div className="node-pager-wrapper">
                        <div className="page-info">

                        </div>
                        <div className="node-pager">
                            <AppPagination params={{
                                offset: searchParams.get('offset') || undefined,
                                limit: searchParams.get('limit') || undefined,
                            }} total={total}
                                           onPageChange={handlePageChange}
                                           showButtons={true}
                                           pageSizes={PAGE_SIZE}
                            />

                        </div>
                        <div className="download">

                        </div>
                    </div>
                </CardBody>
            </Card>
        </AppSuspense>
    );
};

export {CommonGrid};
