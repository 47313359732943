import {
    ApiChangeCurrencyRateRequest,
    SpecItemValidationItem,
    SpecItemValidationItemType
} from '../../../../../../../interface';
import React, {useMemo} from 'react';
import {SpecificationItemPrice} from '../../../../components/price/specification-item-price';
import {SpecificationStatus} from '../../specification-data-input.interface';

interface CalculationHelper {
    updateRate: (data: ApiChangeCurrencyRateRequest) => void;
    messages: SpecItemValidationItem[];
    status: SpecificationStatus;
    disabled: boolean
}

const CalculationHelper = ({
                               updateRate,
                               messages,
                               status,
                               disabled
                           }: CalculationHelper) => {

    const errors = useMemo((): SpecItemValidationItem[] => {
        return messages.filter((m) => m.type === SpecItemValidationItemType.error);
    }, [messages]);

    const isError = useMemo(() => {
        return status === SpecificationStatus.error;
    }, [status]);

    const isEmpty = useMemo(() => {
        return status === SpecificationStatus.empty;
    }, [status]);

    return (
        <>
            {isEmpty && (
                <div className="info__status__helper empty">Добавьте все обязательные параметры (отмечены
                    звездочкой
                    *) для
                    расчета</div>
            )}

            {isError && (
                <div className="info__status__helper error">
                    <ul>
                        {errors.map((e, i) => (
                            <li key={i}>{e.text}</li>
                        ))}
                    </ul>
                </div>
            )}
            <SpecificationItemPrice onRateUpdate={updateRate} disabled={disabled}/>
        </>
    );
};

export {CalculationHelper};
