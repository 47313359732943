import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import baseRequest from '../../utils/base-request';
import {API_ADMIN_NODE, API_ADMIN_NODE_TRAITS} from '../../pages/admin/admin.meta';
import {AdminNode, ApiAdminNodeResult} from '../../interface/admin/admin-node.interface';
import {AdminTrait, ApiAdminTraitsResult} from '../../interface/admin/admin-traits.interface';

export interface AdminState {
    nodeList?: AdminNode[] | null;
    traitList?: AdminTrait[] | null;

}


export const getAllNodes = createAsyncThunk(
    'admin/getAllNodes',
    async function (_, {rejectWithValue, dispatch}) {
        try {
            const response = await baseRequest({
                method: 'get',
                url: API_ADMIN_NODE,
            }, dispatch);

            return response?.data;
        }catch (error) {
            return rejectWithValue(error);
        }

    }
);

export const getAllTraits = createAsyncThunk(
    'admin/getAllTraits',
    async function (_, {rejectWithValue, dispatch}) {
        try {
            const response = await baseRequest({
                method: 'get',
                url: API_ADMIN_NODE_TRAITS,
            }, dispatch);

            return response?.data;
        }catch (error) {
            return rejectWithValue(error);
        }
    }
);




const initialState: AdminState = {
    nodeList: null,
    traitList: null
};

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        clearNodes(state){
            state.nodeList = null;
        },
        clearTraits(state){
            state.traitList = null;
        }
    },
    extraReducers: {
        [getAllNodes.fulfilled as any]: (state: AdminState, action: PayloadAction<ApiAdminNodeResult>) => {
            state.nodeList = action.payload.result;
        },
        [getAllTraits.fulfilled as any]: (state: AdminState, action: PayloadAction<ApiAdminTraitsResult>) => {
            state.traitList = action.payload.result;
        }
    }
});

export const {clearNodes, clearTraits} = adminSlice.actions;

export default adminSlice.reducer;
