import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {Grid, GridCellProps, GridColumn, GridSortChangeEvent} from "@progress/kendo-react-grid";
import {ColumnInterface, TypeColumnMenu} from "../../../../../components";
import {GridNoRecords as NoRecords} from "@progress/kendo-react-grid/dist/npm/GridNoRecords";
import React, {ReactElement, SyntheticEvent, useEffect, useState} from "react";
import {useAppSelector} from "../../../../../hook/store";
import {NodeState} from "../../../../../store/slice/node-slice";
import {ApiNodeItemConstraint} from "../../../../../interface";
import {GridRowClickEvent} from "@progress/kendo-react-grid/dist/npm/interfaces/events";
import {ComponentConstraintAction} from "../component-item-constraint.interface";

import {MenuActions} from "../../../../../components/menu-actions/menu-actions";
import {DropDownButtonItem} from "@progress/kendo-react-buttons";
import {MenuSelectEvent} from "@progress/kendo-react-layout/dist/npm/menu/events";
import {ItemComponentActions} from "../../component-item-links/component-item-links.interface";
import {ConstraintType} from "../constraint-dialog/constraint-dialog.meta";
import {componentItemConstraintColumns} from "./constraint-grid.meta";
import {ReactComponent as IconFunction} from "../../../../../assets/img/icon/function.svg";

interface ConstraintGridProps {
    readonly: boolean;
    onAction: (action: MenuSelectEvent, data: ApiNodeItemConstraint, constraintId?: string) => void;
}

const ConstraintGrid = ({readonly, onAction}: ConstraintGridProps) => {

    const [columns, setColumns] = useState<ColumnInterface[]>([]);
    const [gridData, setGridData] = useState<ApiNodeItemConstraint[]>([]);
    const [sort, setSort] = useState<SortDescriptor[]>([]);
    const {currentNodeItem} = useAppSelector<NodeState>((store) => store.node);


    useEffect(() => {
        if (currentNodeItem?.constraints) {
            setGridData(Object.values(currentNodeItem.constraints));
        }
    }, [currentNodeItem])

    const handleRowClick = (event: GridRowClickEvent) => {
        onAction({item: {text: ItemComponentActions.edit}} as MenuSelectEvent, event.dataItem);
    };

    const handleNameClick = (event: SyntheticEvent, item: ApiNodeItemConstraint) => {
        onAction({item: {text: ItemComponentActions.edit}} as MenuSelectEvent, item);
    };

    const gridActions = ({dataItem, className, style}: GridCellProps): ReactElement => {
        return <td style={{...style, textAlign: 'right'}} className={className}>
            <MenuActions onAction={(action) => onAction(action, dataItem)}>
                {!readonly && (
                    <DropDownButtonItem key={ComponentConstraintAction.edit} text={ComponentConstraintAction.edit}/>)}
                {!readonly && (<DropDownButtonItem key={ComponentConstraintAction.delete}
                                                   text={ComponentConstraintAction.delete}/>)}
            </MenuActions>
        </td>;
    };

    const onColumnsChange = (columns: ColumnInterface[]) => {
        setColumns(columns);
    };


    const typeCell = ({dataItem}: GridCellProps): ReactElement => {
        const text= (ConstraintType as any)[dataItem.type];
        return <td onClick={(e => handleNameClick(e, dataItem))}>
            <span>{text}</span>
        </td>;
    };

    const valueCell = ({dataItem}: GridCellProps): ReactElement => {
        const isFormula = !!dataItem.activationPredicate;
        const value = dataItem.activationPredicate;
        return <td title={isFormula ? dataItem.activationPredicate : ''} onClick={(e => handleNameClick(e, dataItem))}>
            <span>
                {isFormula && <span><IconFunction style={{verticalAlign: 'middle'}}/> = </span>}
                {value || '-'}
            </span>
        </td>;
    };

    const predicateCell = ({dataItem}: GridCellProps): ReactElement => {
        const isFormula = !!dataItem.consistency.predicate;
        const value = dataItem.consistency.predicate;
        return <td title={isFormula ? dataItem.consistency.predicate : ''} onClick={(e => handleNameClick(e, dataItem))}>
            <span>
                {isFormula && <span><IconFunction style={{verticalAlign: 'middle'}}/> = </span>}
                {value || '-'}
            </span>
        </td>;
    };

    useEffect(() => {
        const columnsSet: ColumnInterface[] = componentItemConstraintColumns(typeCell, valueCell, predicateCell);
        setColumns([...columnsSet]);

    }, [ currentNodeItem])



    return (
        <Grid
            style={{height: '100%', width: '100%'}}
            scrollable={'scrollable'}
            data={orderBy(gridData, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
            }}
            rowHeight={64}
            onRowClick={handleRowClick}
        >

            {columns.map((col, idx) => col.show && (
                <GridColumn
                    key={idx}
                    field={col.field}
                    title={col.title}
                    sortable={col.sortable}
                    cell={col.cell}
                    width={col.width || 'auto'}
                />
            ))}

            <GridColumn title="" width={100} cell={gridActions}
                        headerClassName={'actions-menu'}
                        reorderable={false}
                        locked={true}
                        headerCell={() => (
                            <TypeColumnMenu
                                columns={columns}
                                onColumnsChange={onColumnsChange}/>
                        )}
            />
            <NoRecords>
                Список пуст.
            </NoRecords>
        </Grid>
    );
};

export {ConstraintGrid};
