import styles from './loader-overlay.module.scss';
import {AppLoader} from '../app-loader/app-loader';

const LoaderOverlay = () => {
    return (
        <div className={styles.loader_overlay} onClick={(e) => {e.stopPropagation()}}>
            <AppLoader/>
        </div>
    );
};

export {LoaderOverlay};
