import {useEffect, useState} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {JsonForms} from '@jsonforms/react';
import {useForm} from 'react-hook-form';
import {FormControl, TextField} from '@mui/material';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {showSuccess} from '../../../../store/slice/toast-slice';
import {AppSuspense} from '../../../../components/app-suspense/app-suspense';
import {ApiDictionaryUpdate} from '../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import './dictionary-item-props.scss';
import {DictionaryState, updateDictionary} from '../../../../store/slice/dictionary-slice';
import {DictionaryTypeState} from '../../../../store/slice/dictionary-type-slice';
import {SplitLoaderButton} from '../../../../ui/button/split-loader-button/split-loader-button';
import {ROUTE_PATH} from '../../../../constants/routes';
import {JsonFormsGenerateUISchema, translateError, translation} from "../../../../helpers";
import {ErrorObject} from "ajv";
import {UISchemaElement} from "@jsonforms/core";


interface PropsDataItem {
    title: string;
    value: any;
}

const DictionaryItemProps = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currentDictionary} = useAppSelector<DictionaryState>(store => store.dictionary);
    const {allDictionaryTypes} = useAppSelector<DictionaryTypeState>(store => store.dictionaryType);
    const [data, setData] = useState<PropsDataItem[]>([]);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const { jsonSchema } = useOutletContext() as any;
    const [formData, setFormData] = useState<any>();
    const {
        register,
        getValues,
        formState: {errors, isValid},
    } = useForm({
        mode: 'all',
    });


    useEffect(() => {
        if(Array.isArray(allDictionaryTypes) && !!currentDictionary){
            const nodeType = allDictionaryTypes.find(n => n.id === currentDictionary.type);

            if (!!nodeType?.typeSchema?.properties && !!currentDictionary) {
                const props = Object.entries(nodeType.typeSchema.properties);
                const data: PropsDataItem[] = props.map(([k, v]) => (
                    {
                        title: v?.title || '-',
                        value: currentDictionary.data[k] || '-'
                    }
                ));
                setData(data);
            }
        }
    }, [currentDictionary, allDictionaryTypes]);

    const handleSave = (redirect = false) => {
        if (!!formData?.errors?.length || !currentDictionary) {
            return;
        }
        if (!isValid) {
            return;
        }
        const nameValue = getValues().componentName;
        const data: ApiDictionaryUpdate = {
            id: currentDictionary.id,
            formData: {
                data: formData.data,
                name: nameValue
            }
        };

        setInProgress(true);

        dispatch(updateDictionary(data)).unwrap()
            .then(() => {
                const text = 'Данные успешно обновлены.';
                dispatch(showSuccess(text));
                if (redirect) {
                    navigate(`/${ROUTE_PATH.dictionary}/type?type=${currentDictionary.type}`);
                }
            }).finally(() => setInProgress(false));
    };

    const handleFormChange = (data: any) => {
        setFormData(data);
    };



    return (
        <AppSuspense condition={!!data?.length}>
            <Card style={{marginBottom: '32px'}}>
                <CardBody>
                    <div className="dictionary-item__props-title">Свойства</div>
                    <div className="dictionary-item__form">
                        <form style={{width: '100%', marginBottom: '1.5em'}}>
                            <FormControl error={!!errors?.componentName} sx={{width: '100%'}}>
                                <TextField
                                    variant={'standard'}
                                    label={'Название'}
                                    required
                                    defaultValue={currentDictionary?.name || ''}
                                    error={!!errors?.componentName}
                                    helperText={errors?.componentName ? errors?.componentName.message as string : ''}
                                    {...register('componentName', {
                                        required: 'Обязательное поле',
                                        ...currentDictionary?.name && {'value': currentDictionary?.name}
                                    })}
                                />
                            </FormControl>
                        </form>

                        {jsonSchema && (
                            <JsonForms

                                data={currentDictionary?.data}
                                schema={jsonSchema}
                                // uischema={uiSchema}
                                renderers={[...materialRenderers]}
                                cells={materialCells}
                                onChange={handleFormChange}
                                i18n={{locale: 'ru', translate: translation, translateError: translateError}}

                            />
                        )}
                    </div>
                    <SplitLoaderButton
                        items={['Сохранить и выйти']}
                        inProgress={inProgress}
                        disabled={!!formData?.errors?.length || inProgress}
                        onButtonClick={() => handleSave()}
                        onItemClick={() => handleSave(true)}
                    />
                </CardBody>
            </Card>
        </AppSuspense>
    );
};

export {DictionaryItemProps as default};
