import React, {useState} from "react";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {ApiNodeItem, ApiNodeItemConstraint, NodeItemStatus} from "../../../../interface";
import {useAppDispatch, useAppSelector} from "../../../../hook/store";
import {NodeState, updateCurrentNodeItem} from "../../../../store/slice/node-slice";
import {ConstraintDialog} from "./constraint-dialog/constraint-dialog";
import {ConstraintGrid} from "./constraint-grid/constraint-grid";
import {MenuSelectEvent} from "@progress/kendo-react-layout/dist/npm/menu/events";
import {ComponentConstraintAction} from "./component-item-constraint.interface";
import {AppPrompt} from "../../../../components/app-prompt/app-prompt";

const ComponentItemConstraint = () => {

    const dispatch = useAppDispatch();
    const {currentNodeItem} = useAppSelector<NodeState>((store) => store.node);
    const [constraintDialog, setConstraintDialog] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<ApiNodeItemConstraint | null>(null);
    const [updateData, setUpdateData] = useState<ApiNodeItem | null>(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const isReadOnly = (): boolean => {
        return currentNodeItem?.status !== NodeItemStatus.draft;
    };

    const updateNodeItem = (data: ApiNodeItem) => {
        dispatch(updateCurrentNodeItem(data));
        setConstraintDialog(false);
    };

    const openConstraintDialog = () => {
        setSelectedItem(null)
        setConstraintDialog(true);
    };

    const deleteConstraint = (data: ApiNodeItemConstraint) => {
        if (currentNodeItem && data) {
            const constraints = {...currentNodeItem.constraints};
            delete constraints[data.id];
            setUpdateData({
                ...currentNodeItem,
                constraints,
            });
            setShowConfirm(true);
        }
    };

    const handleAction = (
        action: MenuSelectEvent,
        data: ApiNodeItemConstraint,
    ) => {
        switch (action?.item?.text) {
            case ComponentConstraintAction.edit:
                setSelectedItem(data);
                setConstraintDialog(true);
                break;
            case ComponentConstraintAction.delete:
                deleteConstraint(data);
                break;
        }
    };

    const handleConfirmClose = (): void => {
        setShowConfirm(false);
        setUpdateData(null);
    };

    const confirmConstraintDelete = (): void => {
        if (updateData) {
            dispatch(updateCurrentNodeItem(updateData));
            setConstraintDialog(false);
            setSelectedItem(null);
            setShowConfirm(false);
            setUpdateData(null);
        }
    };

    return (
        <>
            <div className={"linked-components-header"}>
                <div className="title">
                    Ограничения
                </div>
                <div className="actions">
                    {!isReadOnly() && (
                        <Button variant={"outlined"} onClick={openConstraintDialog}>
                            <AddIcon/> Добавить Ограничение
                        </Button>
                    )}
                </div>
            </div>

            <div className={"component-links-grid"}>
                <ConstraintGrid
                    readonly={isReadOnly()}
                    onAction={handleAction}
                />
            </div>

            {constraintDialog && (
                <ConstraintDialog
                    handleClose={() => setConstraintDialog(false)}
                    readonly={isReadOnly()}
                    handleUpdate={updateNodeItem}
                    constraintData={selectedItem}
                />
            )}

            {showConfirm && (
                <AppPrompt
                    data={{
                        title: "Подтвердите действие",
                        message: ["Подтвердите удаление ограничения"],
                        confirmButton: "Удалить",
                        buttonError: true,
                    }}
                    onClose={handleConfirmClose}
                    onConfirm={confirmConstraintDelete}
                />
            )}

        </>
    );
};

export {ComponentItemConstraint};
