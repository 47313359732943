import {Outlet, useNavigate} from 'react-router-dom';
import {Sidebar} from '../../sidebar/sidebar';
import './cabinet-layout.scss';
import {Suspense, useEffect, useState} from 'react';
import {AppLoader} from '../../app-loader/app-loader';
import {useAppDispatch, useAppSelector} from '../../../hook/store';
import {getCurrentUser, UserState} from '../../../store/slice/user-slice';
import {AppTitle} from '../../app-title/app-title';
import {getSettings, SettingsState} from '../../../store/slice/settings-slice';
import {login} from '../../../store/slice/auth-slice';
import {ROUTE_PATH} from "../../../constants/routes";

export enum SidebarState {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}

const CabinetLayout = () => {
  const getSidebarState = localStorage.getItem('sidebarState');
  const [sidebarState, setSidebarState] = useState(getSidebarState ?? SidebarState.EXPANDED);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const {settings} = useAppSelector<SettingsState>(state => state.settings);
  const {currentUser} = useAppSelector<UserState>(state => state.user);

  const handleChangeSidebarState = () => {
    if (sidebarState === SidebarState.EXPANDED) {
      setSidebarState(SidebarState.COLLAPSED);
      localStorage.setItem('sidebarState', SidebarState.COLLAPSED);
    } else {
      setSidebarState(SidebarState.EXPANDED);
      localStorage.setItem('sidebarState', SidebarState.EXPANDED);
    }
  };

  const isExpanded = sidebarState === SidebarState.EXPANDED;

  useEffect(() => {
    if (!settings) {
      dispatch(getSettings());
    }
    if (!currentUser) {
      dispatch(getCurrentUser({isLogin: false}));
    }

    if (!!settings && !!currentUser) {
      if (Array.isArray(currentUser?.roles) && !currentUser?.roles.length) {
        navigate(ROUTE_PATH.loginError, {replace: true});
      } else {
        dispatch(login());
        setAuthChecked(true);
      }
    }
  }, [dispatch, settings, currentUser]);

  const isReady = (): boolean => {
    return (authChecked && Array.isArray(currentUser?.roles) && !!currentUser?.roles.length);
  };

  return (
    <>
      {isReady() && (
        <>
          <AppTitle/>
          <div className={`${isExpanded ? 'cabinet' : 'cabinet collapsed'}`}>
            <div className={`${isExpanded ? 'cabinet__sidebar' : 'cabinet__sidebar collapsed'}`}>
              <Sidebar isExpanded={isExpanded} handleChangeSidebarState={handleChangeSidebarState}/>
            </div>
            <div className={isExpanded ? 'cabinet__target' : 'cabinet__target collapsed'}>
              <Suspense fallback={<AppLoader/>}>
                <Outlet/>
              </Suspense>
            </div>
          </div>
        </>
      )}
    </>
  );

};

export {CabinetLayout};
