import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {
    getSpecification,
    setCurrentSpecState,
    SpecificationState,
    updateCurrencyRate,
    updateSpecificationForms
} from '../../../../../store/slice/spec-slice';
import {useNavigate, useParams} from 'react-router-dom';
import {AppSuspense} from '../../../../../components/app-suspense/app-suspense';
import {SpecificationTabWrapper} from '../tab-header/specification-tab-wrapper';
import {JsonForms} from '@jsonforms/react';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {
    ApiChangeCurrencyRateRequest,
    SpecFormsRequest,
    SpecificationItemInterface,
    SpecificationItemTab,
    SpecificationItemTabForm,
    SpecificationItemTabFormData,
    SpecItemStatus,
    SpecItemValidationItem,
    SpecItemValidationItemType
} from '../../../../../interface';
import AsyncSelectControl, {
    asyncSelectTester
} from '../../../../../ui/json-form-renderers/async-select-renderer/async-select-control';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';
import {SpecificationItemTabs} from '../../components/tabs/specification-item-tabs';
import {Card, CardBody} from '@progress/kendo-react-layout';
import '../../specification-item.scss';
import {SpecificationStatus, SpecificationStatusTitle} from './specification-data-input.interface';
import {StatusMessage} from '../../../../../components/status-message/status-message';
import {ScrollToTop} from '../../../../../ui/scroll-to-top/scroll-to-top';
import {getLastCurrencyRate} from '../../../../../store/slice/settings-slice';
import Ajv from 'ajv';
import {showError} from '../../../../../store/slice/toast-slice';
import {translation} from '../../../../../helpers';
import ArrayLayout, {
    arrayLayoutTester
} from '../../../../../ui/json-form-renderers/array-layout-renderer/ArraLayoutRenderer';
import TextControl, {
    textControlTester
} from '../../../../../ui/json-form-renderers/input-control-renderers/TextControl';
import IntegerControl, {
    integerControlTester
} from '../../../../../ui/json-form-renderers/input-control-renderers/IntegerControl';
import NumberControl, {
    numberControlTester
} from '../../../../../ui/json-form-renderers/input-control-renderers/NumberControl';
import axios from 'axios';
import {CalculationButtons} from './sections/calculation/calculation-buttons';
import {CalculationHelper} from './sections/calculation/calculation-helper';
import {ROUTE_PATH} from '../../../../../constants/routes';


const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    strictSchema: 'log',
    strict: false,
});

const SpecificationDataInput = () => {
    const urlParams = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currentSpec, specificationFormData} = useAppSelector<SpecificationState>(store => store.spec);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formsError, setFormsError] = useState<boolean>(false);
    const [formDataError, setFormDataError] = useState<boolean>(false);
    const [errorForms, setErrorForms] = useState<{ [key: string]: boolean }>({});
    const [tabForms, setTabForms] = useState<SpecificationItemTabFormData>({});
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [sticky, setSticky] = useState<boolean>(false);
    const [status, setStatus] = useState<SpecificationStatus>(SpecificationStatus.empty);
    const [specificationItem, setSpecificationItem] = useState<SpecificationItemInterface>({
        tabs: []
    });

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [isCancelling, setIsCancelling] = useState<boolean>(false);

    const [currencyUpdate, setCurrencyUpdate] = useState<boolean>(false);
    const [shouldRecalculate, setShouldRecalculate] = useState<boolean>(false);

    const tabsRef = useRef<HTMLDivElement | null>(null);

    const renderers = [...materialRenderers,
        {tester: asyncSelectTester, renderer: AsyncSelectControl},
        {tester: formulaInputTester, renderer: FormulaInputRenderer},
        {tester: arrayLayoutTester, renderer: ArrayLayout},
        {tester: textControlTester, renderer: TextControl},
        {tester: integerControlTester, renderer: IntegerControl},
        {tester: numberControlTester, renderer: NumberControl},
    ];

    const buildTabs = (): void => {
        if (currentSpec?.id && specificationFormData?.id === currentSpec.id && !isLoading) {
            setSpecificationItem(specificationFormData.data);
        } else {
            const tabs: SpecificationItemTab[] = [];
            if (!currentSpec?.meta?.formTabs || !currentSpec.doc.forms) {
                setFormsError(true);
                setIsReady(true);
                setSpecificationItem({tabs});
                return;
            }
            for (const item of currentSpec.meta.formTabs) {
                const tab: SpecificationItemTab = {} as SpecificationItemTab;
                tab.title = item.name;
                tab.type = 'form';
                tab.forms = [];
                tab.required = false;
                for (const form of item.forms) {
                    if (!currentSpec.meta.forms[form]) {
                        continue;
                    }
                    const schema = validateSchema(currentSpec.meta.forms[form].schema, form);
                    if (currentSpec.doc?.forms[form]?.visible) {
                        tab.forms.push({
                            key: form,
                            schema: schema,
                            ...currentSpec.meta.forms[form].uiSchema && {'uiSchema': currentSpec.meta.forms[form].uiSchema},
                            data: currentSpec.doc.forms[form].data || {}
                        });
                    }
                    if (Array.isArray(schema?.required) && schema?.required.length) {
                        tab.required = true;
                    }
                }
                if (!!tab.forms.length) {
                    const tabError = checkTabFormsError(tab.forms);
                    tab.filled = !tabError;
                    tab.error = tabError;
                    tabs.push(tab);
                }
            }
            setSpecificationItem({tabs});
        }
    };

    const checkTabFormsError = (forms: SpecificationItemTabForm[]): boolean => {
        let filled = true;
        for (const form of forms) {
            if (form.key === 'expertMode') {
                // we don't check expert mode form for errors here
                filled = filled && true;
            } else {
                filled = filled && checkRequiredFieldsFilled(form);
            }
        }
        return !filled;
    };

    const checkAllFormsError = (): boolean => {
        if (Array.isArray(specificationItem.tabs)) {
            for (const tab of specificationItem.tabs) {
                if (Array.isArray(tab.forms)) {
                    if (checkTabFormsError(tab.forms)) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const validateSchema = (schema: any, key: string) => {
        try {
            ajv.compile(schema);
            return schema;
        } catch (e: any) {
            dispatch(showError(`Form '${key}': ${e.message} `));
            return {};
        }
    };

    const handleFormChange = (key: string, formData: any): void => {
        if (!key) {
            return;
        }
        updateSpecItem(key, formData);
        if (!!formData.errors.length && key !== 'expertMode') {
            setErrorForms({...errorForms, [key]: true});
            return;
        } else {
            setErrorForms({...errorForms, [key]: false});
        }
        const next = {
            ...tabForms,
            [key]: {...formData.data}
        };
        setTabForms(next);
    };

    const updateSpecItem = (key: string, formData: any): void => {
        // const errors = Array.isArray(formData.errors) && !!formData.errors.length;
        const currentTabForms = specificationItem?.tabs[selectedTab].forms;
        if (currentTabForms && Array.isArray(currentTabForms)) {
            const form = currentTabForms.find(f => f.key === key);
            if (form) {
                const nextForms: SpecificationItemTabForm[] = currentTabForms.reduce((acc: SpecificationItemTabForm[], curr: SpecificationItemTabForm, i: number) => {
                    const next = {...curr};
                    if (next.key === key) {
                        next.data = formData.data;
                    }
                    acc[i] = next;
                    return acc;
                }, []);
                const nextSpecTabs = specificationItem.tabs.reduce((acc: SpecificationItemTab[], curr: SpecificationItemTab, index: number) => {
                    const next = {...curr};
                    if (index === selectedTab) {
                        next.forms = nextForms;
                        // next.error = errors;
                        // next.filled = !errors;
                    }
                    acc[index] = next;
                    return acc;
                }, []);
                setSpecificationItem({tabs: nextSpecTabs});

                dispatch(setCurrentSpecState({tabs: nextSpecTabs}));
                // if (currentSpec?.id) {
                //     dispatch(setSpecificationFormData({id: currentSpec.id, data: {tabs: nextSpecTabs}}));
                // }

            }
        }
    };


    const hasForms = (): boolean => {
        return !!specificationItem.tabs[selectedTab]?.forms?.length;
    };

    const isReadOnly = (): boolean => {
        return currentSpec?.status !== SpecItemStatus.draft || !!currentSpec?.masterSpecificationId;
    };

    const handleSelectTab = (tab: number): void => {
        checkTabFormsRequiredFields(specificationItem.tabs[selectedTab], selectedTab);
        setSelectedTab(tab);
    };

    const checkRequiredFieldsFilled = (form: SpecificationItemTabForm): boolean => {
        if (form) {
            if (errorForms[form.key]) {
                return false;
            }
            if (Array.isArray(form.schema?.required) && form.schema.required.length) {
                if (!form.data) {
                    return false;
                } else {
                    for (const field of form.schema.required) {
                        if (form.data[field] === undefined) {
                            return false;
                        }
                    }
                }

            }
        }
        return true;
    };

    const checkTabFormsRequiredFields = (tab: SpecificationItemTab, index: number) => {
        if (!tab) {
            return;
        }
        let tabError = false;
        if (tab.forms) {
            tabError = checkTabFormsError(tab.forms);
        }
        const nextSpecTabs = specificationItem.tabs.reduce((acc: SpecificationItemTab[], curr: SpecificationItemTab, index: number) => {
            const next = {...curr};
            if (index === selectedTab) {
                next.error = tabError;
                next.filled = !tabError;
            }
            acc[index] = next;
            return acc;
        }, []);
        setSpecificationItem({tabs: nextSpecTabs});
    };

    const handleScroll = () => {
        if (tabsRef) {
            setSticky(tabsRef?.current?.getBoundingClientRect()?.top === 0);
        }
    };


    const updateStatus = (): void => {
        if (Array.isArray(currentSpec?.doc?.validation?.messages)) {
            const errors = currentSpec?.doc.validation.messages.filter((m) => m.type === SpecItemValidationItemType.error);
            if (Array.isArray(errors) && errors.length) {
                setStatus(SpecificationStatus.error);
                return;
            }
        }
        if (!!currentSpec?.doc?.invoice?.isEmpty) {
            setStatus(SpecificationStatus.empty);
            return;
        }
        setStatus(SpecificationStatus.calculated);
    };

    const messages = useMemo((): SpecItemValidationItem[] => {
        return currentSpec?.doc?.validation?.messages || [];
    }, [currentSpec]);

    const isSpecStage = useMemo((): boolean => {
        return !!currentSpec?.masterSpecificationId;
    }, [currentSpec]);

    const updateRate = (data: ApiChangeCurrencyRateRequest) => {
        setCurrencyUpdate(true);
        dispatch(updateCurrencyRate(data)).unwrap()
            .finally(() => {
                setCurrencyUpdate(false);
            });
    };

    const handleRecalculate = (): void => {
        if (!currentSpec) {
            return;
        }
        setIsLoading(true);
        setStatus(SpecificationStatus.inProgress);
        if (currencyUpdate) {
            setShouldRecalculate(true);
            return;
        }
        const data: SpecFormsRequest = {
            id: currentSpec.id,
            forms: tabForms
        };
        setProgressPercentage(0);
        setIsCancelling(false);
        setInProgress(true);
        dispatch(updateSpecificationForms(data)).unwrap().then((v) => {
            if (!v.cancelled && urlParams.id) {
                setProgressPercentage(100);
                dispatch(getSpecification(urlParams.id)).unwrap()
                    .then(() => setIsLoading(false))
                    .catch(() => setIsLoading(false));
            }
        }).finally(() => {
            setIsLoading(false);
            setInProgress(false);
            setIsCancelling(false);
        });
    };

    const checkProgress = useMemo(
        () => {
            return () => {
                if (inProgress) {
                    axios.get(`/api/spec/${urlParams.id}/calculationStatus`)
                        .then((response) => {
                            if (response.data?.percentage) {
                                setProgressPercentage(response.data?.percentage);
                            }
                        });
                }
            };
        }, [inProgress]
    );

    const cancelRecalculation = (): void => {
        setIsCancelling(true);
        axios.put(`/api/spec/${urlParams.id}/cancelCalculation`)
            .finally(() => {
                updateStatus()
            });
    };

    const renderMessages = (): ReactElement | ReactElement[] => {
        const messages = currentSpec?.doc?.validation?.messages;
        if (Array.isArray(messages)) {
            const notError = messages.filter((m) => m.type !== SpecItemValidationItemType.error);
            if (Array.isArray(notError) && notError.length) {
                return notError.map(message => {
                    return <StatusMessage key={message.id} {...message}/>;
                });
            }
        }
        return <></>;
    };

    useEffect(() => {
        if (!currencyUpdate && shouldRecalculate) {
            setShouldRecalculate(false);
            handleRecalculate();
        }
    }, [currencyUpdate]);

    useEffect(() => {
        if (urlParams?.id && !!currentSpec?.id && urlParams.id === currentSpec?.id) {
            buildTabs();
            updateStatus();
            setIsReady(true);
        }
    }, [currentSpec]);


    useEffect(() => {
        dispatch(getLastCurrencyRate());
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const errors = Object.entries(errorForms).find(([key, value]) => {
            return key !== 'expertMode' && !!value;
        });
        setFormDataError(!!errors || checkAllFormsError());
    }, [errorForms, currentSpec]);

    const navigateToMaster = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(`/${ROUTE_PATH.specifications}/${currentSpec?.masterSpecificationId}/stages`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.specifications}/${currentSpec?.masterSpecificationId}/stages`);
        }
    }, [currentSpec]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkProgress();
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [inProgress]);

    return (
        <SpecificationTabWrapper>
            {!!currentSpec && (

                <AppSuspense condition={isReady}>
                    {!formsError ? (
                        <div className="target">
                            <div className="forms">
                                <Card style={{overflow: 'unset'}}>
                                    <CardBody>
                                        <div className={`tabs ${sticky ? 'sticky' : ''}`} ref={tabsRef}>
                                            <SpecificationItemTabs tabs={specificationItem.tabs}
                                                                   selected={selectedTab}
                                                                   onSelect={handleSelectTab}
                                            />
                                        </div>

                                        {
                                            hasForms()
                                                ? specificationItem.tabs[selectedTab].forms?.map((form, index) => (
                                                    <div className="form-wrapper" key={index}>
                                                        <JsonForms
                                                            readonly={isReadOnly()}
                                                            key={form.key}
                                                            schema={form.schema}
                                                            data={form.data}
                                                            uischema={form.uiSchema}
                                                            renderers={renderers}
                                                            cells={materialCells}
                                                            validationMode={'ValidateAndShow'}
                                                            onChange={(data) => handleFormChange(form.key, data)}
                                                            i18n={{locale: 'ru', translate: translation}}
                                                        />
                                                    </div>

                                                ))
                                                :
                                                <div className={'empty-result'}>параметры спецификации
                                                    недоступны</div>
                                        }

                                    </CardBody>
                                </Card>
                            </div>
                            <div className={'info'}>
                                <div className={'info__status'}>
                                    <div className={`title ${status}`}>
                                        {SpecificationStatusTitle[status]}
                                    </div>
                                    <CalculationHelper
                                        updateRate={updateRate}
                                        messages={messages}
                                        status={status}
                                        disabled={isSpecStage}
                                    />

                                    <CalculationButtons
                                        handleRecalculate={handleRecalculate}
                                        cancelRecalculation={cancelRecalculation}
                                        navigateToMaster={navigateToMaster}
                                        disabled={formDataError || isLoading || currentSpec.status !== SpecItemStatus.draft}
                                        inProgress={inProgress}
                                        isCancelling={isCancelling}
                                        progressPercentage={progressPercentage}
                                        isSpecStage={isSpecStage}
                                    />
                                </div>

                                {renderMessages()}

                            </div>

                        </div>
                    ) : (
                        <Card style={{marginBottom: '1rem', padding: '1rem'}}>
                            <CardBody>
                                <div style={{textAlign: 'center'}}>Неверный формат спецификации.</div>
                            </CardBody>
                        </Card>
                    )}
                </AppSuspense>
            )
            }
            <ScrollToTop/>
        </SpecificationTabWrapper>
    );
};

export {SpecificationDataInput};
