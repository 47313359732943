import {ColumnInterface} from '../../../components';

export const templatesColumns: ColumnInterface[] = [
    {
        title: 'Название',
        field: 'name',
        type: 'name',
        searchField: 'name',
        show: true,
        sortable: true,
        width: '400px'
    },
    {
        title: 'Тип',
        field: 'typeName',
        type: 'type',
        searchField: 'typeName',
        show: true,
        sortable: true,
        width: '150px'
    },
    {
        title: 'Группа',
        field: 'groupName',
        type: 'group',
        searchField: 'groupName',
        show: true,
        sortable: true,
        width: '200px'
    },
    {
        title: 'Автор',
        field: 'author',
        type: 'author',
        searchField: 'author',
        show: true,
        sortable: true,
        width: '200px'
    },
    {
        title: 'Дата',
        field: 'created',
        type: 'created',
        searchField: 'created',
        show: true,
        sortable: true,
    },
];
