import {ColumnInterface} from '../../../components';
import {AppBreadcrumb} from '../../../interface/breadcrumbs.interface';
import {ROUTE_PATH} from '../../../constants/routes';
import {AdminTraitSchema} from '../../../interface/admin/admin-traits.interface';

export const traitsListColumns: ColumnInterface[] = [
    {
        title: 'ID',
        field: 'id',
        type: 'string',
        searchField: 'id',
        sortable: true,
        show: true,
        width: '300px',
    },
    {
        title: 'Название',
        field: 'name',
        type: 'name',
        show: true,
        searchField: 'name',
        sortable: true,
        width: '300px',
    }
];


export const adminTraitsBreadcrumbs: AppBreadcrumb[] = [
    {
        title: 'Администрирование',
        path: `/${ROUTE_PATH.admin}`
    },
    {
        title: 'Характеристики',
        path: `/${ROUTE_PATH.adminNodeTraits}`
    }
];

export const adminTraitSchema: AdminTraitSchema = {
    id: '',
    name: '',
    data: {
        traitsName: '',
        typeSchema: {
            type: 'object',
            properties: {},
            required: []
        }
    }
};
