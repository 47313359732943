import React, {useCallback, useEffect, useMemo} from 'react';
import {Card, CardBody} from '@progress/kendo-react-layout';
import './specification-item-header.scss';
import {StatusBadge} from '../../../../../components/status-badge/status-badge';
import {SpecificationActions} from '../../../components/specification-actions/specification-actions';
import {Role, SpecItem} from '../../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {getSpecType, SpecTypeState} from '../../../../../store/slice/spec-type-slice';
import {NavLink, useParams, useSearchParams} from 'react-router-dom';
import {SpecificationStatus} from '../../tabs/data-input/specification-data-input.interface';
import {getUsers, UserState} from "../../../../../store/slice/user-slice";
import {RoleState} from "../../../../../store/slice/role-slice";

interface SpecificationItemHeaderProps {
    specificationItem: SpecItem;
    onAuthorUpdate: () => void;
    status?: SpecificationStatus;
}

const SpecificationItemHeader = ({specificationItem, onAuthorUpdate, status}: SpecificationItemHeaderProps) => {
    const dispatch = useAppDispatch();
    const {currentType} = useAppSelector<SpecTypeState>(store => store.specType);
    const {currentUser} = useAppSelector<UserState>((store) => store.user);
    const {roleList} = useAppSelector<RoleState>((store) => store.role);
    const [searchParams] = useSearchParams();
    const urlParams = useParams();


    useEffect(() => {
        if (currentUser && roleList) {
            const role = roleList.find((r) => r.id === currentUser.role)
            if (role && role.name === Role.ROLE_ADMIN) {
                dispatch(getUsers({}));
            }
        }
    }, [dispatch, currentUser, roleList]);

    const isEnabled = () => {
        return status === SpecificationStatus.calculated && !!specificationItem;
    };

    const handleActionUpdate = () => {
        onAuthorUpdate();
    };

    const isDebug = (): boolean => {
        return searchParams.get('debug') === 'true';
    };

    const linkTo = (url: string): string => {
        return isDebug() ? `${url}?debug=true` : url;
    };

    const withStages = useMemo((): boolean => {
        if(!specificationItem){
            return false;
        }
        return Array.isArray(specificationItem?.stages) && !!specificationItem.stages.length;
    }, [specificationItem]);

    const stageNumber = useMemo((): string | null => {
        if(!specificationItem){
            return null;
        }
        return withStages ? null : specificationItem?.stageNumber || null;
    }, [specificationItem]);

    const isReady = useMemo((): boolean => {
        return specificationItem?.id === urlParams.id;
    }, [specificationItem]);

    useEffect(() => {
        if (currentType?.id !== specificationItem.typeId) {
            dispatch(getSpecType(specificationItem.typeId));
        }
    }, [specificationItem, currentType]);


    return <>
        <Card orientation={'horizontal'}>
            <CardBody className={'spec-item-header'}>
                <div className="title">
                    {(!!currentType && !!specificationItem) && (
                        <>
                            <span>{specificationItem?.name || ''}</span>
                            {!!stageNumber && (
                                <span>(этап {stageNumber})</span>
                            )}
                            <span className={'type'}>{specificationItem?.name ? currentType.name : ''}</span>
                            {specificationItem?.version &&
                                <span className={'version'}>v{currentType?.version || ''}</span>}
                        </>
                    )}
                    <StatusBadge status={specificationItem?.status}/>
                </div>
                <div className="buttons">
                    <SpecificationActions
                        itemData={specificationItem}
                        itemPage={true}
                        onAuthorChange={handleActionUpdate}
                        itemActions={true}
                        disablePublish={!isEnabled()}
                    />

                </div>
            </CardBody>
        </Card>
        <Card>
            <CardBody className="item-nav-tabs">
                <div className="nav-tabs">
                    <NavLink
                        className={({isActive}) => isActive ? 'tab active' : 'tab'}
                        to={linkTo('../data-input')}
                    >
                        Ввод данных
                    </NavLink>
                    {isEnabled() ? (
                        <>
                            {withStages ? (
                                <>
                                    <NavLink
                                        className="tab"
                                        to={linkTo('../stages')}
                                    >
                                        Этапы
                                    </NavLink>
                                    <NavLink
                                        className="tab"
                                        to={linkTo('../documents')}
                                    >
                                        Отчеты
                                    </NavLink>
                                </>
                            ) : (
                                <>
                                    <NavLink
                                        className="tab"
                                        to={linkTo('../specification')}
                                    >
                                        Спецификация
                                    </NavLink>
                                    <NavLink
                                        className="tab"
                                        to={linkTo('../documents')}
                                    >
                                        Отчеты
                                    </NavLink>
                                    {isDebug() && (
                                        <NavLink
                                            className="tab"
                                            to={'../structure?debug=true'}
                                        >
                                            Структура решения
                                        </NavLink>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {isReady && (
                                <>
                                    {withStages ? (
                                        <>
                                            <div
                                                className="tab disabled"
                                            >
                                                Этапы
                                            </div>
                                            <div
                                                className="tab disabled"
                                            >
                                                Отчеты
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className="tab disabled"
                                            >
                                                Спецификация
                                            </div>
                                            <div
                                                className="tab disabled"
                                            >
                                                Отчеты
                                            </div>
                                            {isDebug() && (
                                                <div
                                                    className="tab disabled"
                                                >
                                                    Структура решения
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </CardBody>
        </Card>
    </>;
};

export {SpecificationItemHeader};
