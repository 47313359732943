import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {SyntheticEvent, useEffect, useState} from 'react';
import {JsonSchema} from '@jsonforms/core';
import {materialCells, materialRenderers} from '@jsonforms/material-renderers';
import {JsonForms} from '@jsonforms/react';
import {componentItemDialogUiSchema} from './component-item-options-dialog.meta';
import {ValidationMode} from '@jsonforms/core';
import {ItemComponentOptionData} from '../component-item-options.interface';
import {ApiNodeItem, ApiNodeItemOptions, NodeItemStatus} from '../../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {NodeState, getNodeItem} from '../../../../../store/slice/node-slice';
import {NodeTypeState} from '../../../../../store/slice/node-type-slice';
import FormulaInputRenderer, {
    formulaInputTester
} from '../../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer';
import DependentAutocompleteRenderer, {
    dependentAutocompleteTester
} from '../../component-item-links/new-component-item-dialog/dependent-autocomplete-renderer/dependent-autocomplete-renderer';
import {translation} from "../../../../../helpers";
import TextControl, {
    textControlTester
} from "../../../../../ui/json-form-renderers/input-control-renderers/TextControl";
import IntegerControl, {
    integerControlTester
} from "../../../../../ui/json-form-renderers/input-control-renderers/IntegerControl";

interface NewComponentItemDialogProps {
    handleClose: () => void;
    handleUpdate: (data: ApiNodeItem) => void;
    itemData?: ApiNodeItemOptions | null;
    readonly: boolean;
}

const renderers = [...materialRenderers,
    {tester: formulaInputTester, renderer: FormulaInputRenderer},
    {tester: dependentAutocompleteTester, renderer: DependentAutocompleteRenderer},
    {tester: textControlTester, renderer: TextControl},
    {tester: integerControlTester, renderer: IntegerControl},
];


const ComponentItemOptionsDialog = ({handleClose, handleUpdate, itemData, readonly}: NewComponentItemDialogProps) => {
    const dispatch = useAppDispatch();
    const [formSchema, setFormSchema] = useState<JsonSchema>();
    const [validationMode, setValidationMode] = useState<ValidationMode>('ValidateAndHide');
    const [formData, setFormData] = useState<any>();
    const [nameError, setNameError] = useState<any[]>([]);
    const [inProgress, setInProgress] = useState(false);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);
    const {allNodeTypes} = useAppSelector<NodeTypeState>(store => store.nodeType);

    const isReadOnly = (): boolean => {
        return currentNodeItem?.status !== NodeItemStatus.draft;
    };

    const createFormSchema = (types: string[]) => {
        const schema: JsonSchema = {
            type: 'object',
            properties: {
                id: {
                    title: 'ID',
                    type: 'string',
                    ...!!itemData && {'readOnly': readonly},
                },
                name: {
                    title: 'Название',
                    type: 'string',
                    ...!!itemData && {'readOnly': readonly},
                },
                value: {
                    type: 'object',
                    title: 'Количество',
                    properties: {
                        value: {
                            title: 'Количество (значение)',
                            type: 'integer',
                        },
                        formula: {
                            title: 'Количество (формула)',
                            type: 'string',
                        },
                    }
                }
            },
            required: ['id', 'name', 'value']
        };
        setFormSchema(schema);
    };


    const handleChange = (nextData: any) => {
        const {data} = nextData;

        if (data && data.id) {
            let keys = currentNodeItem?.options && Object.keys(currentNodeItem.options);
            if (itemData && keys) {
                keys = keys.filter(v => v !== itemData.id);
            }
            if (keys?.includes(data.id)) {
                const error = {
                    instancePath: '/id',
                    message: 'этот id не может быть использован',
                    schemaPath: '',
                    keyword: '',
                    params: {},
                };
                setNameError([error]);
            } else {
                if (nameError.length) {
                    setNameError([]);
                }
            }
        }
        setFormData(nextData);
    };

    const onSubmit = (e: SyntheticEvent) => {
        if ((formData.errors && formData.errors.length) || nameError.length) {
            setValidationMode('ValidateAndShow');
        } else {
            updateNodeItem(formData.data);
        }
        e.preventDefault();
    };

    const updateNodeItem = (data: ItemComponentOptionData) => {
        if (currentNodeItem) {
            const newId = data.id;
            const newOption = {
                id: data.id,
                name: data.name,
                quantityValue: data.value.value,
                quantityFormula: data.value.formula,
                links: itemData?.links || {},
                linkVariants: itemData?.linkVariants || {}
            };

            const newOptions = {...currentNodeItem.options};
            if (!!itemData) {
                delete newOptions[itemData.id];
            }

            newOptions[newId] = newOption;

            const formData: ApiNodeItem = {
                ...currentNodeItem,
                options: newOptions,
            };
            handleUpdate(formData);

        }
    };

    useEffect(() => {
        if (!!allNodeTypes && !!currentNodeItem) {
            const type = allNodeTypes.find(t => t.id === currentNodeItem.type);
            if (itemData) {
                setFormData({
                    data: {
                        id: itemData.id,
                        name: itemData.name,
                        value: itemData.quantityFormula ? {
                            formula: itemData.quantityFormula
                        } : {
                            value: itemData.quantityValue
                        },
                        links: itemData.links || {},
                    }
                });
            }
            createFormSchema(type?.allowChildren || []);
        }
    }, [allNodeTypes, currentNodeItem]);

    return <>
        <Dialog
            className="new-item-dialog"
            open={true}
            maxWidth={'lg'}
            onClose={handleClose}
        >
            <DialogTitle className="dialog-title">
                {!!itemData ? (isReadOnly() ? 'Опция' : 'Редактировать Опцию') : 'Добавить Опцию'}
            </DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent dividers>
                    {formSchema && (
                        <JsonForms
                            readonly={readonly}
                            data={formData?.data}
                            schema={formSchema}
                            uischema={componentItemDialogUiSchema}
                            renderers={renderers}
                            cells={materialCells}
                            validationMode={validationMode}
                            additionalErrors={nameError}
                            onChange={handleChange}
                            i18n={{locale: 'ru', translate: translation}}
                        />
                    )}
                </DialogContent>
                <DialogActions className="form-buttons">
                    {readonly ? (
                        <Button
                            variant={'contained'}
                            onClick={handleClose}
                        >
                            Закрыть
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant={'outlined'}
                                onClick={handleClose}
                                disabled={inProgress}
                            >
                                Отмена
                            </Button>
                            <LoadingButton
                                loading={inProgress}
                                type={'submit'}
                                variant={'contained'}
                                disabled={inProgress}
                            >
                                {itemData ? 'Сохранить' : 'Добавить'}
                            </LoadingButton>
                        </>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    </>;
};

export {ComponentItemOptionsDialog};
