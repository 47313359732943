import styles from '../../styles/type-edit.module.scss';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {Button} from '@mui/material';

interface TypeHeaderProps {
    header: TypeHeaderData,
    jsonMode: boolean,
    onSave: () => void,
    onDelete: () => void
}


export interface TypeHeaderData {
    title: string;
    version?: string;
}

const TypeHeader = ({header, jsonMode, onSave, onDelete}: TypeHeaderProps) => {
    return (
        <Card style={{background: 'inherit'}}>
            <CardBody className={styles.adminTypeItem__header_content}>
                <div className={styles.adminTypeItem__header_content_item}>
                    <span>{header.title}</span>
                    <span className={styles.adminTypeItem__header_content_version}>{header.version}</span>
                </div>
                { jsonMode && <div className={styles.adminTypeItem__header_content_item}>
                    <Button
                        type={'button'}
                        variant={'outlined'}
                        onClick={onDelete}
                    >
                        Удалить
                    </Button>
                    <Button
                        type={'button'}
                        variant={'contained'}
                        onClick={onSave}
                    >
                        Сохранить
                    </Button>
                </div>}

            </CardBody>
        </Card>
    );
};

export {TypeHeader};
