import {DropDownButton} from '@progress/kendo-react-buttons';
import {AppPrompt} from '../../../components/app-prompt/app-prompt';
import React, {PropsWithChildren, useState} from 'react';
import {AppPromptData, SpecTemplate, SpecTemplateAction, UserAction} from '../../../interface';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {ROUTE_PATH} from '../../../constants/routes';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../../hook/store';
import {createSpecFromTemplate, deleteTemplate} from '../../../store/slice/template-slice';
import {TemplateDialog} from './template-dialog/template-dialog';

interface TemplateActionsProps extends PropsWithChildren {
    template: SpecTemplate;
}

const TemplateActions = ({children, template}: TemplateActionsProps) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [rename, setRename] = useState<boolean>(false);

    const actionData: AppPromptData = {
        title: 'Подтвердите действие',
        message: [`Подтвердите удаление шаблона "${template?.name}"`],
        confirmButton: 'Удалить',
        buttonError: true
    };

    const handleAction = (e: DropDownButtonItemClickEvent) => {
        switch (e.item.text) {
            case SpecTemplateAction.create:
                createSpec();
                break;
            case SpecTemplateAction.rename:
                setRename(true);
                break;
            case UserAction.delete:
                setShowConfirm(true);
                break;
        }
    };

    const createSpec = () => {
        if (template.id) {
            dispatch(createSpecFromTemplate(template.id)).unwrap()
                .then((data) => {
                    navigate(`/${ROUTE_PATH.specifications}/${data.id}`);
                });
        }
    };

    const itemRender = (itemData: { item: any; itemIndex: number }) => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };

    const onDeleteItem = () => {
        setShowConfirm(false);
        if (template.id) {
            dispatch(deleteTemplate(template.id)).unwrap()
                .then(() => {
                    navigate(`/${ROUTE_PATH.templates}`);
                });
        }
    };

    const onRename = (reload: boolean) => {
        setRename(false);
        if (reload) {
            navigate(`/${ROUTE_PATH.templates}`);
        }
    };


    return (
        <>
            <DropDownButton
                size={'small'}
                themeColor={'primary'}
                fillMode={'solid'}
                rounded={'small'}
                icon={'more-vertical'}
                itemRender={itemRender}
                onItemClick={handleAction}
            >
                {children}
            </DropDownButton>
            {showConfirm && (
                <AppPrompt data={actionData} onClose={() => setShowConfirm(false)} onConfirm={onDeleteItem}/>
            )}

            {rename && (
                <TemplateDialog template={template} onClose={onRename}/>
            )}
        </>
    );
};

export {TemplateActions};
