import {ColumnInterface} from '../../../components';
import {AppBreadcrumb} from '../../../interface/breadcrumbs.interface';
import {ROUTE_PATH} from '../../../constants/routes';
import {AdminNodeSchema} from "../../../interface/admin/admin-node.interface";

export const nodeListColumns: ColumnInterface[] = [
    {
        title: 'ID',
        field: 'id',
        type: 'string',
        searchField: 'id',
        sortable: true,
        show: true,
        width: '300px',
    },
    {
        title: 'Название',
        field: 'name',
        type: 'name',
        show: true,
        searchField: 'name',
        sortable: true,
        width: '300px',
    }
];

export const adminNodeBreadcrumbs: AppBreadcrumb[] = [
    {
        title: 'Администрирование',
        path: `/${ROUTE_PATH.admin}`
    },
    {
        title: 'Типы компонентов',
        path: `/${ROUTE_PATH.adminNodes}`
    }
];


export const adminNodeSchema: AdminNodeSchema = {
    id: '',
    name: '',
    data: {
        typeName: '',
        allowChildren: [],
        requireTraits: [],
        typeSchema: {
            type: 'object',
            properties: {},
            required: []
        }
    }
}

