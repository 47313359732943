import styles from './attribute.module.scss';

import {Control, UseFormRegister} from 'react-hook-form/dist/types/form';
import {
    Checkbox,
    createTheme,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText, IconButton, InputLabel,
    MenuItem,
    Select,
    TextField,
    ThemeProvider
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {JSONSchema7TypeName} from 'json-schema';
import {FieldArrayWithId} from 'react-hook-form/dist/types/fieldArray';
import {Controller} from 'react-hook-form';
import {attributeTheme} from './attribute.meta';
import {notEmpty} from '../../../../utils/array.util';
import {AppPrompt} from '../../../../components/app-prompt/app-prompt';

interface AttributeProps {
    register: UseFormRegister<any>;
    errors: any;
    control: Control<any>;
    field: FieldArrayWithId<any>;
    index: number;
    onDelete: () => void;
}

export interface FormAttribute {
    name: string;
    type: JSONSchema7TypeName;
    title: string;
    required: boolean;
    minimum?: number | undefined;
    maximum?: number | undefined;
    restrictions?: boolean;
}

const theme = createTheme(attributeTheme);

// const types = ['string', 'number', 'integer', 'boolean', 'object', 'array', 'null'];
const types = ['string', 'number', 'integer', 'boolean'];


const Attribute = ({register, errors, control, field, index, onDelete}: AttributeProps,) => {
    const [errorData, setErrorData] = useState<any | undefined>(undefined);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [restrictions, setRestrictions] = useState<boolean>(false);

    useEffect(() => {
        if((field as unknown as any).restrictions){
            setRestrictions(true);
        }
    }, [field])

    useEffect(() => {
        const errorList = errors().properties;
        if (notEmpty(errorList) && errorList[index]) {
            setErrorData(errorList[index]);
        }
    }, [errors, index]);


    const handleDelete = () => {
        setShowConfirm(true);
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        onDelete();
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={styles.attributeWrapper}>
                <div className={styles.attributeWrapper_fieldset}>
                    <FormGroup sx={{gap: '16px', flexDirection: 'row'}}>
                        <FormControl error={!!errorData?.title}>
                            <TextField
                                error={!!errorData?.title}
                                helperText={!!errorData?.title ? errorData?.title.message as string : ''}
                                label="Название"
                                key={field.id} // important to include key with field's id
                                {...register(`properties.${index}.title` as const)}
                            />
                        </FormControl>
                        <FormControl error={!!errorData?.type}>
                            <InputLabel>Тип</InputLabel>
                            <Controller
                                name={(`properties.${index}.type` as const)}
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <Select
                                        key={field.id} // important to include key with field's id
                                        value={value}
                                        onChange={onChange}
                                        error={!!errorData?.type}
                                        label={'Тип'}
                                    >
                                        {types.map((type) => (
                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <FormHelperText>{!!errorData?.type ? errorData.type.message as string : ''}</FormHelperText>
                        </FormControl>

                        <FormControl>
                            <FormControlLabel control={
                                <Controller
                                    name={(`properties.${index}.required` as const)}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Checkbox
                                            key={field.id} // important to include key with field's id
                                            onChange={onChange}
                                            checked={Boolean(value)}
                                        />
                                    )}
                                />
                            } label="Обязательный атрибут"/>
                        </FormControl>
                        <FormControl>
                            <FormControlLabel control={
                                <Controller
                                    name={(`properties.${index}.restrictions` as const)}
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <Checkbox
                                            key={field.id} // important to include key with field's id
                                            onChange={(e) => {
                                                setRestrictions(e.target.checked)
                                                onChange(e)
                                            }}
                                            checked={Boolean(value)}
                                        />
                                    )}
                                />
                            } label="Ограничения"/>
                        </FormControl>
                    </FormGroup>
                    <div
                        className={restrictions ? `${styles.attributeWrapper_restrictions} ${styles.active}` : styles.attributeWrapper_restrictions}>
                        <span className={styles.bold}>Заданные ограничения</span>
                        <div className={styles.attributeWrapper_restrictions_controls}>
                            <div className={styles.attributeWrapper_restrictions_control}>
                                <span>min</span>
                                <svg width="9" height="11" viewBox="0 0 9 11" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.26172 4.27148L0.318359 1.81055V0.121094L8.6582 3.4707V4.50586L7.26172 4.27148ZM0.318359 6.43945L7.28125 3.92969L8.6582 3.73438V4.76953L0.318359 8.12891V6.43945ZM8.44336 9.51562V11H0.289062V9.51562H8.44336Z"
                                        fill="#212121"/>
                                </svg>
                                <FormControl error={!!errorData?.minimum}>
                                    <TextField
                                        sx={{width: '80px'}}
                                        type={'number'}
                                        error={!!errorData?.minimum}
                                        helperText={!!errorData?.minimum ? errorData?.minimum.message as string : ''}
                                        key={field.id} // important to include key with field's id
                                        {...register(`properties.${index}.minimum` as const )}
                                    />
                                </FormControl>
                            </div>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.47948 7.01594C6.36509 5.83659 6.89558 5.4442 7.16959 5.4442C7.45237 5.4442 7.67816 5.7226 8.16262 7.28776L8.99123 9.95994C7.3888 12.4107 6.21602 13.7764 5.50359 13.7764C5.26684 13.7764 5.0279 13.7019 4.85911 13.5353C4.69251 13.3687 4.50837 13.2306 4.32862 13.2306C3.74113 13.2306 3.01335 13.943 3.0002 14.8264C2.98705 15.7274 3.61838 16.3719 4.44699 16.3719C5.86967 16.3719 7.07314 14.9755 9.39677 11.273L10.0763 13.5638C10.6594 15.5323 11.3434 16.3719 12.1983 16.3719C13.2549 16.3719 14.6754 15.4709 16.2252 13.0355L15.5763 12.2945C14.6447 13.4169 14.0265 13.943 13.6604 13.943C13.2527 13.943 12.8954 13.3204 12.4241 11.7904L11.431 8.56138C12.0185 7.6955 12.5972 6.96772 13.1058 6.38681C13.7108 5.69629 14.1778 5.40255 14.4956 5.40255C14.7631 5.40255 14.9954 5.51654 15.1401 5.67656C15.3308 5.88701 15.4448 5.98565 15.6706 5.98565C16.1813 5.98565 16.9705 5.33679 16.999 4.47748C17.0253 3.67955 16.5299 3 15.6706 3C14.3729 3 13.2286 4.11798 11.0255 7.39298L10.5717 5.98784C9.93603 4.01495 9.51515 3 8.62954 3C7.59925 3 6.18314 4.26704 4.79773 6.27501L5.47948 7.01594Z"
                                    fill="#212121"/>
                            </svg>
                            <div className={styles.attributeWrapper_restrictions_control}>
                                <span>max</span>
                                <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.94336 4.0957L8.69141 6.57617V8.31445L0.703125 4.95508V3.92969L1.94336 4.0957ZM8.69141 2.05469L1.95312 4.57422L0.703125 4.69141V3.66602L8.69141 0.316406V2.05469ZM8.75 9.50586V10.9902H0.595703V9.50586H8.75Z" fill="#212121"/>
                                </svg>
                                <FormControl error={!!errorData?.maximum}>
                                    <TextField
                                        sx={{width: '80px'}}
                                        type={'number'}
                                        error={!!errorData?.maximum}
                                        helperText={!!errorData?.maximum ? errorData?.maximum.message as string : ''}
                                        key={field.id} // important to include key with field's id
                                        {...register(`properties.${index}.maximum` as const)}
                                    />
                                </FormControl>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={styles.attributeWrapper_actions}>
                    <div className={styles.adminTypeItem__content_form_actions}>
                        <IconButton aria-label="delete"
                                    sx={{borderRadius: '4px', backgroundColor: '#F4F5F6', padding: '9px'}}
                                    onClick={handleDelete}
                        >
                            <i className="pi pi-trash"></i>
                        </IconButton>
                    </div>
                </div>
            </div>
            {showConfirm && (
                <AppPrompt data={{
                    title: 'Удаление атрибута',
                    message: [`Вы хотите удалить атрибут '${(field as unknown as FormAttribute).title}' ?`],
                    confirmButton: 'Удалить',
                    buttonError: true
                }}
                           onClose={() => setShowConfirm(false)}
                           onConfirm={handleConfirm}
                />
            )}
        </ThemeProvider>
    );
};

export {Attribute};
