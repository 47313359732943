import {ThemeOptions} from '@mui/material';

export const attributeTheme: ThemeOptions = {
    components: {
        MuiInputLabel: {
            styleOverrides: {
                outlined:{
                    transform: 'translate(6px, 10px) scale(1)',
                    '&.MuiInputLabel-shrink': {
                        transform: 'translate(12px, -9px) scale(0.75)'
                    }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input': {
                        width: 168,
                        fontSize: 14,
                        fontWeight: 400,
                        color: '#212121',
                        padding: 8,
                        height: 'auto',
                    },
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    width: 168,
                    padding: 8,
                    fontSize: 14,
                    fontWeight: 400,
                    color: '#212121',
                    height: 'auto',
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00BEC8'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked':{
                        color: '#00BEC8'
                    }
                }
            }
        }
    }
};
