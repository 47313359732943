import {useForm} from "react-hook-form";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormGroup,
    TextField
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import React from "react";


interface NewAttributeDialogProps {
    onClose: () => void;
    onSubmit: (formData: { name: string }) => void;
    existing: string[];
}

const NewAttributeDialog = ({onClose, onSubmit, existing}: NewAttributeDialogProps) => {

    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
    } = useForm({
        mode: 'all',
    });

    const onBeforeSubmit = (formData: any) => {
        onSubmit(formData);
    };

    return (
        <Dialog
            className="new-item-dialog"
            open={true}
            maxWidth={'lg'}
            onClose={onClose}
        >
            <DialogTitle className="dialog-title">Новый атрибут</DialogTitle>
            <form onSubmit={handleSubmit(onBeforeSubmit)}>
                <DialogContent>
                    <FormGroup className={errors?.name ? 'form-field form-field-error' : 'form-field'}>
                        <FormControl error={!!errors?.name} size={'small'}>
                            <TextField
                                label={'Имя атрибута'}
                                variant="outlined"
                                error={!!errors?.name}
                                helperText={errors?.name ? errors?.name.message as string : ''}
                                {...register('name', {
                                    validate: (value) => !existing.includes(value.toLowerCase()) || 'Атрибут с таким именем уже создан',
                                    required: 'Обязательное поле',
                                    maxLength: {value: 100, message: 'Имя должно содержать максимум 100 символов'},
                                    pattern: {
                                        value: /^[a-zA-Z0-9_]*$/,
                                        message: 'Имя может содержать только латинские букы, цифры и знак подчеркивания'
                                    }
                                })}
                            />
                        </FormControl>
                    </FormGroup>
                </DialogContent>
                <DialogActions className="form-buttons">
                    <Button
                        variant={'outlined'}
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        type={'submit'}
                        variant={'contained'}
                    >
                        Добавить
                    </LoadingButton>
                </DialogActions>
            </form>

        </Dialog>
    );
};

export {NewAttributeDialog};
