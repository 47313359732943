import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import './specification-item-price.scss';
import {ReactComponent as IconCurrency} from '../../../../../assets/img/icon/dollar.svg';
import {ApiChangeCurrencyRateRequest, CurrencyCode} from '../../../../../interface';
import {useAppDispatch, useAppSelector} from '../../../../../hook/store';
import {SpecificationState, updateCurrencyRate} from '../../../../../store/slice/spec-slice';
import {format} from 'date-fns';
import {SettingsState} from "../../../../../store/slice/settings-slice";

export interface SpecificationItemPriceProps {
  onRateUpdate: (data: ApiChangeCurrencyRateRequest) => void;
  disabled: boolean;
}

const SpecificationItemPrice = ({onRateUpdate, disabled}: SpecificationItemPriceProps) => {

  const {currentSpec} = useAppSelector<SpecificationState>(store => store.spec);
  const [rate, setRate] = useState<number>(0);
  const [date, setDate] = useState<string>('');
  const dispatch = useAppDispatch();
  const [inputWidth, setInputWidth] = useState<number>(0);
  const ref = useRef<HTMLSpanElement>(null);
  const {currencyRate} = useAppSelector<SettingsState>(store => store.settings);

  const handleRateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /(?<=^| )\d+(\.)?(\d+)?(?=$| )/gm;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length < 8) {
        setRate(parseFloat(e.target.value));
      }
    }
  };

  const applyRate = () => {
    if (currencyRate?.exchangeRate && !disabled) {
      setRate(currencyRate.exchangeRate);
      updateRate(`${currencyRate.exchangeRate}`);
    }
  }

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === currentSpec?.doc?.invoice?.summary?.primaryCurrencyRate.toString()) {
      return;
    }
    updateRate(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateRate((e.target as HTMLInputElement).value);
    }
  };

  const updateRate = (value: string) => {
    if (!!currentSpec?.id) {
      const data: ApiChangeCurrencyRateRequest = {
        id: currentSpec.id,
        data: {
          exchangeRate: value
        }
      };
      onRateUpdate(data);
    }
  };

  const getCurrencySymbol = (value: string): string | undefined => {
    return Object.entries(CurrencyCode).find(([key, val]) => key === value)?.[1];
  };

  const getTotal = (total: number | undefined): string => {
    if (!total) {
      return '-';
    }
    const parts = total.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  };

  useEffect(() => {
    if (ref?.current) {
      setInputWidth(ref.current.offsetWidth);
    }
  }, [rate]);

  useEffect(() => {
    if (!!currentSpec) {
      setRate(currentSpec.doc?.invoice?.summary?.primaryCurrencyRate || 0);
    }
  }, [currentSpec]);

  useEffect(() => {
    if (currencyRate?.exchangeAt) {
      setDate(format(new Date(currencyRate.exchangeAt), 'dd.MM'));
    }
  }, [currencyRate])

  return <>
    <div className="specification-price">
      <div className="specification-price__row">
        <div className="specification-price__row__label">
          Курс:
        </div>
        <div className="specification-price__row__data">
          <span ref={ref} style={{position: 'absolute', opacity: 0, left: '-9999px'}}>{rate}</span>
          <div className={currencyRate?.exchangeRate ? 'current-currency enabled' : 'current-currency'}>
            <div>Курс на {date}</div>
            <div className={disabled ? 'disabled' : ''} title={currencyRate?.exchangeRate ? 'Применить курс' : ''} onClick={applyRate}>
              {currencyRate?.exchangeRate ? currencyRate.exchangeRate.toFixed(2) : ''}
            </div>
          </div>
          <div className="currency-input">
            <div className={'icon'}><IconCurrency/></div>
            <input className={'rate'}
                   style={{width: inputWidth}}
                   type="number"
                   step="0.01"
                   name="rate"
                   value={rate}
                   onChange={handleRateChange}
                   onBlur={handleBlur}
                   onKeyDown={handleKeyPress}
                   disabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className="specification-price__row">
        <div className="specification-price__row__label">
          Общая сумма:
        </div>
        <div className="specification-price__row__data price">
          {getTotal(currentSpec?.doc?.invoice?.summary?.total)} ₽
        </div>
      </div>
    </div>
  </>;
};

export {SpecificationItemPrice};
