import {ThemeOptions} from '@mui/material';

export const muiTheme: ThemeOptions = {
    typography: {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        fontWeightRegular: 400,
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {}
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    gap: 8
                },
                label: {
                    fontSize: 14,
                    fontWeight: 500,
                }
            }
        },
        MuiFormLabel: {
          styleOverrides: {
              root: {
                  fontSize: 16,
                  fontWeight: 500,
                  color: '#6C757D',
                  marginBottom: 12
              }
          }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 42,
                    height: 24,
                    padding: 0,
                },
                switchBase: {
                    padding: 0,
                    margin: 2,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(20px)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            backgroundColor: '#00BEC8',
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: '#33cf4d',
                        border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: '#ff00ff'

                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.7,
                    },
                },
                thumb: {boxSizing: 'border-box',
                    width: 18,
                    height: 18,
                    marginTop: 1

                },
                track: {
                    borderRadius: 12,
                    backgroundColor: '#C3CAD2',
                    opacity: 1,
                    transitionDuration: '500ms',
                }
                ,
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    background: '#ffffff',
                    paddingRight: 4
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#2196F3',
        },
        secondary: {
            main: '#6C757D'
        }
    },
};
