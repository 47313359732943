import React, {useCallback} from 'react';
import {withJsonFormsArrayLayoutProps} from '@jsonforms/react';
import {
    and,
    ArrayLayoutProps,
    isObjectArrayWithNesting,
    RankedTester,
    rankWith,
    schemaMatches
} from '@jsonforms/core';
import {Hidden} from '@mui/material';
import {ArrayLayout} from './ArrayLayout';

export const ArrayLayoutRenderer = ({
                                        visible,
                                        addItem,
                                        ...props
                                    }: ArrayLayoutProps) => {
    const addItemCb = useCallback((p: string, value: any) => addItem(p, value), [
        addItem
    ]);
    return (

        <>
            <Hidden xsUp={!visible}>
                <ArrayLayout
                    visible={visible}
                    addItem={addItemCb}
                    {...props}
                />
            </Hidden>
        </>
    );
};

export const arrayLayoutTester: RankedTester = rankWith(
    5,
    and(isObjectArrayWithNesting, schemaMatches((schema) => {
        // @ts-ignore
        return schema && schema.custom;
    }))
);

export default withJsonFormsArrayLayoutProps(ArrayLayoutRenderer);
