import React from 'react';
import {
  ControlProps,
  isStringControl,
  RankedTester,
  rankWith
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import {MaterialInputControl} from "./InputControl";
import {MuiInputText} from "@jsonforms/material-renderers";

export const MaterialTextControl = (props: ControlProps) => (
  <MaterialInputControl {...props} input={MuiInputText} />
);

export const textControlTester: RankedTester = rankWith(
  2,
  isStringControl
);
export default withJsonFormsControlProps(MaterialTextControl);
