import {SettingsAbout} from './sections/settings-about';
import {SettingsCommon} from './sections/settings-common';
import styles from './settings.module.scss';
import {SettingsSystem} from './sections/system/settings-system';
import {ProtectedLayout} from '../../hoc/protected-layout/protected-layout';
import {AppBreadcrumb} from '../../interface/breadcrumbs.interface';
import {AppBreadcrumbs} from '../../components/app-breadcrumbs/app-breadcrumbs';

const Settings = () => {

    const breadcrumbs: AppBreadcrumb[] = [
        {title: 'Настройки'}
    ];

    return <>
        <div className={styles.settingsCard}>
            <AppBreadcrumbs items={breadcrumbs}/>
        </div>

        <SettingsCommon/>
        <SettingsAbout/>
        <ProtectedLayout>
            <SettingsSystem/>
        </ProtectedLayout>
    </>;
};

export {Settings};
