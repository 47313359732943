import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ApiNodeItem, ApiNodeItemConstraint} from "../../../../../interface";
import {LoadingButton} from "@mui/lab";
import {SyntheticEvent, useEffect, useState} from "react";
import {JsonSchema, ValidationMode} from "@jsonforms/core";
import {constraintDialogSchema, ConstraintDialogUiSchema, ConstraintType} from "./constraint-dialog.meta";
import {JsonForms} from "@jsonforms/react";
import {materialCells, materialRenderers} from "@jsonforms/material-renderers";
import {translation} from "../../../../../helpers";
import FormulaInputRenderer, {
    formulaInputTester
} from "../../../../../ui/json-form-renderers/formula-input-renderer/formula-input-renderer";
import {useAppSelector} from "../../../../../hook/store";
import {NodeState} from "../../../../../store/slice/node-slice";
import TextControl, {
    textControlTester
} from "../../../../../ui/json-form-renderers/input-control-renderers/TextControl";
import IntegerControl, {
    integerControlTester
} from "../../../../../ui/json-form-renderers/input-control-renderers/IntegerControl";

interface ConstraintDialogProps {
    handleClose: () => void;
    handleUpdate: (data: ApiNodeItem) => void;
    readonly: boolean
    constraintData: ApiNodeItemConstraint | null;
}

interface ConstraintFormData {
    id: string;
    type: string;
    name: string;
    activationPredicate: { value: string, formula: string };
    predicate: { value: string, formula: string };
    message: string;
}

const renderers = [...materialRenderers,
    {tester: formulaInputTester, renderer: FormulaInputRenderer},
    {tester: textControlTester, renderer: TextControl},
    {tester: integerControlTester, renderer: IntegerControl},
];


const ConstraintDialog = ({constraintData, handleClose, readonly, handleUpdate}: ConstraintDialogProps) => {

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [validationMode, setValidationMode] = useState<ValidationMode>('ValidateAndHide');
    const [formSchema, setFormSchema] = useState<JsonSchema>();
    const [additionalError, setAdditionalError] = useState<any[]>([]);

    const [formData, setFormData] = useState<{ errors?: any, data: ConstraintFormData }>({
        data: {type: ConstraintType.consistency} as ConstraintFormData
    });

    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);

    useEffect(() => {

        setFormSchema(constraintDialogSchema(!!constraintData, readonly))
        if(constraintData ){setFormData({
            data: {
                id: constraintData.id,
                type: ConstraintType.consistency,
                name: constraintData.name,
                activationPredicate: {value: constraintData.activationPredicate, formula: constraintData.activationPredicate},
                predicate: {value: constraintData.consistency.predicate, formula: constraintData.consistency.predicate},
                message: constraintData.consistency.message,

            }
        });

        }
    }, [constraintData, readonly])

    const handleChange = (nextData: any) => {
        const {data} = nextData;
        setAdditionalError([]);
        if (data && data.id) {

            const keys = Object.keys(currentNodeItem?.constraints || {}).filter(k => k !== constraintData?.id);
            if (keys.includes(data.id)) {
                setAdditionalError([{
                    instancePath: '/id',
                    message: 'Этот id не может быть использован',
                    schemaPath: '',
                    keyword: '',
                    params: {},
                }]);
            }else if (!/^[a-zA-Z0-9]*$/gm.test(data.id)){
                setAdditionalError([{
                    instancePath: '/id',
                    message: 'Поле может содержать только латинские буквы и цифры',
                    schemaPath: '',
                    keyword: '',
                    params: {},
                }]);
            }

        }
        setFormData(nextData);
    };

    const onSubmit = (e: SyntheticEvent) => {

        if ((formData?.errors.length) || additionalError?.length) {
            setValidationMode('ValidateAndShow');
        } else {
            updateNodeItem(formData.data);
        }

        e.preventDefault();
    };


    const updateNodeItem = (data: ConstraintFormData) => {
        if (currentNodeItem) {
            const newId = data.id;
            const newConstraint: ApiNodeItemConstraint = {
                id: data.id,
                name: data.name,
                type: 'consistency',
                activationPredicate: data.activationPredicate.formula || data.activationPredicate.value,
                consistency: {
                    type: 'fatal',
                    predicate: data.predicate?.formula || data.predicate?.value || '',
                    message: data?.message || ''
                }
            };

            const newConstraints = {...currentNodeItem.constraints};
            if (!!constraintData) {
                delete newConstraints[constraintData.id];
            }
            newConstraints[newId] = newConstraint;

            const formData: ApiNodeItem = {
                ...currentNodeItem,
                constraints: newConstraints,
            };
            handleUpdate(formData);
        }
    };


    return (
        <Dialog
            className="new-item-dialog"
            open={true}
            maxWidth={'lg'}
            onClose={handleClose}
        >

            <DialogTitle className="dialog-title">
                {!!constraintData ? 'Редактировать Ограничение' : 'Добавить Ограничение'}
            </DialogTitle>
            <form onSubmit={onSubmit}>
                <DialogContent>
                    {formSchema && (
                        <JsonForms
                            readonly={readonly}
                            data={formData.data}
                            schema={formSchema}
                            uischema={ConstraintDialogUiSchema}
                            renderers={renderers}
                            cells={materialCells}
                            validationMode={validationMode}
                            additionalErrors={additionalError}
                            onChange={handleChange}
                            i18n={{locale: 'ru', translate: translation}}
                        />
                    )}
                </DialogContent>
                <DialogActions className="form-buttons">
                    {readonly ? (
                        <Button
                            variant={'contained'}
                            onClick={handleClose}
                        >
                            Закрыть
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant={'outlined'}
                                onClick={handleClose}
                                disabled={inProgress}
                            >
                                Отмена
                            </Button>
                            <LoadingButton
                                loading={inProgress}
                                type={'submit'}
                                variant={'contained'}
                                disabled={inProgress}
                            >
                                {constraintData ? 'Сохранить' : 'Добавить'}
                            </LoadingButton>
                        </>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export {ConstraintDialog};
