import {Card, CardActions, CardContent} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {logout} from '../../store/slice/auth-slice';
import {useAppDispatch} from '../../hook/store';
import './login.scss';
import {ReactComponent as Logo} from '../../assets/img/logo.svg';

const LoginError = () => {
    const dispatch = useAppDispatch();

    const handleLoginSSO = async () => {
        await dispatch(logout());
        window.location.href = '/sso/signout';
    };

    return (
        <div className="login-wrapper">
            <div className="login-form">
                <div className="logo">
                    <Logo/>
                </div>
                <Card>
                    <CardContent>
                        <p className="noRolesError">Нет доступа к системе.</p>
                    </CardContent>
                    <CardActions className="actions">
                        <LoadingButton
                            type={'submit'}
                            variant={'contained'}
                            onClick={handleLoginSSO}
                        >
                            Войти
                        </LoadingButton>

                    </CardActions>
                </Card>
            </div>
        </div>
    );
};

export {LoginError};
