import React, {ReactElement, useEffect, useState} from 'react';
import {DropDownButton, DropDownButtonItem} from '@progress/kendo-react-buttons';
import {DropDownButtonItemClickEvent} from '@progress/kendo-react-buttons/dist/npm/ListButton/models/events';
import {useNavigate, useParams} from 'react-router-dom';
import {
    ApiNodeItem,
    AppPromptData,
    NodeAction,
    NodeActionListItem,
    NodeItemStatus,
    NodeStatusCommand
} from '../../../../interface';
import {AppPrompt} from '../../../../components/app-prompt/app-prompt';
import {useAppDispatch, useAppSelector} from '../../../../hook/store';
import {
    changeNodeItemStatus,
    deleteNodeItem,
    cloneNodeItem,
    getNodeItem,
    NodeState
} from '../../../../store/slice/node-slice';
import {ComponentItemClone} from '../../component-item/component-item-clone/component-item-clone';
import {
    getComponentActionItems,
    getConfirmData,
    getDryRunConfirmData,
    getDryRunResourceConfirmData
} from './component-actions.meta';
import {ROUTE_PATH} from '../../../../constants/routes';

interface ComponentActionsProps {
    nodeItem: ApiNodeItem
    hideEdit?: boolean
}

const ComponentTypeActions = ({nodeItem, hideEdit = false}: ComponentActionsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id} = useParams();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [confirmData, setConfirmData] = useState<AppPromptData>();
    const [showDryRunConfirm, setShowDryRunConfirm] = useState<boolean>(false);
    const [dryRunConfirmData, setDryRunConfirmData] = useState<AppPromptData>();
    const [actionItems, setActionItems] = useState<NodeActionListItem[]>([]);
    const [dryRunResult, setDryRunResult] = useState<any>(false);
    const [dryRunMessage, setDryRunMessage] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {currentNodeItem} = useAppSelector<NodeState>(store => store.node);


    const [lastAction, setLastAction] = useState<NodeAction>();

    const itemRender = (itemData: { item: any; itemIndex: number }): ReactElement => {
        return <div className="action-item">
            <span data-action={itemData.item.text}>{itemData.item.text}</span>
        </div>;
    };

    const handleSetProgress = (progress: boolean) => {
        setInProgress(progress);
    };

    const handleConfirm = (): void => {
        setIsLoading(true);
        switch (lastAction) {
            case NodeAction.delete:
                onDeleteItem();
                break;
            case NodeAction.publish:
                onSafeChangeStatus(NodeStatusCommand.publish, NodeAction.publish);
                break;
            case NodeAction.unpublish:
                onSafeChangeStatus(NodeStatusCommand.unpublish, NodeAction.unpublish);
                break;
            case NodeAction.archive:
                onArchiveItem();
                break;
            case NodeAction.unarchive:
                onUnArchiveItem();
                break;
        }
    };

    const handleDryRunConfirm = (): void => {
        setIsLoading(true);
        switch (lastAction) {
            case NodeAction.unpublish:
                onConfirmChangeStatus(NodeStatusCommand.unpublish);
                break;
            case NodeAction.publish:
                onConfirmChangeStatus(NodeStatusCommand.publish);
                break;
        }
    };

    const handleAction = (e: DropDownButtonItemClickEvent): void => {
        switch (e.item.text) {
            case NodeAction.edit:
                editItem(e);
                break;
            case NodeAction.manage:
                editItem(e);
                break;
            case NodeAction.clone:
                if (!id) {
                    dispatch(getNodeItem((nodeItem.id).toString())).unwrap().then(() => setTimeout(() => onCloneItem(true)));
                } else {
                    onCloneItem(true);
                }
                break;
            default:
                showConfirmModal(e.item.text);
                break;
        }
    };

    const showConfirmModal = (action: NodeAction): void => {
        setLastAction(action);
        setConfirmData(getConfirmData(nodeItem, action));
        setShowConfirm(true);
    };

    const editItem = (e: DropDownButtonItemClickEvent) => {
        if (e.nativeEvent.ctrlKey || e.nativeEvent.metaKey) {
            window.open(`/${ROUTE_PATH.newComponents}/${nodeItem.type}/${nodeItem.id}`, '_blank');
        } else {
            navigate(`/${ROUTE_PATH.newComponents}/${nodeItem.type}/${nodeItem.id}`);
        }
    };

    const onDeleteItem = async (): Promise<any> => {
        const safeDelete = nodeItem.status === NodeItemStatus.archive;
        await dispatch(deleteNodeItem({id: nodeItem.id, dryRun: safeDelete})).unwrap()
            .then((data: any) => {
                navigate(`/${ROUTE_PATH.newComponents}/type?types=${nodeItem.type}`);
            });
    };

    const onCloneItem = (dryRunParam: boolean): void => {
        const now = new Date();
        const cloneItemData = {
            id: nodeItem.id,
            cloneName: `${nodeItem.name} - копия ${now.toLocaleDateString('en-GB')}`,
            dryRun: dryRunParam,
        };
        dispatch(cloneNodeItem(cloneItemData)).unwrap().then((data: any) => {
            handleSetProgress(false);
            if (dryRunParam) {
                setDryRunResult(data);
                setDryRunMessage(true);
            } else if (data.id) {
                if (id) {
                    dispatch(getNodeItem(data.id)).unwrap().then((data: any) => {
                        navigate(`/${ROUTE_PATH.newComponents}/${nodeItem.type}/${data.id}`);
                    });
                } else {
                    navigate(`/${ROUTE_PATH.newComponents}/type?types=${nodeItem.type}`);
                }
            }
        })
            .finally(() => {
                if (!dryRunParam) {
                    setDryRunMessage(false);
                }
            });
    };

    const handleCloneConfirm = () => {
        onCloneItem(false);
    };

    const onPublishItem = (): void => {
        dispatch(changeNodeItemStatus({id: nodeItem.id, command: NodeStatusCommand.publish}));
    };

    const onSafeChangeStatus = (command: NodeStatusCommand, action: NodeAction): void => {
        dispatch(changeNodeItemStatus({id: nodeItem.id, command, dryRun: true}))
            .unwrap()
            .then((data) => {
                if (Array.isArray(data?.response?.nodes) && !!data.response.nodes.length) {
                    setDryRunConfirmData(getDryRunConfirmData(nodeItem, action, data.response.nodes));
                    setShowConfirm(false);
                    setIsLoading(false);
                    setShowDryRunConfirm(true);
                } else if (Array.isArray(data?.response?.resources) && !!data.response.resources.length) {
                    setDryRunConfirmData(getDryRunResourceConfirmData(nodeItem, action, data.response.resources));
                    setShowConfirm(false);
                    setIsLoading(false)
                    setShowDryRunConfirm(true);
                } else {
                    onConfirmChangeStatus(command);
                }
                // dispatch(getNodeItem(nodeItem.id.toString()));
            });
    };

    const onArchiveItem = async (): Promise<any> => {
        await dispatch(changeNodeItemStatus({id: nodeItem.id, command: NodeStatusCommand.archive}));
        if (!id) {
            window.location.reload();
        }
    };

    const onUnArchiveItem = async (): Promise<any> => {
        await dispatch(changeNodeItemStatus({id: nodeItem.id, command: NodeStatusCommand.unarchive}));
        if (!id) {
            window.location.reload();
        }
    };

    const onConfirmChangeStatus = async (command: NodeStatusCommand): Promise<any> => {
        await dispatch(changeNodeItemStatus({id: nodeItem.id, command})).unwrap()
            .then( () => {
                setIsLoading(false);
                setShowDryRunConfirm(false);
                setShowConfirm(false);
            });
        if (!id) {
            window.location.reload();
        }
    };

    const handleCloseDryRunConfirm = () => {
        setShowDryRunConfirm(false);
        setIsLoading(false);
    };

    const handleCloseConfirm = () => {
        setShowConfirm(false);
        setIsLoading(false);
    };

    useEffect(() => {
        setActionItems(getComponentActionItems(hideEdit, nodeItem));
    }, [nodeItem, hideEdit]);

    return <>
        <DropDownButton
            size={'small'}
            themeColor={'primary'}
            fillMode={'solid'}
            rounded={'small'}
            icon={'more-vertical'}
            itemRender={itemRender}
            onItemClick={handleAction}
        >
            {actionItems.filter(item => item.active).map(item => (
                <DropDownButtonItem key={item.action} text={item.action}/>
            ))}
        </DropDownButton>
        {showConfirm && !!confirmData && (
            <AppPrompt data={confirmData}
                       onClose={handleCloseConfirm}
                       onConfirm={handleConfirm}
                       isLoading={isLoading}/>
        )}
        {showDryRunConfirm && !!dryRunConfirmData && (
            <AppPrompt data={dryRunConfirmData}
                       onClose={handleCloseDryRunConfirm}
                       isLoading={isLoading}
                       onConfirm={handleDryRunConfirm}/>
        )}
        {dryRunMessage && <ComponentItemClone data={dryRunResult}
                                              originalNode={nodeItem}
                                              inProgress={inProgress}
                                              onSetProgress={handleSetProgress}
                                              handleClose={() => setDryRunMessage(false)}
                                              handleSave={handleCloneConfirm}
        />}
    </>;
};

export {ComponentTypeActions};
