import {useParams} from 'react-router-dom';
import {AppBreadcrumbs} from '../../components/app-breadcrumbs/app-breadcrumbs';
import React, {CSSProperties, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hook/store';
import {clearCurrentModel, getModelType, ModelTypeState} from '../../store/slice/model-type-slice';
import {AppSuspense} from '../../components/app-suspense/app-suspense';
import {ROUTE_PATH} from '../../constants/routes';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {NodeTypeActions} from "./node-type-actions";
import {DropDownButtonItem} from "@progress/kendo-react-buttons";
import {Role, SpecTemplateAction} from "../../interface";
import {UserState} from "../../store/slice/user-slice";
import {RoleState} from "../../store/slice/role-slice";

const titleStyle: CSSProperties = {
  color: 'var(--text-secondary)',
  fontSize: '18px',
  fontWeight: '500',
  lineHeight: '33px',
  marginBottom: '6px',
};


const NodeTypeItem = () => {
  const dispatch = useAppDispatch();
  const urlParams = useParams();
  const {currentModel} = useAppSelector<ModelTypeState>(state => state.modelType);
  const {currentUser} = useAppSelector<UserState>(store => store.user);
  const {roleList} = useAppSelector<RoleState>(store => store.role);
  const [role, setRole] = useState<Role>();

  useEffect(() => {
    if (currentUser && Array.isArray(roleList)) {
      const role = roleList.find((r) => r.id === currentUser.role);
      const userRole = role ? role.name : Role.ROLE_SALESMAN;
      setRole(userRole);
    }
  }, [currentUser, roleList])

  useEffect(() => {
    if (urlParams?.id) {
      dispatch(getModelType(Number(urlParams.id)));
    }
  }, [urlParams]);

  useEffect(() => {
    return () => {
      // clear  data on component unmount
      dispatch(clearCurrentModel());
    };
  }, []);

  const isAdmin = (): boolean => {
    return role === Role.ROLE_ADMIN;
  };

  return (
    <div className={'page-grid page-grid-inline-header'}>
      <div className="page-grid__breadcrumbs">
        <AppBreadcrumbs
          items={[{title: 'Типы узлов', path: `/${ROUTE_PATH.nodeTypes}`}, {title: String(currentModel?.id) || ''}]}/>
      </div>
      <div className="page-grid__header">
        <Card>
          <CardBody>
            <div className="k-d-flex k-justify-content-between">

              <div style={titleStyle}>{currentModel?.namespace || ''}:{currentModel?.id || ''}</div>
              <div>
                {isAdmin() && currentModel && (
                  <NodeTypeActions modelType={currentModel}>
                    <DropDownButtonItem text={SpecTemplateAction.delete}/>
                  </NodeTypeActions>
                )}
              </div>

            </div>
          </CardBody>
        </Card>
      </div>
      <div className="page-grid__grid">
        <AppSuspense condition={!!currentModel?.id}>
          <Card style={{height: 'calc(100vh - 200px)', width: '100%', overflow: 'auto'}}>
            <CardBody>
              <pre style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(currentModel, null, 2)}</pre>
            </CardBody>
          </Card>
        </AppSuspense>
      </div>
    </div>
  );
};

export {NodeTypeItem};
