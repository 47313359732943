import {ReactComponent as IconHome} from '../../../../assets/img/icon/home.svg';
import {ReactComponent as IconChevronRight} from '../../../../assets/img/icon/chevron-right.svg';
import {NavLink} from 'react-router-dom';
import {Card, CardBody} from '@progress/kendo-react-layout';
import {ROUTE_PATH} from '../../../../constants/routes';
import {ReactComponent as IconCurrency} from '../../../../assets/img/icon/currency.svg';
import {SpecItem} from "../../../../interface";
import React, {useMemo} from "react";

interface SpecificationBreadcrumbsProps {
  spec?: SpecItem;
  className?: string;
}

const SpecificationBreadcrumbs = ({spec, className}: SpecificationBreadcrumbsProps) => {

  const versionStyle = {
    fontSize: '11px',
    lineHeight: '11px',
    background: '#EAEBF0',
    display: 'flex',
    padding: '4px 6px',
    borderRadius: '4px',
  };

  const getTotal = (total: number | undefined): string => {
    if (!total) {
      return '-';
    }
    const parts = total.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${parts.join('.')} ₽`;
  };

  const withStages = useMemo((): boolean => {
    if(!spec){
      return false;
    }
    return Array.isArray(spec?.stages) && !!spec?.stages?.length;
  }, [spec]);

  const stageNumber = useMemo((): string | null => {
    if(!spec) {
      return null;
    }
    return withStages ? null : spec?.stageNumber || null;
  }, [spec]);

  return (
    <div className={className} style={{width: '100%'}}>
      <Card>
        <CardBody className={'breadcrumbs-card d-flex'}>
          <div className="breadcrumbs">
            <NavLink to={'/'}><IconHome/></NavLink>
            <IconChevronRight/>
            <div className="item">
              <NavLink
                to={`/${ROUTE_PATH.specifications}`}>{'Спецификации'}</NavLink>
            </div>

            {!!spec && (
              <>
                <IconChevronRight/>
                <div className="item">
                  <span>
                    <span>{spec.name}</span>
                    {!!stageNumber && (
                        <span> (этап {stageNumber})</span>
                    )}
                    <span style={versionStyle}>{spec.id}</span>
                  </span>
                </div>
              </>
            )}
          </div>
          {!!spec && (
            <div className="k-flex k-d-flex-row !k-gap-1 !k-justify-content-end !k-align-items-center">
              <div className="specification-price__icon">
                <IconCurrency/>
              </div>
              <div style={{border: '1px solid #DAE0E6', padding: '0 6px'}}>
                {spec.doc?.invoice?.summary?.primaryCurrencyRate || ''}
              </div>
              <span>{getTotal(spec.doc?.invoice?.summary?.total)}</span>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export {SpecificationBreadcrumbs};
